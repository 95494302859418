import { PlayerBalanceDTO } from '../../dto/playerBalanceDTO'
import { TransferInLimitDTO } from '../../dto/transferInLimitDTO'

export interface IWalletState {
    cmsWallet: PlayerBalanceDTO, currentWallet: PlayerBalanceDTO, transferInLimit: TransferInLimitDTO, loading: boolean, error: {}, transferInSuccess: boolean, isVIP: boolean
}


export const InitWalletState: IWalletState = {
    cmsWallet: {} as PlayerBalanceDTO, currentWallet: {} as PlayerBalanceDTO, transferInLimit: {} as TransferInLimitDTO, loading: false, error: "", transferInSuccess: false, isVIP: false
}