import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { ApplicationState, reducers } from '.';
import { userReducer } from '../Player/PlayerReducers';
import { jackpotReducer } from '../Jackpot/JackpotReducers';
import { lobbyReducer } from '../Lobby/LobbyReducers';
import { walletReducer } from '../PlayerWallet/PlayerWalletReducers';
import { historyReducer } from '../History/HistoryReducers';
import { rememberMeReducer } from '../PortalConfig/PortalConfigReducers';
import { PlayerProfileDTO } from '../../dto/playerProfileDTO';

export default function configureStore(history: History, initialState?: ApplicationState) {
    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    const rootReducer = combineReducers({
        ...reducers,
        userState: userReducer,
        walletState: walletReducer,
        jackpotState: jackpotReducer,
        lobbyState: lobbyReducer,
        historyState: historyReducer,
        rememberMeState: rememberMeReducer,
        router: connectRouter(history)
    });

    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }
    const tokenFromStorage: string = JSON.stringify(localStorage.getItem('tkn')
        ? (localStorage.getItem('tkn') === 'undefined' ? null : (localStorage.getItem('tkn') || ""))
        : "")

    initialState = {
        userState: {
            userInfo: {} as PlayerProfileDTO, tkn: tokenFromStorage, isLogin: false, loading: false, error: ""
        }
    };

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
