import axios from 'axios';
import { LobbyActionType } from './LobbyConstants';

type GetLobbyGames_Request = { type: LobbyActionType.GET_LOBBY_GAMES_REQUEST };
type GetLobbyGames_Success = { type: LobbyActionType.GET_LOBBY_GAMES_SUCCESS, payload: {} };
type GetLobbyGames_Failed = { type: LobbyActionType.GET_LOBBY_GAMES_FAILED, payload: {} };

export type ActionTypes =
    GetLobbyGames_Request |
    GetLobbyGames_Success |
    GetLobbyGames_Failed;

export const getLobbyGames = () => async (dispatch: any, getState: any) => {
    try {

        dispatch({
            type: LobbyActionType.GET_LOBBY_GAMES_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('tkn') || "",
            },
        }

        const { data } = await axios.get(
            "Lobby/GetLobbyGames",
            config
        )

        dispatch({
            type: LobbyActionType.GET_LOBBY_GAMES_SUCCESS,
            payload: JSON.parse(data.res)
        });

    } catch (error) {
        dispatch({
            type: LobbyActionType.GET_LOBBY_GAMES_FAILED,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}