import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Col, Row } from 'reactstrap'
import { getPlayerProfile } from '../states/Player/PlayerActions'
import { IStore } from '../states/store/IStore'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { DemoModeContext } from '../context/DemoContext';
import "../styles/PlayerProfile.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { LanguageModeContext } from '../context/LanguageContext';
import { strings } from '../assets/strings'
import TransferInModal from "../components/modals/TransferInModal";
import { getBalance } from '../states/PlayerWallet/PlayerWalletActions'
import { FormatCurrency, FormatDateTime } from '../utils/StringFormatHelper';
import { FunplayModeContext } from '../context/FunplayContext'
import { GameMode } from '../enums/GameMode'

const PlayerModal = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isDemo = useContext(DemoModeContext)
    const { funplayMode } = useContext(FunplayModeContext)
    const { language } = useContext(LanguageModeContext)

    const userState = useSelector((state: IStore) => state.userState)
    const { userInfo, loading, error } = userState

    const [username, setUsername] = useState("")
    const [fullname, setFullname] = useState("")
    const [cardType, setCardType] = useState("")
    const [lastLoginDateTime, setLastLoginDateTime] = useState("")
    const [ecBalance, setEcBalance] = useState("0.00")
    const [nnBalance, setNnBalance] = useState("0.00")
    const [showModal, setshowModal] = useState(false);
    const [walletType, setWalletType] = useState(0);
    const walletState = useSelector((state: IStore) => state.walletState)
    const { currentWallet, isVIP } = walletState
    const [currency, setCurrency] = useState("")

    const handleModalOpened = (value: boolean) => {
        setshowModal(value)
    };

    useEffect(() => {
        if (localStorage.getItem('tkn') == null) {
            navigate('/')
        }
        else if (!userInfo) {
            dispatch(getPlayerProfile(language, isDemo ? GameMode.DEMO : funplayMode ? GameMode.FUNPLAY : GameMode.NORMAL))
        }
        else if (userInfo && Object.keys(userInfo).length > 0) {
            setUsername(userInfo.unm)
            setFullname(userInfo.fnm)
            setCardType(userInfo.crd)
            setCurrency(userInfo.cur)

            setLastLoginDateTime(FormatDateTime(userInfo.llg))
            //first time wallet data
            if (!currentWallet || Object.keys(currentWallet).length == 0) {
                if (isVIP && userInfo.blc.vipec != undefined && userInfo.blc.vipnn != undefined) {
                    setEcBalance(FormatCurrency(currentWallet.vipec))
                    setNnBalance(FormatCurrency(currentWallet.vipnn))
                } else if (userInfo.blc.ec != undefined && userInfo.blc.nn != undefined) {
                    setEcBalance(FormatCurrency(userInfo.blc.ec))
                    setNnBalance(FormatCurrency(userInfo.blc.nn))
                }
            }
        }

        if (currentWallet && Object.keys(currentWallet).length > 0) {
            if (isVIP && currentWallet.vipec != undefined && currentWallet.vipnn != undefined) {
                setEcBalance(FormatCurrency(currentWallet.vipec))
                setNnBalance(FormatCurrency(currentWallet.vipnn))
            }
            else if (currentWallet.ec != undefined && currentWallet.nn != undefined) {
                setEcBalance(FormatCurrency(currentWallet.ec))
                setNnBalance(FormatCurrency(currentWallet.nn))
            }
        }
        else {
            dispatch(getBalance(isDemo ? GameMode.DEMO : GameMode.NORMAL))
        }

    }, [userInfo, loading, currentWallet])

    return (
        <Row className="justify-content-center p-0 m-0">
            <Col sm={10} md={8} lg={6} className="d-flex justify-content-center">
                {loading ? (
                    <div>
                        <Loader />
                    </div>)
                    : error ?
                        (<Message variant='danger'>{error}</Message>)
                        : (
                            <>
                                <Card id="profile-card" className="fade-in-top">
                                    <Row>
                                        <Col>
                                            <Row className="mb-2">
                                                <Col><b>{fullname}</b></Col>
                                            </Row>
                                            <Row className="mb-4">
                                                <Col>{username}</Col>
                                            </Row>
                                            <Row className="mb-4">
                                                <Col>
                                                    <Row>
                                                        <Col><b>{strings.CARD_TYPE[language]}:</b></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>{cardType}</Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col>
                                                    <Row>
                                                        <Col><b>{strings.LAST_LOGIN_DATE_TIME[language]}:</b></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>{lastLoginDateTime}</Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <hr className="divider" />
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <h4><b>{strings.BALANCE[language]}</b></h4>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3 balance-category">
                                        <Col>
                                            <Row>
                                                <Col><b>{strings.EC[language]} ({currency})</b></Col>
                                            </Row>
                                            <Row>
                                                <Col>{ecBalance}</Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col><Button color="success" onClick={() => { setshowModal(!showModal); setWalletType(0) }} className="w-90 transfer-in-btn"><FontAwesomeIcon icon={faPlus} className="me-3" />{strings.TRANSFER_IN[language]}</Button></Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col><b>{strings.NN[language]} ({currency})</b></Col>
                                            </Row>
                                            <Row>
                                                <Col>{nnBalance}</Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col><Button color="success" onClick={() => { setshowModal(!showModal); setWalletType(1) }} className="w-90 transfer-in-btn"><FontAwesomeIcon icon={faPlus} className="me-3" />{strings.TRANSFER_IN[language]}</Button></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>
                                <TransferInModal openModal={showModal} onChange={handleModalOpened} walletType={walletType} />
                            </>
                        )}
            </Col>
        </Row>
    )
};

export default PlayerModal;
