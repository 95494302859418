export enum WorkerActions {
    BALANCE_DATA_UPDATE = 1,
    JACKPOT_DATA_UPDATE = 2,
    LANGUAGE_CHANGED = 3,
    STOP_IDLE = 4,
    START_IDLE = 5,
    CHECK_OPENED_TABS = 6,
    UPDATE_TOKEN = 7,
    KEEP_ALIVE = 8
}

