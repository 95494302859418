import { PlayerProfileDTO } from '../../dto/playerProfileDTO'

export interface IUserState {
    userInfo: PlayerProfileDTO, tkn: string, isLogin: boolean, loading: boolean, error: {}
}

export const InitUserState: IUserState = {
    userInfo: {} as PlayerProfileDTO, tkn: "", isLogin: false, loading: false, error: ""
}

