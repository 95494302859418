export enum ErrorCode {
    /// <summary>
    ///     Accounting - login failed, session exist
    /// </summary>
    ACCOUNTING_SESSION_EXIST = 0X1000001,
    /// <summary>
    ///     Accounting - login failed
    /// </summary>
    ACCOUNTING_LOGIN_FAILED = 0X1000002,
    /// <summary>
    ///     Accounting - login failed, wrong username or password
    /// </summary>
/// <reference path="../assets/errormessages.ts" />
    ACCOUTNING_INVALID_USERNAME_PASSWORD = 0X1000003,
    /// <summary>
    ///     Accounting - login failed, player locked
    /// </summary>
    ACCOUNTING_PLAYER_LOCKED = 0X1000004,
    /// <summary>
    ///     Accounting - request invalid session
    /// </summary>
    ACCOUNTING_INVALID_SESSION = 0X1000005,
    /// <summary>
    ///     Accounting - cannot join game
    /// </summary>
    ACCOUNTING_GAME_JOIN_FAILED = 0X1000006,
    /// <summary>
    ///     Accounting - failed to transfer
    /// </summary>
    ACCOUNTING_ECA_FAILED = 0X1000007
}