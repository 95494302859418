export enum PlayerWalletActionType {
    UPDATE_LOCAL_BALANCE_REQUEST = "UPDATE_LOCAL_BALANCE_REQUEST",
    UPDATE_LOCAL_BALANCE_SUCCESS = "UPDATE_LOCAL_BALANCE_SUCCESS",
    UPDATE_LOCAL_BALANCE_FAILED = "UPDATE_LOCAL_BALANCE_FAILED",
    GET_CMS_BALANCE_REQUEST = "GET_CMS_BALANCE_REQUEST",
    GET_CMS_BALANCE_SUCCESS = "GET_CMS_BALANCE_SUCCESS",
    GET_CMS_BALANCE_FAILED = "GET_CMS_BALANCE_FAILED",
    GET_BALANCE_REQUEST = "GET_BALANCE_REQUEST",
    GET_BALANCE_SUCCESS = "GET_BALANCE_SUCCESS",
    GET_BALANCE_FAILED = "GET_BALANCE_FAILED",
    GET_TRANSFER_IN_LIMIT_REQUEST = "GET_TRANSFER_IN_LIMIT_REQUEST",
    GET_TRANSFER_IN_LIMIT_SUCCESS = "GET_TRANSFER_IN_LIMIT_SUCCESS",
    GET_TRANSFER_IN_LIMIT_FAILED = "GET_TRANSFER_IN_LIMIT_FAILED",
    TRANSFER_IN_REQUEST = "TRANSFER_IN_REQUEST",
    TRANSFER_IN_SUCCESS = "TRANSFER_IN_SUCCESS",
    TRANSFER_IN_FAILED = "TRANSFER_IN_FAILED",
    LOGIN_WALLET_VIP_SUCCESS = "SELECT_WALLET_VIP_SUCCESS",
    LOGIN_WALLET_COMMON_SUCCESS = "SELECT_WALLET_COMMON_SUCCESS",
    TRANSFER_IN_RESET = "TRANSFER_IN_RESET",
    WALLET_RESET = "WALLET_RESET"
}