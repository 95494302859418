import { IString } from "./IString";

export const errorMessages: IString = {
    "ERROR": [
        "An Error has Occured",
        "发生错误",
        "發生錯誤"
    ],
    "ACCOUNTING_SESSION_EXIST": [
        "Session exist",
        "会话存在",
        "會話存在"
    ],
    "ACCOUNTING_LOGIN_FAILED": [
        "Login failed",
        "登录失败",
        "登入失敗"
    ],
    "ACCOUTNING_INVALID_USERNAME_PASSWORD": [
        "Incorrect ID or password",
        "会员号码或密码错误",
        "會員號碼或密碼錯誤"
    ],
    "ACCOUNTING_INVALID_SESSION": [
        "Invalid Session",
        "无效会话",
        "無效會話"
    ],
    "ACCOUNTING_GAME_JOIN_FAILED": [
        "Failed to Join Game",
        "无法加入游戏",
        "無法加入遊戲"
    ],
    "ACCOUNTING_ECA_FAILED": [
        "Failed to Transfer",
        "转账失败",
        "轉帳失敗"
    ],
    "ACCOUNTING_PLAYER_LOCKED": [
        "Player account is locked. Please request help from admin to unlock your account.",
        "玩家帐号已被锁定。 请向管理员请求协助以解锁您的帐户。",
        "玩家帳號已被鎖定。 請向管理員請求協助以解鎖您的帳戶。"
    ]
}
