import { PlayerProfileDTO } from "./dto/playerProfileDTO";

export const DemoProfile: PlayerProfileDTO = {
    uid: 0,
    unm: "1000000000",
    fnm: "[DEMO]",
    crd: "LO",
    llg: new Date(),
    stt: 0,
    vip: false,
    cur: "MYR",
    blc: {
        ec: 50000,
        nn: 50000,
        vipec: 50000,
        vipnn: 50000
    },
    lgg: 0,
    cms: 1,
    ftl: false
}

export const FunPlayProfile: PlayerProfileDTO = {
    uid: 0,
    unm: "0000000000",
    fnm: "[FUNPLAY ACCOUNT]",
    crd: "LO",
    llg: new Date(),
    blc: {
        ec: 0.00,
        nn: 0.00,
        vipec: 0.00,
        vipnn: 0.00
    },
    lgg: 0,
    stt: 0,
    vip: false,
    cms: 1,
    ftl: false,
    cur: "MYR"
}
