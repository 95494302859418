import GameWidget from '../components/GameWidget';
import { Col, Row } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../states/store/IStore';
import LoginModal from '../components/modals/LoginModal';
import React, { useContext, useEffect, useRef, useState } from 'react';
import "../styles/Dashboard.css";
import "odometer/themes/odometer-theme-minimal.css";
import Odometer from 'react-odometerjs'
import { LanguageModeContext } from '../context/LanguageContext';
import { strings } from '../assets/strings'
import { useNavigate } from 'react-router-dom';
import { getLobbyGames } from '../states/Lobby/LobbyActions';
import { GameRoomDTO, InitGameDTO } from '../dto/gameDTO';
import { GameMode } from "../enums/GameMode";
import { DemoModeContext } from '../context/DemoContext';
import { FunplayModeContext } from '../context/FunplayContext';
import axios from 'axios';
import GameFrame from '../components/GameFrame';
import { login } from '../states/Player/PlayerActions';
import btn_Play from '../assets/images/btn_Play.png'
import { Orientation } from '../enums/Style';
import { ThemesModeContext } from '../context/ThemesContext';
import Loader from '../components/Loader';
import { getJackpotMeter } from '../states/Jackpot/JackpotActions';
import { getRememberMeConfig } from '../states/PortalConfig/PortalConfigActions';

export type EnterGameDataDTO = {
    gameName: string,
    gameLink: string,
    initGame: InitGameDTO
}


const Dashboard = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const { language } = useContext(LanguageModeContext)
    const { orientation, theme } = useContext(ThemesModeContext)
    const [showModal, setshowModal] = useState(false)
    const userState = useSelector((state: IStore) => state.userState)
    const { isLogin, userInfo } = userState

    const lobbyState = useSelector((state: IStore) => state.lobbyState)
    const { lobbyGames, loading } = lobbyState

    const jackpotState = useSelector((state: IStore) => state.jackpotState)
    const { highestJackpot } = jackpotState

    const [gameList, setGameList] = useState<GameRoomDTO[] | undefined>(undefined)
    const [api, setApi] = useState("");
    const [isPortrait, setIsPortrait] = useState(false)

    const isDemo = useContext(DemoModeContext);
    const { funplayMode } = useContext(FunplayModeContext)

    const [isSingleGame, setIsSingleGame] = useState(false)
    const [singleGameFrameInit, setSingleGameFrameInit] = useState<InitGameDTO>({} as InitGameDTO)
    const [singleGameInfo, setSingleGameInfo] = useState<GameRoomDTO>({} as GameRoomDTO)
    const [enterGame, setEnterGame] = useState(false)
    const enterGameData = useRef({} as EnterGameDataDTO)

    const [showBanner, setShowBanner] = useState(false)
    const [displayJackpotAmount, setDisplayJackpotAmount] = useState(0)
    const [currency, setCurrency] = useState("")
    const jackpotAmount = useRef(0)
    const [jackpotTextFormatting, setJackpotTextFormatting] = useState(false)
    const [firstTimeLoaded, setFirstTimeLoaded] = useState(false)
    const currentJackpotVideoUrl = useRef("")
    const currentJackpotImageUrl = useRef("")
    const showJackpotAmount = useRef(false)
    const amount = useRef(0)

    const handleModalOpened = (value: boolean) => {
        setshowModal(value)
    }

    const getAPI = async () => {
        var { data } = await axios.get("API")
        setApi(data)
    }

    const goToGame = (name: string, link: string, initGameModel: InitGameDTO) => {
        navigate('/Game', {
            state: {
                gameName: name, gameLink: link, isGameContainer: true, initGame: initGameModel
            }
        })
    }

    const handleGameSelection = (name: string, link: string, gameRoom: GameRoomDTO) => {
        if (isLogin) {
            let token = localStorage.getItem('tkn')
            let initGameModel: InitGameDTO = {
                api: isDemo ? gameRoom.surl : api,
                tkn: token != undefined ? token : "",
                gmd: isDemo ? GameMode.DEMO : funplayMode ? GameMode.FUNPLAY : GameMode.NORMAL,
                lan: language,
                gid: gameRoom.gid,
                cms: userInfo.cms,
                jai: gameRoom.jai
            }

            goToGame(name, link, initGameModel)
        }
        else {
            setEnterGame(true)
            let initGameModel: InitGameDTO = {
                api: gameRoom.surl,
                tkn: "",
                gmd: GameMode.NORMAL,
                lan: language,
                gid: gameRoom.gid,
                cms: 0,
                jai: gameRoom.jai
            }
            enterGameData.current = {
                gameName: name,
                gameLink: link,
                initGame: initGameModel
            }

            if (isDemo) {
                dispatch(login("", language, GameMode.DEMO))
            }
            else {
                setshowModal(true)
            }
        }
    }

    const getBannerPortrait = () => {
        var jackpotVideo = document.getElementById("jackpotVideo") as HTMLVideoElement
        var jackpotImg = document.getElementById("jackpotImg") as HTMLImageElement
        var jackpotBannerAmtBox = document.getElementById("jackpotBannerAmountBox")

        if (jackpotBannerAmtBox) {

            if (jackpotImg && currentJackpotImageUrl.current != "var(--jackpot-banner-portrait-img)") {
                jackpotImg.style.backgroundImage = "var(--jackpot-banner-portrait-img)"
                setShowBanner(true)
            }

            if (jackpotVideo) {
                var vidVariable = getComputedStyle(document.body).getPropertyValue("--jackpot-banner-portrait-vid")
                var vidUrl = vidVariable.substring(vidVariable.indexOf('(') + 1, vidVariable.length - vidVariable.indexOf('(') + 2)

                if (vidUrl != undefined && jackpotVideo.src != vidUrl && currentJackpotVideoUrl.current != vidUrl) {
                    jackpotVideo.classList.add("lazy-loading")
                    jackpotVideo.classList.remove("show-jackpot")

                    currentJackpotVideoUrl.current = vidUrl

                    jackpotVideo.src = vidUrl
                } else {
                    jackpotVideo.classList.add("show-jackpot")
                    jackpotVideo.classList.remove("lazy-loading")
                }
            }
        }
    }

    const getBannerLandscape = () => {
        var jackpotVideo = document.getElementById("jackpotVideo") as HTMLVideoElement
        var jackpotImg = document.getElementById("jackpotImg") as HTMLImageElement
        var jackpotBannerAmtBox = document.getElementById("jackpotBannerAmountBox")

        if (jackpotBannerAmtBox) {
            if (jackpotImg && currentJackpotImageUrl.current != "var(--jackpot-banner-landscape-img)") {
                setShowBanner(true)
                jackpotImg.style.backgroundImage = "var(--jackpot-banner-landscape-img)"
            }

            if (jackpotVideo) {
                var vidVariable = getComputedStyle(document.body).getPropertyValue("--jackpot-banner-landscape-vid")
                var vidUrl = vidVariable.substring(vidVariable.indexOf('(') + 1, vidVariable.length - vidVariable.indexOf('(') + 2)

                if (vidUrl != undefined && jackpotVideo.src != vidUrl && currentJackpotVideoUrl.current != vidUrl) {
                    jackpotVideo.classList.add("lazy-loading")
                    jackpotVideo.classList.remove("show-jackpot")

                    currentJackpotVideoUrl.current = vidUrl

                    jackpotVideo.src = vidUrl
                } else {
                    jackpotVideo.classList.add("show-jackpot")
                    jackpotVideo.classList.remove("lazy-loading")
                }
            }
        }
    }

    useEffect(() => {
        showJackpotAmount.current = false
        currentJackpotVideoUrl.current = ""
        currentJackpotImageUrl.current = ""
        setIsSingleGame(false)
        getAPI()
        dispatch(getJackpotMeter())
        dispatch(getLobbyGames())
        dispatch(getRememberMeConfig())
        amount.current = 1
        setEnterGame(false)
    }, [])

    useEffect(() => {
        var jackpotBanner = document.getElementById("jackpotBanner")
        var jackpotBannerAmount = document.getElementById("jackpotBannerAmount")
        var jackpotBannerAmtBox = document.getElementById("jackpotBannerAmountBox")
        var jackpotTitleText = document.getElementById("jackpotTitleText")
        var jackpotBannerTitle = document.getElementById("jackpotBannerTitle")
        var jackpotVideo = document.getElementById("jackpotVideo")
        var jackpotImg = document.getElementById("jackpotImg") as HTMLImageElement
        var clientWidth = window.innerWidth
        var clientHeight = window.innerHeight

        if (clientWidth && clientHeight && jackpotBanner &&
            jackpotBannerAmtBox && jackpotBannerAmount && jackpotTitleText && jackpotBannerTitle) {
            if (showBanner && !showJackpotAmount.current) {
                if (isPortrait) {
                    jackpotBannerAmtBox.style.setProperty("--jackpot-amount-box-X", (jackpotBanner.clientLeft + (jackpotBanner.clientWidth * 0.07)) + "px");
                    jackpotBannerAmtBox.style.setProperty("--jackpot-amount-box-Y", (jackpotBanner.clientTop + (jackpotBanner.clientHeight * 0.545)) + "px");
                    jackpotBannerAmtBox.style.setProperty("--jackpot-amount-box-width", (jackpotBanner.clientWidth * 0.61) + "px");
                    jackpotBannerAmtBox.style.setProperty("--jackpot-amount-box-height", (jackpotBanner.clientHeight * 0.14) + "px");

                    jackpotBannerAmount.style.fontSize = (jackpotBanner.clientWidth / 20) + "px";
                    jackpotBannerTitle.style.fontSize = (jackpotBanner.clientWidth / 28) + "px";
                    jackpotTitleText.style.fontSize = (jackpotBanner.clientWidth / 20) + "px";

                    jackpotTitleText.style.left = (jackpotBanner.clientLeft + (jackpotBanner.clientWidth * 0.07)) + "px";
                    jackpotTitleText.style.width = (jackpotBanner.clientWidth * 0.61) + "px";

                    if (jackpotVideo) {
                        jackpotVideo.style.top = -((jackpotBanner.clientHeight * 1.3) * 0.11) + "px";
                        jackpotVideo.style.height = (jackpotBanner.clientHeight * 1.3) + "px";
                        jackpotVideo.style.width = jackpotBanner.clientWidth + "px";
                    }
                } else {
                    jackpotBannerAmtBox.style.setProperty("--jackpot-amount-box-X", (jackpotBanner.clientLeft + (jackpotBanner.clientWidth * 0.13)) + "px");
                    jackpotBannerAmtBox.style.setProperty("--jackpot-amount-box-Y", (jackpotBanner.clientTop + (jackpotBanner.clientHeight * 0.525)) + "px");
                    jackpotBannerAmtBox.style.setProperty("--jackpot-amount-box-width", (jackpotBanner.clientWidth * 0.48) + "px");
                    jackpotBannerAmtBox.style.setProperty("--jackpot-amount-box-height", (jackpotBanner.clientHeight * 0.24) + "px");

                    if (clientWidth < 800) {
                        jackpotBannerAmount.style.fontSize = (jackpotBanner.clientWidth / 20) + "px";
                        jackpotBannerTitle.style.fontSize = (jackpotBanner.clientWidth / 28) + "px";
                        jackpotTitleText.style.fontSize = (jackpotBanner.clientWidth / 20) + "px";
                    } else {
                        jackpotBannerAmount.style.fontSize = (jackpotBanner.clientHeight / 5) + "px";
                        jackpotBannerTitle.style.fontSize = (jackpotBanner.clientHeight / 8) + "px";
                        jackpotTitleText.style.fontSize = (jackpotBanner.clientHeight / 6) + "px";
                    }

                    jackpotTitleText.style.left = (jackpotBanner.clientLeft + (jackpotBanner.clientWidth * 0.13)) + "px";
                    jackpotTitleText.style.width = (jackpotBanner.clientWidth * 0.48) + "px";

                    if (jackpotVideo) {
                        jackpotVideo.style.top = -((jackpotBanner.clientHeight * 2.6) * 0.31) + "px";
                        jackpotVideo.style.height = (jackpotBanner.clientHeight * 2.6) + "px";
                        jackpotVideo.style.width = jackpotBanner.clientWidth + "px";
                    }
                }


                if (jackpotImg && jackpotImg.style.backgroundImage != "") {
                    jackpotImg.style.backgroundSize = jackpotBanner.clientWidth + "px " + jackpotBanner.clientHeight + "px";
                }
                adjustTitlePosition()

                var bannerDiv = document.getElementById("bannerDiv")

                if (bannerDiv) {
                    bannerDiv.style.setProperty("--jackpot-banner-height", (jackpotBanner.clientHeight) + "px");
                }
                setLobbyGameWidgetsOffset()
                showJackpotAmount.current = true
            }
        }
    }, [showBanner])

    const setLobbyGameWidgetsOffset = () => {
        var bannerDivEmpty = document.getElementById("bannerDivEmpty")
        var jackpotBanner = document.getElementById("jackpotBanner")
        if (jackpotBanner && bannerDivEmpty &&
            (bannerDivEmpty.style.getPropertyValue("--jackpot-banner-height") == "" || bannerDivEmpty.style.getPropertyValue("--jackpot-banner-height") == "0px")) {
            bannerDivEmpty.style.setProperty("--jackpot-banner-height", (jackpotBanner.clientHeight) + "px");
        }
    }

    const adjustTitlePosition = () => {
        var jackpotBanner = document.getElementById("jackpotBanner")
        var jackpotTitleText = document.getElementById("jackpotTitleText")

        if (jackpotTitleText && jackpotBanner && jackpotTitleText.clientHeight != 0) {
            if (isPortrait) {
                jackpotTitleText.style.top = (jackpotBanner.clientTop + (jackpotBanner.clientHeight * 0.52) - jackpotTitleText.clientHeight - (jackpotBanner.clientHeight * 0.05)) + "px";
            } else {
                jackpotTitleText.style.top = (jackpotBanner.clientTop + (jackpotBanner.clientHeight * 0.5) - jackpotTitleText.clientHeight - (jackpotBanner.clientHeight * 0.05)) + "px";
            }
        }
    }

    useEffect(() => {
        if (highestJackpot && highestJackpot.amt) {
            setCurrency(highestJackpot.cur)
            if (isPortrait) {
                getBannerPortrait()
            } else {
                getBannerLandscape()
            }

            var currentAmt = jackpotAmount.current

            if (highestJackpot.amt != jackpotAmount.current) {
                jackpotAmount.current = highestJackpot.amt
                if (highestJackpot.amt != 0) {
                    var jackpotAmt = highestJackpot.amt
                    var jackpotAmtText = jackpotAmt.toFixed(2)

                    if (jackpotAmt < 1) {
                        if (highestJackpot.amt < 1 && currentAmt > 1 && firstTimeLoaded) {
                            jackpotAmtText = "1.00"
                            setJackpotTextFormatting(true)
                            setDisplayJackpotAmount(parseFloat(jackpotAmtText))

                            window.setTimeout(() => {
                                var jackpotAmountText = document.getElementById("jackpotAmountText")
                                jackpotAmtText = '1' + jackpotAmount.current.toFixed(2)
                                if (jackpotAmountText)
                                    jackpotAmountText.classList.add("leading-zeros")

                                jackpotAmtText += '1'

                                setDisplayJackpotAmount(parseFloat(jackpotAmtText))
                            }, 600)
                        }
                        else if (!jackpotTextFormatting) {
                            jackpotAmtText = '1' + jackpotAmtText
                        }
                    }

                    jackpotAmtText += '1'

                    setDisplayJackpotAmount(parseFloat(jackpotAmtText))
                }
            }

            var jackpotBannerAmount = document.getElementById("jackpotBannerAmount")
            var jackpotBannerAmtBox = document.getElementById("jackpotBannerAmountBox")

            if (jackpotBannerAmount && jackpotBannerAmtBox) {
                if (jackpotBannerAmount.clientWidth > jackpotBannerAmtBox.clientWidth) {
                    jackpotBannerAmount.style.fontSize = (jackpotBannerAmtBox.clientWidth / 8) + "px";
                }
            }

            if (!firstTimeLoaded)
                setFirstTimeLoaded(true)
        }
    }, [highestJackpot])

    useEffect(() => {
        if (lobbyGames && lobbyGames.lbg && lobbyGames.lbg[0] != null) {
            setEnterGame(false)
            setGameList(lobbyGames.lbg)
            setLobbyGameWidgetsOffset()
            //if (lobbyGames.lbg.length == 1) {
            //    let token = localStorage.getItem('tkn')

            //    let initGame: InitGameDTO = {
            //        api: api,
            //        tkn: token != undefined ? token : "",
            //        gmd: GameMode.PREVIEW,
            //        lan: language,
            //        gid: lobbyGames.lbg[0].gid,
            //        cms: userInfo.cms,
            //    }
            //    setSingleGameFrameInit(initGame)
            //    setSingleGameInfo(lobbyGames.lbg[0])
            //    setIsSingleGame(true)
            //}
        }
    }, [lobbyGames])

    useEffect(() => {
        setIsPortrait(orientation == Orientation.PORTRAIT)
        showJackpotAmount.current = false
        if (showBanner) {
            if (orientation == Orientation.PORTRAIT) {
                getBannerPortrait()
            } else {
                getBannerLandscape()
            }
        }
    }, [orientation, theme])

    useEffect(() => {
        if (enterGame) {
            if (Object.keys(enterGameData.current).length > 0) {
                let token = localStorage.getItem('tkn')
                let language = Number.parseInt(sessionStorage.getItem('lang') || '0')
                enterGameData.current = {
                    gameName: enterGameData.current.gameName,
                    gameLink: enterGameData.current.gameLink,
                    initGame: {
                        api: isDemo ? enterGameData.current.initGame.api : api,
                        tkn: token != undefined ? token : "",
                        gmd: isDemo ? GameMode.DEMO : funplayMode ? GameMode.FUNPLAY : GameMode.NORMAL,
                        lan: language != undefined ? language : 0,
                        gid: enterGameData.current.initGame.gid,
                        cms: userInfo.cms,
                        jai: enterGameData.current.initGame.jai
                    }
                }

                goToGame(enterGameData.current.gameName, enterGameData.current.gameLink, enterGameData.current.initGame)
            }

        }
    }, [isLogin])


    return (
        <div>
            {isSingleGame ? "" :
                <div id="bannerDiv" className={showBanner ? "" : "hidden"}>
                    <div id="bannerShimmer" className={"shimmer w-100 h-100 position-absolute"}></div>
                    <Col id="jackpotBanner" className={isPortrait ? "portrait" : "landscape"}>
                        <div id="jackpotImg"> </div>
                        <div id="jackpotVideoContainer" >
                            <video id="jackpotVideo" autoPlay loop muted />
                        </div>
                        <Row id="jackpotTitleText" className="jackpot-title-text fade-in">
                            <span id="bannerGameTitle" className="w-auto">{strings.SLOT_GAME[language]}</span>
                            <span id="jackpotBannerTitle" className="w-auto">{strings.HIGHEST_JACKPOT_PRIZE[language]} ({currency})</span>
                        </Row>
                        <Row id="jackpotBannerAmountBox" className="jackpot-banner-amount fade-in">
                            {jackpotAmount.current != 0 ? <>
                                <span id="jackpotAmountText" className={"jackpot-amount-text trailing-zeros" + (jackpotAmount.current < 1 && displayJackpotAmount != 1 ? " leading-zeros" : "")}>
                                    <Odometer
                                        id="jackpotBannerAmount" className={!showJackpotAmount && displayJackpotAmount == 0 ? "hidden" : "odometer"}
                                        format="(,ddd).ddd"
                                        duration={500}
                                        value={displayJackpotAmount}
                                    />
                                </span>
                            </> :
                                <span id="jackpotBannerAmount">0.00</span>}
                        </Row>
                    </Col>
                </div>
            }
            <Row className={"dashboard-row " + (isPortrait ? "portrait" : "landscape")}>
                {lobbyGames && lobbyGames.lbg ? <>
                    {isSingleGame ?
                        <Col className="w-100" >
                            <Row className="mb-3" onClick={() => { handleGameSelection(singleGameInfo.gmn, singleGameInfo.url, lobbyGames.lbg[0]) }}>
                                <Col className="position-relative">
                                    <GameFrame
                                        gameLink={singleGameInfo.url}
                                        showOptions={false}
                                        initGame={singleGameFrameInit}
                                        frameHeight={'75vh'}
                                        disableAnimation={true}
                                    />
                                    <div className="game-overlay" >
                                        <div>
                                            <img className="play-btn" src={btn_Play} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col id="widget" className="description-widget">
                                    <img className="game-widget-img shimmer" src={"data:image/png;base64," + singleGameInfo.icn} />
                                </Col>
                                <Col className="description">
                                    <Row>
                                        <Col className="game-name">
                                            {singleGameInfo.gmn}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="game-description">
                                            {singleGameInfo.des}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col> :
                        <Col>
                            <Row id="bannerDivEmpty" className={(showBanner ? "banner-div " : "hidden ") + (isPortrait ? "portrait" : "landscape")} >
                            </Row>
                            {loading ? <Loader /> :
                                <>
                                    <Row className="fade-in-top position-relative">
                                        <h3>{strings.SLOT_MACHINE[language]}</h3>
                                    </Row>
                                    <Row className={"justify-content-start fade-in-top " + (isPortrait ? "d-flex flex-column" : "position-relative")}>
                                        {gameList && gameList.map((game) => {
                                            return (<GameWidget key={game.gid} onSelect={handleGameSelection} gameInfo={game} />)
                                        })}
                                    </Row>
                                </>
                            }
                        </Col>
                    }</> : <></>}
                <LoginModal openModal={showModal} onChange={handleModalOpened} title={strings.LOGIN[language]}></LoginModal>
            </Row>
        </div>
    );
}

export default (Dashboard);
