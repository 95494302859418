import React, { useRef } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from './containers/Dashboard';
import History from './containers/History';
import Layout from './containers/shared/Layout';
import Header from './containers/shared/Header';
import PlayerProfile from './containers/PlayerProfile';
import { logout, tokenValidation } from './states/Player/PlayerActions'

import "./styles/Main.css";
import "./styles/Modal.css";
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from './states/store/IStore';
import { Container } from 'reactstrap';
import { DemoContext, DemoModeContext } from './context/DemoContext';
import { LanguageContext } from './context/LanguageContext';
import { FunplayContext, FunplayModeContext } from './context/FunplayContext';
import { ThemesContext } from './context/ThemesContext';
import GameFrame from './components/GameFrame';
import GameContainer from './containers/GameContainer';
import Idler from './components/Idler';
import { WorkerActions } from './enums/WorkerActions';
import { updateLocalBalance } from './states/PlayerWallet/PlayerWalletActions';
import { updateLocalJackpotMeter } from './states/Jackpot/JackpotActions';
import { WorkerMessageDTO } from './dto/workerMessageDTO';
import BottomNavigationBar from './containers/shared/NavigationBar';
import { InitGameDTO } from './dto/gameDTO';
import { GameMode } from './enums/GameMode';
import NotificationModal from './components/modals/NotificationModal';
import { errorMessages } from './assets/errorMessages';
import { ErrorCode } from './enums/ErrorCode';
import { strings } from './assets/strings';
import LogoutLoadingModal from './components/modals/LogoutLoadingModal';

export const postToIframe = async (json: any) => {
    var iframe: HTMLIFrameElement = document.getElementById("gameFrame") as HTMLIFrameElement;
    if (iframe != null && iframe.contentWindow != null) {
        try {
            await iframe.contentWindow.postMessage(json, new URL(iframe.src).origin);
        }
        catch (error) {
            console.log(error)
        }
    }
}

export const resetFunctions = () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
            if (registration.active) {
                if (localStorage.getItem('balanceUpdateRunning')) {
                    localStorage.removeItem('balanceUpdateRunning')
                    registration.active.postMessage({
                        actionType: WorkerActions.BALANCE_DATA_UPDATE,
                        data: null
                    });
                }
            }
        });
    }
}

export const updateToken = (token: string) => {
    localStorage.setItem('tkn', token)
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
            if (registration.active) {
                registration.active.postMessage({
                    actionType: WorkerActions.UPDATE_TOKEN,
                    data: localStorage.getItem('tkn')
                });
            }
        });
    }
}

export default () => {
    const dispatch = useDispatch()
    const location = useLocation()

    const keepAlive = useRef<NodeJS.Timeout | undefined>(setInterval(() => { }, 0))
    const [initializeListener, setInitializeListener] = useState(false)
    const userState = useSelector((state: IStore) => state.userState)
    const { loading, isLogin } = userState
    const isDemo = useContext(DemoModeContext)
    const { funplayMode } = useContext(FunplayModeContext)
    const playerLogin = useRef<boolean>(false)
    const lastBalanceUpdate = useRef<number | undefined>(undefined)
    const lastJackpotUpdate = useRef<number | undefined>(undefined)
    const [showNotificationModal, setshowNotificationModal] = useState(false);
    const [showLogoutModal, setshowLogoutModal] = useState(false);

    const onStorageChange = (e: StorageEvent) => {
        if (!localStorage.getItem('tkn')) {
            dispatch(logout(isDemo ? GameMode.DEMO : funplayMode ? GameMode.FUNPLAY : GameMode.NORMAL))
            resetFunctions()
        }

        if (e.key === 'tkn') {
            if (localStorage.getItem('tkn')) {
                dispatch(tokenValidation())
            }
        }
    };

    window.onblur = () => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then((registration) => {
                if (registration.active) {
                    registration.active.postMessage({
                        actionType: WorkerActions.CHECK_OPENED_TABS,
                        data: window.origin
                    });
                }
            });
        }
    }

    window.onfocus = () => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then((registration) => {
                if (registration.active) {
                    registration.active.postMessage({
                        actionType: WorkerActions.CHECK_OPENED_TABS,
                        data: window.origin
                    });
                }
            });
        }
    }

    window.onload = () => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then((registration) => {
                if (registration.active) {
                    registration.active.postMessage({
                        actionType: WorkerActions.CHECK_OPENED_TABS,
                        data: window.origin
                    });
                }
            });
        }
    }

    const postDataToGame = async (e: any) => {
        if (e.data) {
            var message = e.data as WorkerMessageDTO
            switch (message.actionType) {
                case WorkerActions.BALANCE_DATA_UPDATE:
                    if (playerLogin.current && !isDemo && !funplayMode) {
                        localStorage.setItem('balanceUpdateRunning', 'true')
                        if (message.data.err == ErrorCode.ACCOUNTING_INVALID_SESSION) {
                            setshowNotificationModal(true)
                            setshowLogoutModal(true)
                        } else {
                            lastBalanceUpdate.current = Date.now()
                            dispatch(updateLocalBalance(message.data))
                            postToIframe({
                                type: "updateBalance",
                                res: message.data.res
                            });
                        }
                    }
                    break;
                case WorkerActions.JACKPOT_DATA_UPDATE:
                    lastJackpotUpdate.current = Date.now()
                    localStorage.setItem('jackpotUpdateRunning', 'true')

                    dispatch(updateLocalJackpotMeter(message.data))
                    postToIframe({
                        type: "updateJackpot",
                        res: message.data.res
                    });
                    break;
            }
        }
    }

    const checkLastUpdate = () => {
        if (isLogin && lastBalanceUpdate.current != undefined && (Date.now() - lastBalanceUpdate.current) >= 10000) {
            resetUpdateBalanceThread()
        }

        if (lastJackpotUpdate.current != undefined && (Date.now() - lastJackpotUpdate.current) >= 10000) {
            resetUpdateJackpotThread()
        }
    }

    const resetUpdateJackpotThread = () => {
        navigator.serviceWorker.ready.then((registration) => {
            if (registration.active) {
                if (localStorage.getItem('jackpotUpdateRunning')) {
                    localStorage.removeItem('jackpotUpdateRunning')
                    registration.active.postMessage({
                        actionType: WorkerActions.JACKPOT_DATA_UPDATE,
                        data: null
                    });
                }

                if (!localStorage.getItem('jackpotUpdateRunning') || (localStorage.getItem('jackpotUpdateRunning') && localStorage.getItem('jackpotUpdateRunning') == 'false')) {
                    try {
                        registration.active.postMessage({
                            actionType: WorkerActions.JACKPOT_DATA_UPDATE,
                            data: ""
                        });
                    } catch (e) {
                        localStorage.setItem('jackpotUpdateRunning', 'false')
                    }
                }
            }
        });
    }

    const resetUpdateBalanceThread = () => {
        navigator.serviceWorker.ready.then((registration) => {
            if (registration.active) {
                if (localStorage.getItem('balanceUpdateRunning')) {
                    localStorage.removeItem('balanceUpdateRunning')
                    registration.active.postMessage({
                        actionType: WorkerActions.BALANCE_DATA_UPDATE,
                        data: null
                    });
                }
                if (localStorage.getItem('tkn') && !isDemo && !funplayMode) {
                    registration.active.postMessage({
                        actionType: WorkerActions.UPDATE_TOKEN,
                        data: localStorage.getItem('tkn')
                    });

                    if (localStorage.getItem('tkn') && (!localStorage.getItem('balanceUpdateRunning') || (localStorage.getItem('balanceUpdateRunning') && localStorage.getItem('balanceUpdateRunning') == 'false'))) {
                        try {
                            registration.active.postMessage({
                                actionType: WorkerActions.BALANCE_DATA_UPDATE,
                                data: ""
                            });
                        } catch (e) {
                            localStorage.setItem('balanceUpdateRunning', 'false')
                        }
                    }
                }
            }
        });
    }

    useEffect(() => {
        if (!sessionStorage.getItem('lang'))
            sessionStorage.setItem('lang', '0')

        //first time load checking 
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js`).then((registration) => {
                if (registration.active) {
                    registration.active.postMessage({
                        actionType: WorkerActions.CHECK_OPENED_TABS,
                        data: window.origin
                    });

                    keepAlive.current = setInterval(() => {
                        if ('serviceWorker' in navigator) {
                            if (registration.active) {
                                registration.active.postMessage({
                                    actionType: WorkerActions.KEEP_ALIVE,
                                    data: ""
                                });
                            }
                        }
                    }, 20000)
                }
            });

            resetUpdateBalanceThread()
            resetUpdateJackpotThread()

            navigator.serviceWorker.removeEventListener('message', postDataToGame);
            navigator.serviceWorker.addEventListener('message', postDataToGame);
        }

        if (!initializeListener) {
            window.addEventListener('storage', onStorageChange);
            document.addEventListener('contextmenu', event => event.preventDefault());
            document.addEventListener('keydown', e => {
                if (e.key == "F11")
                    e.preventDefault()

                //Disable developer tools shortcut
                //if (e.key == "F12")
                //    e.preventDefault();
                //if (e.ctrlKey && e.shiftKey && e.key == 'I')
                //    e.preventDefault();
                //if (e.ctrlKey && e.shiftKey && e.key == 'C')
                //    e.preventDefault();
                //if (e.ctrlKey && e.shiftKey && e.key == 'J')
                //    e.preventDefault();
                //if (e.ctrlKey && e.key == 'U')
                //    e.preventDefault();
            });
            setInitializeListener(true)
        }

        if (!loading && localStorage.getItem('tkn')) {
            dispatch(tokenValidation())
        }
    }, [])

    useEffect(() => {
        if (!location.pathname.includes('/Game')) {
            window.onbeforeunload = null
        }

    }, [location.pathname])

    useEffect(() => {
        playerLogin.current = isLogin
    }, [isLogin])

    return (
        <>
            {checkLastUpdate()}
            <ThemesContext>
                <LanguageContext>
                    <DemoContext>
                        <FunplayContext>
                            {!location.pathname.includes('/Game') ?
                                <Container fluid className="d-flex flex-column justify-content-around p-0">
                                    <Header />
                                    <Layout>
                                        <Routes>
                                            <Route path='/' element={<Dashboard />} />
                                            <Route path='/History' element={<History />} />
                                            <Route path='/PlayerProfile' element={<PlayerProfile />} />
                                        </Routes>
                                    </Layout>
                                    <BottomNavigationBar />
                                </Container>
                                :
                                <GameContainer>
                                    <Routes>
                                        <Route path='/Game' element={<GameFrame gameLink="" initGame={{} as InitGameDTO} showOptions={false} />} />
                                    </Routes>
                                </GameContainer>}
                            <Idler />
                            <LogoutLoadingModal openModal={showLogoutModal} onChange={setshowLogoutModal} />
                            <NotificationModal
                                openModal={showNotificationModal}
                                onChange={setshowNotificationModal}
                                title={errorMessages.ACCOUNTING_INVALID_SESSION[Number.parseInt(sessionStorage.getItem('lang') || '0')]}
                                content={strings.ACCOUNTING_INVALID_SESSION_LOGGED_OUT[Number.parseInt(sessionStorage.getItem('lang') || '0')]}
                                appearSeconds={5} />
                        </FunplayContext>
                    </DemoContext>
                </LanguageContext>
            </ThemesContext>
        </>
    )
};
