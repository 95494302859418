import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Row } from 'reactstrap';
import HistoryGameList from '../components/HistoryGameList';
import HistoryWidget from '../components/HistoryWidget';
import { IStore } from '../states/store/IStore';
import "../styles/History.css";

const History = () => {
    const navigate = useNavigate();

    const userState = useSelector((state: IStore) => state.userState)
    const { isLogin, userInfo } = userState

    const [selectedGameServices, setSelectedGameServices] = useState(0)
    const [selectedGameName, setSelectedGameName] = useState("")
    const [showHistory, setShowHistory] = useState(false)

    const backToServices = (isBack: boolean) => {
        if (isBack)
            setShowHistory(false)
    }

    const selectGameService = (id: number, name: string) => {
        setSelectedGameServices(id)
        setSelectedGameName(name)
        setShowHistory(true)
    }

    useEffect(() => {
        if (localStorage.getItem('tkn') == null) {
            navigate('/')
        }
    }, [isLogin])

    return (
        <>
            <Row className="d-flex justify-content-center align-items-center flex-column">
                {showHistory == true ?
                    <HistoryWidget serviceId={selectedGameServices} gameName={selectedGameName} onBackToServices={backToServices} playerID={userInfo.uid} username={userInfo.unm} /> :
                    <HistoryGameList onSelect={selectGameService} playerID={userInfo.uid} />
                }
            </Row>
        </>
    );
}
export default History;
