import React, { createContext, useEffect, useState } from "react";
import { Orientation, Theme } from "../enums/Style";

export const ThemesModeContext = createContext<any>({});

export const ThemesContext = (props: any) => {
    const [orientation, setOrientation] = useState(Orientation.LANDSCAPE);
    const [theme, setTheme] = useState(Theme.LIGHT);

    const [firstLoad, setFirstLoad] = useState(false)

    let movement_timer: NodeJS.Timeout;
    const RESET_TIMEOUT = 200;
    const test_dimensions = () => {
        var clientWidth = window.innerWidth
        var clientHeight = window.innerHeight
        if (clientWidth && clientHeight) {

            var currentOrientation = Orientation.LANDSCAPE

            if (window.orientation == undefined) {
                currentOrientation = (clientWidth < 800 ? Orientation.PORTRAIT : Orientation.LANDSCAPE)
            }
            else {
                if (clientWidth < 900) {
                    currentOrientation = (window.orientation == 0 ? Orientation.PORTRAIT : Orientation.LANDSCAPE)
                }
            }

            adjustComponents(currentOrientation)
            setOrientation(currentOrientation)
        }
    }

    const initializeDimension = () => {
        test_dimensions()
        setFirstLoad(false)
    }

    const adjustComponents = (orientation: Orientation) => {
        var clientWidth = window.innerWidth
        var clientHeight = window.innerHeight
        if (clientWidth && clientHeight) {
            var header = document.getElementById("header")
            var navBar = document.getElementById("navBar")
            var layout = document.getElementById("layout")

            if (header && navBar && layout) {
                header.style.width = (clientWidth).toString() + "px"
                if (orientation == Orientation.LANDSCAPE) {
                    navBar.style.marginTop = (header.clientHeight).toString() + "px"
                    layout.style.marginLeft = (navBar.clientWidth).toString() + "px"
                    layout.style.width = (header.clientWidth - navBar.clientWidth).toString() + "px"
                    layout.style.marginBottom = "0px"
                } else {
                    navBar.style.marginTop = "0px"
                    layout.style.marginLeft = "0px"
                    layout.style.width = (header.clientWidth).toString() + "px"
                    layout.style.marginBottom = "5em"
                }

                var bannerDiv = document.getElementById("bannerDiv")
                if (bannerDiv) {
                    if (orientation == Orientation.LANDSCAPE) {
                        bannerDiv.style.width = (clientWidth - navBar.clientWidth).toString() + "px"
                        bannerDiv.style.marginLeft = (navBar.clientWidth).toString() + "px"
                    } else {
                        bannerDiv.style.width = (clientWidth).toString() + "px"
                        bannerDiv.style.marginLeft = "0px"
                    }
                }
            }
        }

        var jackpotBanner = document.getElementById("jackpotBanner")
        var jackpotBannerAmount = document.getElementById("jackpotBannerAmount")
        var jackpotBannerAmtBox = document.getElementById("jackpotBannerAmountBox")
        var jackpotTitleText = document.getElementById("jackpotTitleText")
        var jackpotBannerTitle = document.getElementById("jackpotBannerTitle")
        var jackpotVideo = document.getElementById("jackpotVideo")
        var jackpotImg = document.getElementById("jackpotImg")

        if (clientWidth && jackpotBanner && jackpotBannerAmtBox && jackpotBannerAmount && jackpotTitleText && jackpotBannerTitle) {
            if (orientation == Orientation.PORTRAIT) {
                jackpotBannerAmtBox.style.setProperty("--jackpot-amount-box-X", (jackpotBanner.clientLeft + (jackpotBanner.clientWidth * 0.07)) + "px");
                jackpotBannerAmtBox.style.setProperty("--jackpot-amount-box-Y", (jackpotBanner.clientTop + (jackpotBanner.clientHeight * 0.545)) + "px");
                jackpotBannerAmtBox.style.setProperty("--jackpot-amount-box-width", (jackpotBanner.clientWidth * 0.61) + "px");
                jackpotBannerAmtBox.style.setProperty("--jackpot-amount-box-height", (jackpotBanner.clientHeight * 0.14) + "px");

                jackpotBannerAmount.style.fontSize = (jackpotBanner.clientWidth / 20) + "px";
                jackpotBannerTitle.style.fontSize = (jackpotBanner.clientWidth / 28) + "px";
                jackpotTitleText.style.fontSize = (jackpotBanner.clientWidth / 20) + "px";

                jackpotTitleText.style.left = (jackpotBanner.clientLeft + (jackpotBanner.clientWidth * 0.07)) + "px";
                jackpotTitleText.style.width = (jackpotBanner.clientWidth * 0.61) + "px";

                if (jackpotImg && jackpotImg.style.backgroundImage != "") {
                    jackpotImg.style.backgroundSize = jackpotBanner.clientWidth + "px " + jackpotBanner.clientHeight + "px";
                }

                if (jackpotVideo) {
                    jackpotVideo.style.top = -((jackpotBanner.clientHeight * 1.3) * 0.11) + "px";
                    jackpotVideo.style.width = jackpotBanner.clientWidth + "px";
                    jackpotVideo.style.height = (jackpotBanner.clientHeight * 1.3) + "px";
                }

                if (jackpotTitleText.clientHeight != 0) {
                    jackpotTitleText.style.top = (jackpotBanner.clientTop + (jackpotBanner.clientHeight * 0.52) - jackpotTitleText.clientHeight - (jackpotBanner.clientHeight * 0.05)) + "px";
                }
            } else {
                jackpotBannerAmtBox.style.setProperty("--jackpot-amount-box-X", (jackpotBanner.clientLeft + (jackpotBanner.clientWidth * 0.13)) + "px");
                jackpotBannerAmtBox.style.setProperty("--jackpot-amount-box-Y", (jackpotBanner.clientTop + (jackpotBanner.clientHeight * 0.525)) + "px");
                jackpotBannerAmtBox.style.setProperty("--jackpot-amount-box-width", (jackpotBanner.clientWidth * 0.48) + "px");
                jackpotBannerAmtBox.style.setProperty("--jackpot-amount-box-height", (jackpotBanner.clientHeight * 0.24) + "px");

                if (clientWidth < 900) {
                    jackpotBannerAmount.style.fontSize = (jackpotBanner.clientWidth / 20) + "px";
                    jackpotBannerTitle.style.fontSize = (jackpotBanner.clientWidth / 28) + "px";
                    jackpotTitleText.style.fontSize = (jackpotBanner.clientWidth / 20) + "px";
                } else {
                    jackpotBannerAmount.style.fontSize = (jackpotBanner.clientHeight / 5) + "px";
                    jackpotBannerTitle.style.fontSize = (jackpotBanner.clientHeight / 8) + "px";
                    jackpotTitleText.style.fontSize = (jackpotBanner.clientHeight / 6) + "px";
                }

                jackpotTitleText.style.left = (jackpotBanner.clientLeft + (jackpotBanner.clientWidth * 0.13)) + "px";
                jackpotTitleText.style.width = (jackpotBanner.clientWidth * 0.48) + "px";

                if (jackpotImg && jackpotImg.style.backgroundImage != "") {
                    jackpotImg.style.backgroundSize = jackpotBanner.clientWidth + "px " + jackpotBanner.clientHeight + "px";
                }

                if (jackpotVideo) {
                    jackpotVideo.style.top = -((jackpotBanner.clientHeight * 2.6) * 0.31) + "px";
                    jackpotVideo.style.height = (jackpotBanner.clientHeight * 2.6) + "px";
                    jackpotVideo.style.width = jackpotBanner.clientWidth + "px";
                }

                if (jackpotTitleText.clientHeight != 0) {
                    jackpotTitleText.style.top = (jackpotBanner.clientTop + (jackpotBanner.clientHeight * 0.5) - jackpotTitleText.clientHeight - (jackpotBanner.clientHeight * 0.05)) + "px";
                }
            }

            if (jackpotBannerAmount && jackpotBannerAmtBox) {
                if (jackpotBannerAmount.clientWidth > jackpotBannerAmtBox.clientWidth) {
                    jackpotBannerAmount.style.fontSize = (jackpotBannerAmtBox.clientWidth / 8) + "px";
                }
            }

            var bannerDiv = document.getElementById("bannerDiv")

            if (bannerDiv) {
                bannerDiv.style.setProperty("--jackpot-banner-height", (jackpotBanner.clientHeight) + "px");
            }

            var bannerDivEmpty = document.getElementById("bannerDivEmpty")
            var jackpotBanner = document.getElementById("jackpotBanner")
            if (jackpotBanner && bannerDivEmpty &&
                (bannerDivEmpty.style.getPropertyValue("--jackpot-banner-height") == "" || bannerDivEmpty.style.getPropertyValue("--jackpot-banner-height") == "0px" ||
                    bannerDivEmpty.style.getPropertyValue("--jackpot-banner-height") != (jackpotBanner.clientHeight) + "px")) {
                bannerDivEmpty.style.setProperty("--jackpot-banner-height", (jackpotBanner.clientHeight) + "px");
            }
        }
    }

    useEffect(() => {
        const currentTheme = localStorage.getItem('theme') || (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");

        if (currentTheme) {
            document.documentElement.setAttribute('data-theme', currentTheme);
            localStorage.setItem('theme', currentTheme);

            if (currentTheme == 'light')
                setTheme(Theme.LIGHT)
            else
                setTheme(Theme.DARK)
        }

        window.addEventListener('resize', () => {
            clearInterval(movement_timer);
            movement_timer = setTimeout(test_dimensions, RESET_TIMEOUT);

            test_dimensions()
        });
    }, [])

    useEffect(() => {
        if (!firstLoad) {
            var clientWidth = window.innerWidth
            var clientHeight = window.innerHeight
            if (clientWidth && clientHeight) {
                var currentOrientation = Orientation.LANDSCAPE

                if (window.orientation == undefined) {
                    currentOrientation = (clientWidth < 800 ? Orientation.PORTRAIT : Orientation.LANDSCAPE)
                }
                else {
                    if (clientWidth < 900) {
                        currentOrientation = (window.orientation == 0 ? Orientation.PORTRAIT : Orientation.LANDSCAPE)
                    }
                }

                adjustComponents(currentOrientation)
                setOrientation(currentOrientation)
                setFirstLoad(true)
            }
        }
    }, [firstLoad])

    useEffect(() => {
        const currentTheme = localStorage.getItem('theme') ? localStorage.getItem('theme') : null;

        if (currentTheme) {
            if (currentTheme == 'dark' && theme == Theme.LIGHT) {
                document.documentElement.setAttribute('data-theme', 'light');
                localStorage.setItem('theme', 'light');
            }
            else if (currentTheme == 'light' && theme == Theme.DARK) {
                document.documentElement.setAttribute('data-theme', 'dark');
                localStorage.setItem('theme', 'dark');
            }
        }
    }, [theme])

    useEffect(() => {
        test_dimensions()
    }, [props.children])

    return (
        <ThemesModeContext.Provider
            value={{ orientation, theme, setTheme }} data-theme={theme} >
            {firstLoad && initializeDimension()}
            {props.children}
        </ThemesModeContext.Provider>
    );
};