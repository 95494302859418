import { HighestJackpotPotDTO } from '../../dto/jackpotPoolDTO';
import * as JackpotAction from './JackpotActions';
import { JackpotActionType } from './JackpotConstants';
import { IJackpotState, InitJackpotState } from './JackpotStates';

export const jackpotReducer = (state = InitJackpotState, action: JackpotAction.ActionTypes): IJackpotState => {
    switch (action.type) {
        case JackpotActionType.UPDATE_LOCAL_JACKPOT_METER_REQUEST:
            return { ...state, error: "" }
        case JackpotActionType.UPDATE_LOCAL_JACKPOT_METER_SUCCESS:
            return { ...state, jackpotData: action.payload, error: "" }
        case JackpotActionType.UPDATE_LOCAL_JACKPOT_METER_FAILED:
            return { ...state, error: action.payload }
        case JackpotActionType.GET_JACKPOT_METER_REQUEST:
            return { ...state, error: "" }
        case JackpotActionType.GET_JACKPOT_METER_SUCCESS:
            return { ...state, jackpotData: action.payload, error: "" }
        case JackpotActionType.GET_JACKPOT_METER_FAILED:
            return { ...state, error: action.payload }
        case JackpotActionType.UPDATE_HIGHEST_JACKPOT:
            return { ...state, highestJackpot: action.payload as HighestJackpotPotDTO, error: "" }
        default:
            return state
    }
}
