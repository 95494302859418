export enum HistoryActionType {
    GET_HISTORY_GAME_REPLAY_REQUEST = "GET_HISTORY_GAME_REPLAY_REQUEST",
    GET_HISTORY_GAME_REPLAY_SUCCESS = "GET_HISTORY_GAME_REPLAY_SUCCESS",
    GET_HISTORY_GAME_REPLAY_FAILED = "GET_HISTORY_GAME_REPLAY_FAILED",
    CLEAR_HISTORY_GAME_REPLAY = "CLEAR_HISTORY_GAME_REPLAY",
    GET_HISTORY_GAME_SERVICE_REQUEST = "GET_HISTORY_GAME_SERVICE_REQUEST",
    GET_HISTORY_GAME_SERVICE_SUCCESS = "GET_HISTORY_GAME_SERVICE_SUCCESS",
    GET_HISTORY_GAME_SERVICE_FAILED = "GET_HISTORY_GAME_SERVICE_FAILED",
    GET_GAME_HISTORY_REQUEST = "GET_GAME_HISTORY_REQUEST",
    GET_GAME_HISTORY_SUCCESS = "GET_GAME_HISTORY_SUCCESS",
    GET_GAME_HISTORY_FAILED = "GET_GAME_HISTORY_FAILED",
    GET_ACTIVE_GAME_HISTORY_REQUEST = "GET_ACTIVE_GAME_HISTORY_REQUEST",
    GET_ACTIVE_GAME_HISTORY_SUCCESS = "GET_ACTIVE_GAME_HISTORY_SUCCESS",
    GET_ACTIVE_GAME_HISTORY_FAILED = "GET_ACTIVE_GAME_HISTORY_FAILED",
    HISTORY_RESET = "HISTORY_RESET",
}