import axios from 'axios';
import { PlayerActionType } from './PlayerConstants';
import { DemoProfile, FunPlayProfile } from '../../DemoFunplayData';
import { LoginDTO } from '../../dto/loginDTO'
import { LanguageDTO } from '../../dto/languageDTO';
import { PlayerWalletActionType } from '../PlayerWallet/PlayerWalletConstants';
import { PlayerProfileDTO } from '../../dto/playerProfileDTO';
import { GetErrorMessage } from '../../utils/ErrorMessageHelper';
import { Language } from '../../enums/Language';
import { GameMode } from '../../enums/GameMode';
import { FirstTimeLoginDTO } from '../../dto/firstTimeLoginDTO';
import { FsgDynamiqAccounting } from '../../dto/accountingRequestDTO';
import { HistoryActionType } from '../History/HistoryConstants';
import { updateToken } from '../../App';

// Custom Type Declaration
type Login_Request = { type: PlayerActionType.LOGIN_REQUEST };
type Login_Success = { type: PlayerActionType.LOGIN_SUCCESS, payload: {}, token: "" };
type Login_Failed = { type: PlayerActionType.LOGIN_FAILED, payload: {} };
type User_Reset = { type: PlayerActionType.USER_RESET, payload: {} };
type Logout_Request = { type: PlayerActionType.LOGOUT_REQUEST };
type Logout_Success = { type: PlayerActionType.LOGOUT_SUCCESS, payload: {} };
type Logout_Failed = { type: PlayerActionType.LOGOUT_FAILED, payload: {} };
type GetProfile_Request = { type: PlayerActionType.GET_PROFILE_REQUEST };
type GetProfile_Success = { type: PlayerActionType.GET_PROFILE_SUCCESS, payload: {} };
type GetProfile_Failed = { type: PlayerActionType.GET_PROFILE_FAILED, payload: {} };
type TokenValidation_Request = { type: PlayerActionType.TOKEN_VALIDATION_REQUEST };
type TokenValidation_Success = { type: PlayerActionType.TOKEN_VALIDATION_SUCCESS, token: "" };
type TokenValidation_Failed = { type: PlayerActionType.TOKEN_VALIDATION_FAILED, payload: {} };
type UpdateLocalBalance_Request = { type: PlayerActionType.UPDATE_LOCAL_BALANCE_REQUEST };
type UpdateLocalBalance_Success = { type: PlayerActionType.UPDATE_LOCAL_BALANCE_SUCCESS, payload: {} };
type UpdateLocalBalance_Failed = { type: PlayerActionType.UPDATE_LOCAL_BALANCE_FAILED, payload: {} };
type UpdateLanguagePref_Request = { type: PlayerActionType.UPDATE_LANGUAGE_PREF_REQUEST };
type UpdateLanguagePref_Success = { type: PlayerActionType.UPDATE_LANGUAGE_PREF_SUCCESS, payload: {} };
type UpdateLanguagePref_Failed = { type: PlayerActionType.UPDATE_LANGUAGE_PREF_FAILED, payload: {} };
type UpdateFirstTimeLogin_Request = { type: PlayerActionType.UPDATE_FIRST_TIME_LOGIN_REQUEST };
type UpdateFirstTimeLogin_Success = { type: PlayerActionType.UPDATE_FIRST_TIME_LOGIN_SUCCESS, payload: {} };
type UpdateFirstTimeLogin_Failed = { type: PlayerActionType.UPDATE_FIRST_TIME_LOGIN_FAILED, payload: {} };
type GetHistory_Request = { type: PlayerActionType.GET_HISTORY_REQUEST };
type GetHistory_Success = { type: PlayerActionType.GET_HISTORY_SUCCESS, payload: {} };
type GetHistory_Failed = { type: PlayerActionType.GET_HISTORY_FAILED, payload: {} };

export type ActionTypes =
    Login_Request |
    Login_Success |
    Login_Failed |
    User_Reset |
    Logout_Request |
    Logout_Success |
    Logout_Failed |
    GetProfile_Request |
    GetProfile_Success |
    GetProfile_Failed |
    TokenValidation_Request |
    TokenValidation_Success |
    TokenValidation_Failed |
    UpdateLocalBalance_Request |
    UpdateLocalBalance_Success |
    UpdateLocalBalance_Failed |
    UpdateLanguagePref_Request |
    UpdateLanguagePref_Success |
    UpdateLanguagePref_Failed |
    UpdateFirstTimeLogin_Request |
    UpdateFirstTimeLogin_Success |
    UpdateFirstTimeLogin_Failed |
    GetHistory_Request |
    GetHistory_Success |
    GetHistory_Failed;

const clearLocalStorage = () => {
    localStorage.removeItem('vip')
    localStorage.removeItem('idt')
    localStorage.removeItem('tkn')
}

export const login = (loginDetails: string, lang: Language, gameMode: GameMode = GameMode.NORMAL) => async (dispatch: any, getState: any) => {
    try {
        dispatch({
            type: PlayerActionType.LOGIN_REQUEST,
        });

        switch (gameMode) {
            case GameMode.NORMAL:
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }

                var loginData: LoginDTO = {
                    usn: loginDetails.split(',')[0],
                    pas: loginDetails.split(',')[1],
                    cms: 1
                }

                const { data } = await axios.post(
                    "Player/Login",
                    JSON.stringify(loginData),
                    config
                )

                if (data && data.err == 0) {
                     if (data.irr)
                        updateToken(data.tkn)

                    if (data.idt != null)
                        localStorage.setItem('idt', data.idt)

                    var profile: PlayerProfileDTO = JSON.parse(data.res)
                    localStorage.setItem('vip', String(profile.vip))

                    dispatch({
                        type: PlayerActionType.LOGIN_SUCCESS,
                        payload: profile,
                        token: data.tkn
                    });

                    if (profile.vip) {
                        dispatch({
                            type: PlayerWalletActionType.LOGIN_WALLET_VIP_SUCCESS,
                            payload: { vipec: profile.blc.vipec, vipnn: profile.blc.vipnn },
                        });
                    } else {
                        dispatch({
                            type: PlayerWalletActionType.LOGIN_WALLET_COMMON_SUCCESS,
                            payload: { ec: profile.blc.ec, nn: profile.blc.nn },
                        });
                    }
                }
                else {
                    dispatch({
                        type: PlayerActionType.LOGIN_FAILED,
                        payload: GetErrorMessage(JSON.parse(data.err), lang)
                    })
                }
                break;
            case GameMode.DEMO:
                updateToken('demoToken')
                localStorage.setItem('idt', '300')
                sessionStorage.setItem('demo', 'true')

                dispatch({
                    type: PlayerActionType.LOGIN_SUCCESS,
                    payload: DemoProfile,
                    token: "demoToken"
                });
                break;
            case GameMode.FUNPLAY:
                updateToken('funplayToken')
                localStorage.setItem('idt', '300')
                sessionStorage.setItem('funplay', 'true')

                dispatch({
                    type: PlayerActionType.LOGIN_SUCCESS,
                    payload: FunPlayProfile,
                    token: "funplayToken"
                });
                break;

        }
    }
    catch (error) {
        dispatch({
            type: PlayerActionType.LOGIN_FAILED,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const logout = (gameMode: GameMode = GameMode.NORMAL) => async (dispatch: any, getState: any) => {
    if (!localStorage.getItem('tkn')) {
        dispatch({
            type: PlayerActionType.LOGOUT_SUCCESS,
        });
        dispatch({
            type: PlayerWalletActionType.WALLET_RESET,
        })
    }
    else {
        try {
            dispatch({
                type: PlayerActionType.LOGOUT_REQUEST,
            });

            let isLogout = false
            if (gameMode == GameMode.DEMO || gameMode == GameMode.FUNPLAY) {
                isLogout = true
                sessionStorage.removeItem('funplay')
                sessionStorage.removeItem('demo')
                clearLocalStorage()
            }
            else {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Token': localStorage.getItem('tkn') || "",
                    },
                }

                clearLocalStorage()
                dispatch({
                    type: PlayerActionType.USER_RESET,
                });
                dispatch({
                    type: HistoryActionType.HISTORY_RESET,
                });
                dispatch({
                    type: PlayerWalletActionType.WALLET_RESET,
                })

                const { data } = await axios.post(
                    "Player/Logout",
                    JSON.stringify(FsgDynamiqAccounting),
                    config
                )

                if (data.res && data.res == "true")
                    isLogout = true
            }

            if (isLogout) {
                dispatch({
                    type: PlayerActionType.LOGOUT_SUCCESS
                });
            }

        } catch (error) {
            dispatch({
                type: PlayerActionType.LOGOUT_FAILED,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    }
}


export const getPlayerProfile = (lang: Language, gameMode: GameMode = GameMode.NORMAL) => async (dispatch: any, getState: any) => {
    if (!localStorage.getItem('tkn')) {
        dispatch({
            type: PlayerActionType.LOGOUT_SUCCESS,
        });
        dispatch({
            type: PlayerWalletActionType.WALLET_RESET,
        })
    } else {
        try {
            var {
                walletState: { isVIP },
            } = getState()

            switch (gameMode) {
                case GameMode.NORMAL:
                    dispatch({
                        type: PlayerActionType.GET_PROFILE_REQUEST,
                    });

                    const config = {
                        headers: {
                            'Content-Type': 'application/json',
                            'Token': localStorage.getItem('tkn') || "",
                        },
                    }

                    const { data } = await axios.post(
                        "Player/Profile",
                        JSON.stringify(FsgDynamiqAccounting),
                        config
                    )

                    if (data && data.err == 0) {
                        let profile: PlayerProfileDTO = JSON.parse(data.res)
                         if (data.irr)
                            updateToken(data.tkn)

                        dispatch({
                            type: PlayerActionType.GET_PROFILE_SUCCESS,
                            payload: profile
                        });

                        if (profile.vip && !isVIP) {
                            dispatch({
                                type: PlayerWalletActionType.LOGIN_WALLET_VIP_SUCCESS,
                                payload: { vipec: profile.blc.vipec, vipnn: profile.blc.vipnn },
                            });
                        }
                        else {
                            dispatch({
                                type: PlayerWalletActionType.UPDATE_LOCAL_BALANCE_SUCCESS,
                                payload: profile.blc
                            })
                        }
                    } else {
                        dispatch({
                            type: PlayerActionType.GET_PROFILE_FAILED,
                            payload: GetErrorMessage(JSON.parse(data.err), lang)
                        })
                    }
                    break;
                case GameMode.DEMO:
                    dispatch({
                        type: PlayerActionType.GET_PROFILE_SUCCESS,
                        payload: DemoProfile
                    });
                    break;
                case GameMode.FUNPLAY:
                    dispatch({
                        type: PlayerActionType.GET_PROFILE_SUCCESS,
                        payload: FunPlayProfile
                    });
                    break;

            }
        }
        catch (error) {
            dispatch({
                type: PlayerActionType.GET_PROFILE_FAILED,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            })
        }
    }
}

export const tokenValidation = () => async (dispatch: any, getState: any) => {
    if (!localStorage.getItem('tkn')) {
        dispatch({
            type: PlayerActionType.LOGOUT_SUCCESS,
        });
        dispatch({
            type: PlayerWalletActionType.WALLET_RESET,
        })
    }
    else {
        try {
            dispatch({
                type: PlayerActionType.TOKEN_VALIDATION_REQUEST,
            });

            if (sessionStorage.getItem('demo') == 'true') {
                dispatch({
                    type: PlayerActionType.LOGIN_SUCCESS,
                    payload: DemoProfile,
                    token: 'demoToken'
                });
            }
            else if (sessionStorage.getItem('funplay') == 'true') {
                dispatch({
                    type: PlayerActionType.LOGIN_SUCCESS,
                    payload: FunPlayProfile,
                    token: 'funplayToken'
                });
            }
            else {
                const {
                    userState: { isLogin },
                } = getState()

                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Token': localStorage.getItem('tkn') || "",
                    },
                }

                const { data } = await axios.post(
                    "Player/TokenValidation",
                    JSON.stringify(FsgDynamiqAccounting),
                    config
                )
                if (data && data.err == 0) {
                    if (data.res === "true") {
                        if (!isLogin || isLogin === undefined) {
                            dispatch({
                                type: PlayerActionType.LOGIN_SUCCESS,
                                payload: {},
                                token: localStorage.getItem('tkn')
                            });
                        }
                    }
                    else {
                        clearLocalStorage()
                        if (isLogin) {
                            dispatch({
                                type: PlayerActionType.LOGOUT_SUCCESS,
                            });
                        }
                    }
                    dispatch({
                        type: PlayerActionType.TOKEN_VALIDATION_SUCCESS,
                        token: localStorage.getItem('tkn')
                    });
                } else {
                    clearLocalStorage()
                    dispatch({
                        type: PlayerActionType.LOGOUT_SUCCESS,
                    })
                    dispatch({
                        type: PlayerWalletActionType.WALLET_RESET,
                    })
                }
            }

        } catch (error) {
            clearLocalStorage()
            dispatch({
                type: PlayerActionType.TOKEN_VALIDATION_FAILED,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    }
}

export const updateLanguagePref = (language: number, lang: Language, gameMode: GameMode = GameMode.NORMAL) => async (dispatch: any, getState: any) => {
    if (!localStorage.getItem('tkn')) {
        dispatch({
            type: PlayerActionType.LOGOUT_SUCCESS,
        });
        dispatch({
            type: PlayerWalletActionType.WALLET_RESET,
        })
    } else {
        try {
            dispatch({
                type: PlayerActionType.UPDATE_LANGUAGE_PREF_REQUEST,
            });

            var {
                userState: { userInfo },
            } = getState()

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Token': localStorage.getItem('tkn') || "",
                },
            }

            var languageData: LanguageDTO = {
                lan: language,
                cms: 1
            }

            userInfo.lgg = language

            if (gameMode == GameMode.DEMO || gameMode == GameMode.FUNPLAY) {
                dispatch({
                    type: PlayerActionType.UPDATE_LANGUAGE_PREF_SUCCESS,
                    payload: userInfo
                });
            }
            else {
                const { data } = await axios.post(
                    "Player/UpdateLanguagePref",
                    JSON.stringify(languageData),
                    config
                )


                if (data && data.err == 0) {
                     if (data.irr)
                        updateToken(data.tkn)

                    dispatch({
                        type: PlayerActionType.UPDATE_LANGUAGE_PREF_SUCCESS,
                        payload: userInfo
                    });
                } else {
                    dispatch({
                        type: PlayerActionType.UPDATE_LANGUAGE_PREF_FAILED,
                        payload: GetErrorMessage(JSON.parse(data.err), lang)
                    })
                }
            }

        } catch (error) {
            dispatch({
                type: PlayerActionType.UPDATE_LANGUAGE_PREF_FAILED,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    }
}

export const updateFirstTimeLogin = (firstTimeLogin: boolean, lang: Language) => async (dispatch: any, getState: any) => {
    if (!localStorage.getItem('tkn')) {
        dispatch({
            type: PlayerActionType.LOGOUT_SUCCESS,
        });
        dispatch({
            type: PlayerWalletActionType.WALLET_RESET,
        })
    } else {
        try {
            dispatch({
                type: PlayerActionType.UPDATE_FIRST_TIME_LOGIN_REQUEST,
            });

            var {
                userState: { userInfo },
            } = getState()

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Token': localStorage.getItem('tkn') || "",
                },
            }

            var requestData: FirstTimeLoginDTO = {
                ftl: firstTimeLogin,
                cms: 1
            }

            const { data } = await axios.post(
                "Player/UpdateFistTimeLogin",
                JSON.stringify(requestData),
                config
            )
            var profile: PlayerProfileDTO = JSON.parse(data.res)
            userInfo.ftl = firstTimeLogin

            if (data && data.err == 0) {
                 if (data.irr)
                    updateToken(data.tkn)

                dispatch({
                    type: PlayerActionType.UPDATE_FIRST_TIME_LOGIN_SUCCESS,
                    payload: profile == null ? userInfo : profile
                });
            } else {
                dispatch({
                    type: PlayerActionType.UPDATE_FIRST_TIME_LOGIN_FAILED,
                    payload: GetErrorMessage(JSON.parse(data.err), lang)
                })
            }

        } catch (error) {
            dispatch({
                type: PlayerActionType.UPDATE_FIRST_TIME_LOGIN_FAILED,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    }
}
