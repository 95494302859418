import React, { useContext, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { strings } from "../assets/strings"
import { LanguageModeContext } from '../context/LanguageContext';
import { postToIframe } from "../App"
import { updateLanguagePref } from '../states/Player/PlayerActions';
import { useDispatch } from 'react-redux';
import { DemoModeContext } from '../context/DemoContext';
import { FunplayModeContext } from '../context/FunplayContext';
import { GameMode } from '../enums/GameMode';

const LanguageButton = (props: { demoMode: boolean, funplayMode: boolean }) => {
    const isDemo = useContext(DemoModeContext)
    const { funplayMode } = useContext(FunplayModeContext)
    const dispatch = useDispatch()
    const { language, setLanguage } = useContext(LanguageModeContext)
    const [isLanguageBtnOpen, setIsLanguageBtnOpen] = useState(false);
    const languageToggle = () => {
        setIsLanguageBtnOpen(!isLanguageBtnOpen);
    }

    const selectLanguage = (index: number) => {
        setLanguage(index)
        sessionStorage.setItem('lang', index.toString())
        if (!props.demoMode && !props.funplayMode)
            dispatch(updateLanguagePref(index, language, isDemo ? GameMode.DEMO : funplayMode ? GameMode.FUNPLAY : GameMode.NORMAL))
        postToIframe({
            type: "updateLanguage",
            lan: index
        });
    }

    return (
        <Dropdown toggle={languageToggle} isOpen={isLanguageBtnOpen} color="none">
            <DropdownToggle id="changeLanguage" className="navbar-toggler ps-3 pe-3" color="none">
                {strings.LANG[language]}
            </DropdownToggle>
            <DropdownMenu className="flex-grow languageDropdown">
                {strings.LANG.map((lang) => {
                    return (<DropdownItem key={strings.LANG.indexOf(lang)} className="languageDropdownItem" onClick={() => selectLanguage(strings.LANG.indexOf(lang))} active={language == strings.LANG.indexOf(lang) ? true : false}>
                        {lang}
                    </DropdownItem>)
                })}
            </DropdownMenu>
        </Dropdown>
    );
}
export default LanguageButton;
