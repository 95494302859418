import React, { useContext, useEffect, useState } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Button,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader'
import Message from '../Message'
import { getBalance, getCMSBalance, creditTransferIn, getTransferInLimit } from '../../states/PlayerWallet/PlayerWalletActions';
import { IStore } from '../../states/store/IStore';
import { faAnglesRight, faArrowRight, faCircleCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlayerWalletActionType } from '../../states/PlayerWallet/PlayerWalletConstants';
import { LanguageModeContext } from '../../context/LanguageContext';
import { strings } from '../../assets/strings';
import { FormatCurrency } from '../../utils/StringFormatHelper';
import { DemoModeContext } from '../../context/DemoContext';
import { GameMode } from '../../enums/GameMode';

const TransferInModal = (props: { openModal: boolean, onChange: any, walletType: number }) => {
    const isDemo = useContext(DemoModeContext)

    const dispatch = useDispatch()
    const [modal, setModal] = useState(props.openModal);
    const walletState = useSelector((state: IStore) => state.walletState)
    const { cmsWallet, currentWallet, transferInLimit, loading, error, transferInSuccess, isVIP } = walletState
    const userState = useSelector((state: IStore) => state.userState)
    const { userInfo } = userState
    const [amountText, setAmountText] = useState("");
    const [amountString, setAmountString] = useState("");
    const [amountNum, setAmountNum] = useState(0);
    const [maxInput, setMaxInput] = useState(14);
    const [availableAmount, setAvailableAmount] = useState("0.00");
    const [currentAmount, setCurrentAmount] = useState("0.00");
    const [buttonSlideRectLeft, setButtonSlideRectLeft] = useState(0)
    const [buttonSlideRectRight, setButtonSlideRectRight] = useState(0)
    const [transferInButtonTriggered, setTransferInButtonTriggered] = useState(false)
    const [transferLimit, setTransferLimit] = useState("0.00")
    const [isOverLimit, setIsOverLimit] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const { language } = useContext(LanguageModeContext)

    const toggle = () => {
        setModal(!modal);
        props.onChange(!modal);
    };

    const formatCurrencyInput = (input: string) => {
        let totalNum = ""

        if (input == "<") {
            if (amountString.length > 1) {
                totalNum = amountString.substring(0, amountString.length - 1)
                setAmountString(totalNum)
            }
            else {
                setAmountString("")
                totalNum = "0"
            }

        }
        else if (amountString.length < maxInput) {
            if (amountString != "0") {
                totalNum = amountString + input
                setAmountString(totalNum)
            }
            else {
                setAmountString(input)
                totalNum = input
            }
        }

        if (totalNum == "")
            totalNum = amountString


        let decimalNum = totalNum.substring(totalNum.length - 2)
        let num = totalNum.substring(0, totalNum.length - 2).length == 0 ? "0" : totalNum.substring(0, totalNum.length - 2)
        if (decimalNum.length == 1)
            decimalNum = "0" + decimalNum

        let numString = "0"

        if (num.length > 3) {
            numString = Number.parseInt(num).toLocaleString('en-US')

        } else {
            numString = num.toString()
        }

        if (totalNum != "0" && decimalNum != "") {
            if (numString == "")
                numString = "0"
            setAmountText(numString + "." + decimalNum)
            let numFloat = Number.parseFloat(num + "." + decimalNum)
            setAmountNum(numFloat)
        }
        else {
            setAmountText("")
            setAmountNum(0)
        }
    }

    const handleKeyDown = (input: string) => {
        switch (input) {
            //backspace
            case 'Backspace':
                formatCurrencyInput("<")
                break;
            case 'Enter':
                break;
        }

    }

    const handleButtonMoved = (mouse: any) => {
        let buttonArrow = document.getElementById("promptButton")

        if (buttonArrow) {
            buttonArrow.style.setProperty('--positionX', 0 + "px")
            document.addEventListener('mousemove', updateElementPosition)
        }
    }

    const updateElementPosition = (event: any) => {
        let mousePosX = event.clientX
        let buttonArrow = document.getElementById("promptButton")

        if (buttonArrow) {
            let buttonArrowBoundary = buttonArrow.getBoundingClientRect();

            if (buttonArrowBoundary) {
                if (buttonArrowBoundary.left > buttonSlideRectLeft && buttonArrowBoundary.right <= buttonSlideRectRight) {
                    buttonArrow.style.setProperty('--positionX', (mousePosX - buttonSlideRectLeft) + "px")
                }
                else if (buttonArrow.getBoundingClientRect().right >= buttonSlideRectRight) {
                    if (!isDemo)
                        triggerTransferIn()
                    document.removeEventListener('mousemove', updateElementPosition)
                }
            }
        }
    }

    const handleMouseUp = (event: any) => {
        let buttonArrow = document.getElementById("promptButton")

        if (buttonArrow) {
            let buttonArrowBoundary = buttonArrow.getBoundingClientRect();

            if (buttonArrowBoundary) {
                if (buttonArrowBoundary.right < buttonSlideRectRight) {
                    handleButtonStopped()
                }
            }
        }
    }

    const handleButtonStopped = () => {
        let buttonArrow = document.getElementById("promptButton")
        if (buttonArrow) {
            if (!transferInButtonTriggered)
                buttonArrow.style.setProperty('--positionX', 0 + "px")
        }
        document.removeEventListener('mousemove', updateElementPosition)
    }

    const triggerTransferIn = () => {
        setTransferInButtonTriggered(true)
        let buttonArrow = document.getElementById("promptButton")
        if (buttonArrow) {
            buttonArrow.classList.add("promptArrowShadowExpand")
        }
    }

    useEffect(() => {
        setModal(props.openModal)
        if (props.openModal) {
            dispatch({ type: PlayerWalletActionType.TRANSFER_IN_RESET })
            setTransferInButtonTriggered(false)
            setAmountText("")
            setAmountString("")
            setAmountNum(0)
            setAvailableAmount("0.00")
            setCurrentAmount("0.00")
            setTransferLimit("0.00")
            setIsOverLimit(false)
            setErrorMsg("")

            dispatch(getCMSBalance(isDemo ? GameMode.DEMO : GameMode.NORMAL))
            dispatch(getBalance(isDemo ? GameMode.DEMO : GameMode.NORMAL))
        }
    }, [props.openModal])

    useEffect(() => {
        if (cmsWallet && Object.keys(cmsWallet).length > 0) {
            if (isVIP && userInfo.vip && cmsWallet.vipec != undefined && cmsWallet.vipnn != undefined)
                setAvailableAmount(FormatCurrency(props.walletType == 0 ? cmsWallet.vipec : cmsWallet.vipnn))
            else if (cmsWallet.ec != undefined && cmsWallet.nn != undefined)
                setAvailableAmount(FormatCurrency(props.walletType == 0 ? cmsWallet.ec : cmsWallet.nn))
        }
        else {
            dispatch(getCMSBalance(isDemo ? GameMode.DEMO : GameMode.NORMAL))
        }

        if (currentWallet && Object.keys(currentWallet).length > 0) {
            if (isVIP && currentWallet.vipec != undefined && currentWallet.vipnn != undefined)
                setCurrentAmount(FormatCurrency(props.walletType == 0 ? currentWallet.vipec : currentWallet.vipnn))
            else if (currentWallet.ec != undefined && currentWallet.nn != undefined)
                setCurrentAmount(FormatCurrency(props.walletType == 0 ? currentWallet.ec : currentWallet.nn))
        }
        else {
            dispatch(getBalance(isDemo ? GameMode.DEMO : GameMode.NORMAL))
        }

        if (transferInLimit && Object.keys(transferInLimit).length > 0) {
            setTransferLimit(FormatCurrency(props.walletType == 0 ? transferInLimit.ec : transferInLimit.nn))
        } else {
            dispatch(getTransferInLimit(isDemo ? GameMode.DEMO : GameMode.NORMAL))
        }


    }, [currentWallet, cmsWallet])

    useEffect(() => {
        if (error != "") {
            setTransferInButtonTriggered(false)
        }
        else if (transferInButtonTriggered) {
            dispatch(creditTransferIn(amountNum, props.walletType, language))
            setAmountText("")
            setAmountString("")
            setAmountNum(0)
            setAvailableAmount("0.00")
            setCurrentAmount("0.00")
            setIsOverLimit(false)
            setErrorMsg("")
        }

    }, [transferInButtonTriggered, error])

    useEffect(() => {
        if (!isOverLimit) {
            setIsOverLimit(false)
            setErrorMsg("")

            let buttonSlide = document.getElementById("buttonSlide")
            if (buttonSlide) {
                setButtonSlideRectLeft(buttonSlide.getBoundingClientRect().left)
                setButtonSlideRectRight(buttonSlide.getBoundingClientRect().right)
            }
        }

    }, [amountText.length > 0 && (buttonSlideRectLeft == 0 || buttonSlideRectRight == 0)])


    useEffect(() => {

        if (transferInLimit && Object.keys(transferInLimit).length > 0) {
            if (amountNum > (props.walletType == 0 ? transferInLimit.ec : transferInLimit.nn)) {
                setIsOverLimit(true)
                setErrorMsg(strings.AMOUNT_MUST_BE_LESS_THAN[language] + transferLimit + "!")
            }
            else {
                setIsOverLimit(false)
                setErrorMsg("")
            }
        }

    }, [amountNum])

    return (
        <Modal id="transferInModal" isOpen={modal} >
            {loading ?
                (
                    <div className="loading-container">
                        <div>
                            <Loader />
                        </div>
                        <div>
                            {transferInButtonTriggered ? <>{strings.LOADING_TRANSACTION[language]}</> : <>{strings.LOADING[language]}</>}
                        </div>
                    </div>
                )
                : (<>
                    <ModalHeader id="transferInModalHeader" toggle={toggle}>
                        <div className="mb-2">
                            <span id="header-logo" className="header-logo pt-3 pb-3" />
                        </div>
                        <div>
                            {strings.TRANSFER_IN[language]} {props.walletType == 0 ? "EC" : "NN"}
                        </div>
                    </ModalHeader>
                    <ModalBody id="transferInModalBody">
                        {error ? (<Message variant='danger'>{error}</Message>)
                            : transferInSuccess ? (<>
                                <Row>
                                    <Col><FontAwesomeIcon icon={faCircleCheck} size="3x" className="mb-3 success-tick" /></Col>
                                </Row>
                                <Row>
                                    <Col>{strings.TRANSFER_IN_SUCCESSFUL[language]}</Col>
                                </Row>
                            </>) : (<>
                                <Row>
                                    <Col>{strings.AVAILABLE_BALANCE[language]}</Col>
                                </Row>
                                <Row>
                                    <Col><h3>{availableAmount}</h3></Col>
                                </Row>
                                <Row>
                                    <Col>{strings.CURRENT_BALANCE[language]}</Col>
                                </Row>
                                <Row >
                                    <Col ><h3>{currentAmount}</h3></Col>
                                </Row>
                                <Row className="mt-3 transfer-limit-text">
                                    <Col>{strings.MAXIMUM_TRANSFER_IN_LIMIT[language]}{transferLimit}</Col>
                                </Row>
                                <Row>
                                    <Form id="transferInForm" autoComplete="off">
                                        <FormGroup id="transferInBox" className="input-group">
                                            <Input
                                                id="transferInAmount"
                                                name="transferInAmount"
                                                className="input"
                                                placeholder=" "
                                                type="text"
                                                maxLength={maxInput}
                                                value={amountText}
                                                onKeyPress={(e) => {
                                                    if (!/[0-9]/.test(e.key))
                                                        e.preventDefault();
                                                    else
                                                        formatCurrencyInput(e.key)
                                                }}
                                                onKeyDown={(e) => { handleKeyDown(e.key) }}
                                                disabled={availableAmount == "0.00"}
                                            />
                                            <Label for="id" className="placeholder-mid">
                                                {strings.ENTER_TRANSFER_IN_AMOUNT[language]}
                                            </Label>
                                        </FormGroup>
                                    </Form>
                                </Row>
                                <Row className="error-message">
                                    <Col>{errorMsg != "" ? <><FontAwesomeIcon icon={faCircleExclamation} color="red" /></> : ""} {errorMsg}</Col>
                                </Row>
                                <Row className="mt-2">
                                    <Button id="buttonSlide" disabled={amountText.length == 0 || isOverLimit} className={transferInButtonTriggered ? "buttonSlide buttonSlideTriggered" : "buttonSlide"} onMouseDown={(e) => { handleButtonMoved(e) }} onMouseUp={(e) => { handleMouseUp(e) }} onMouseLeave={(e) => { handleButtonStopped() }}>{strings.SWIPE_TO_TRANSFER_IN[language]}<span id="promptButton" className="promptArrow"><FontAwesomeIcon icon={faArrowRight} /></span><span className="arrowSwipeRight" hidden={amountText.length == 0 || isOverLimit}><FontAwesomeIcon icon={faAnglesRight} /></span></Button>
                                </Row>
                            </>)}
                    </ModalBody>
                </>)}
        </Modal>
    );
}

export default TransferInModal;