import React, { createContext, useEffect, useState } from "react";
import { Language } from "../enums/Language";

export const LanguageModeContext = createContext<any>({});

export const LanguageContext = (props: any) => {
    const [language, setLanguage] = useState(Language.EN);

    useEffect(() => {
        if (sessionStorage.getItem('lang'))
            setLanguage(Number.parseInt((sessionStorage.getItem('lang') || '0')))
    }, []);

    return (
        <LanguageModeContext.Provider
            value={{ language, setLanguage }} >
            {props.children}
        </LanguageModeContext.Provider>
    );
};