import { IString } from "../assets/IString"

export const strings: IString = {
    "LANG": [
        "EN",
        "简体",
        "繁体"
    ],
    "DEMO": [
        "Demo",
        "演示",
        "演示"
    ],
    "HOME": [
        "Home",
        "主页",
        "主頁"
    ],
    "HISTORY": [
        "History",
        "赌注记录",
        "賭注記錄"
    ],
    "PLAYER_PROFILE": [
        "Player Profile",
        "玩家资料",
        "玩家資料"
    ],
    "LOGIN": [
        "Login",
        "登入",
        "登入"
    ],
    "LOGIN_AS_MEMBER": [
        "Login as Member",
        "会员登入",
        "會員登入"
    ],
    "LOGIN_TO_PLAY": [
        "Login to Play",
        "登入玩",
        "登入玩"
    ],
    "LOGGING_IN": [
        "Logging in...",
        "登入中...",
        "登入中..."
    ],
    "LOGGING_OUT": [
        "Logging out...",
        "登出中...",
        "登出中..."
    ],
    "PLEASE_ENTER_YOUR_ID": [
        "Please enter your id",
        "请输入您的账号",
        "請輸入您的帳號"
    ],
    "PLEASE_ENTER_YOUR_PASSWORD": [
        "Please enter your password",
        "请输入您的密码",
        "請輸入您的密碼"
    ],
    "LOGOUT": [
        "Logout",
        "登出",
        "登出"
    ],
    "EC": [
        "EC",
        "现金",
        "現金"
    ],
    "NN": [
        "NN",
        "泥码",
        "泥碼"
    ],
    "ID": [
        "ID",
        "账号",
        "帳號"
    ],
    "PASSWORD": [
        "Password",
        "密码",
        "密碼"
    ],
    "OR": [
        "or",
        "或",
        "或"
    ],
    "FUN_PLAY": [
        "Fun Play",
        "模拟下注",
        "模擬下注"
    ],
    "SLOT_MACHINE": [
        "Slot Machine",
        "老虎机",
        "老虎機"
    ],
    "USERNAME": [
        "Username",
        "用户名",
        "用戶名"
    ],
    "FULLNAME": [
        "Fullname",
        "全名",
        "全名"
    ],
    "CARD_TYPE": [
        "Card Type",
        "卡类",
        "卡類"
    ],
    "LAST_LOGIN_DATE_TIME": [
        "Last Login Date Time",
        "最后登入日期时间",
        "最後登入時間日期"
    ],
    "BALANCE": [
        "Balance",
        "余额",
        "餘額"
    ],
    "TRANSFER_IN": [
        "Transfer In",
        "充值",
        "充值"
    ],
    "MAXIMUM_TRANSFER_IN_LIMIT": [
        "Maximum Transfer In Amount is ",
        "最大转值金额为",
        "最大轉值金額為"
    ],
    "AMOUNT_MUST_BE_LESS_THAN": [
        "Amount must be less than ",
        "金额必须小于",
        "金額必須小於"
    ],
    "LOADING_TRANSACTION": [
        "Loading Transaction...",
        "转值中...",
        "轉值中..."
    ],
    "LOADING": [
        "Loading...",
        "加载中...",
        "加載中..."
    ],
    "TRANSFER_IN_SUCCESSFUL": [
        "Transfer In successful!",
        "转值成功！",
        "轉值成功！"
    ],
    "AVAILABLE_BALANCE": [
        "Available balance for transfer in",
        "可转值的余额金额",
        "可轉值的餘額金額"
    ],
    "CURRENT_BALANCE": [
        "Current Balance is",
        "当前余额为",
        "當前餘額為"
    ],
    "ENTER_TRANSFER_IN_AMOUNT": [
        "Enter Transfer In Amount",
        "输入转值金额",
        "輸入轉值金額"
    ],
    "SWIPE_TO_TRANSFER_IN": [
        "Swipe to Confirm Transfer In",
        "滑动确认转值",
        "滑動確認轉值"
    ],
    "ACTIVE_GAMES": [
        "Active Games",
        "活跃游戏",
        "活躍遊戲"
    ],
    "ACTIVE_GAME": [
        "Active Game",
        "活跃游戏",
        "活躍遊戲"
    ],
    "COMPLETED_GAMES": [
        "Completed Games",
        "已完成遊戲",
        "已完成遊戲"
    ],
    "COMPLETED_GAME": [
        "Completed Game",
        "已完成遊戲",
        "已完成遊戲"
    ],
    "DATE_TIME": [
        "Date & Time",
        "日期时间",
        "日期时间"
    ],
    "TOTAL_BET": [
        "Total Bet",
        "总投注",
        "總投注"
    ],
    "PAYOUT": [
        "Payout",
        "赔率",
        "賠率"
    ],
    "BONUS": [
        "Bonus",
        "红利",
        "紅利"
    ],
    "REPLAY": [
        "Replay",
        "重播",
        "重播"
    ],
    "DETAILS": [
        "Details",
        "详情",
        "詳情"
    ],
    "GAME_HISTORY_DETAILS": [
        "Game History Details",
        "赌注记录详情",
        "赌注记录詳情"
    ],
    "GAME_INFO": [
        "GAME INFO",
        "游戏资讯",
        "遊戲資訊"
    ],
    "WALLET_TYPE": [
        "WALLET TYPE",
        "钱包类型",
        "錢包類型"
    ],
    "WAGER_INFO": [
        "WAGER INFO",
        "投注资讯",
        "投注資訊"
    ],
    "MINIGAME_AND_GAMBLE": [
        "MINIGAME AND GAMBLE",
        "小游戏和搏一搏",
        "小遊戲和搏一搏"
    ],
    "CREDIT_RATE": [
        "Credit Rate",
        "汇率",
        "匯率"
    ],
    "CURRENCY": [
        "Currency",
        "货币",
        "貨幣"
    ],
    "GAMING_AREA": [
        "Gaming Area",
        "游戏区",
        "遊戲區"
    ],
    "GAME_STATUS": [
        "Game Status",
        "游戏状态",
        "遊戲狀態"
    ],
    "WALLET": [
        "Wallet",
        "钱包",
        "錢包"
    ],
    "BETS_PER_LINE": [
        "Bets Per Line (Denom)",
        "每线数投注 (面值)",
        "每線數投注 (面值)"
    ],
    "LINES": [
        "Lines",
        "线数",
        "線數"
    ],
    "BEFORE": [
        "Before",
        "前",
        "前"
    ],
    "WAGER": [
        "Wager",
        "赌注",
        "賭注"
    ],
    "AFTER": [
        "After",
        "后",
        "後"
    ],
    "HANDPAY_AMOUNT": [
        "Handpay Amount",
        "手付金额",
        "手付金額"
    ],
    "NUMBER_OF_GAMBLE": [
        "Number of Gamble",
        "搏一搏數",
        "搏一搏數"
    ],
    "GAMBLE_TOTAL_BET": [
        "Gamble Total Bet",
        "搏一搏总投注",
        "搏一搏總投注"
    ],
    "GAMBLE_TOTAL_PAYOUT": [
        "Gamble Total Payout",
        "搏一搏总赔率",
        "搏一搏總賠率"
    ],
    "GAMBLE_STATUS": [
        "Gamble Status",
        "搏一搏状态",
        "搏一搏狀態"
    ],
    "MINIGAME_TYPE": [
        "Minigame Type",
        "小遊戲类型",
        "小遊戲类型"
    ],
    "MINIGAME_TOTAL_PAYOUT": [
        "Minigame Total Payout",
        "小遊戲总赔率",
        "小遊戲總赔率"
    ],
    "JACKPOT_POOL_STRIKE": [
        "Jackpot Pool Strike",
        "积宝奖励击",
        "積寶獎勵擊"
    ],
    "JACKPOT_STRIKE_AMOUNT": [
        "Jackpot Strike Amount",
        "积宝击金额",
        "積寶擊金額"
    ],
    "JACKPOT_STRIKE_STATUS": [
        "Jackpot Strike Status",
        "积宝击状态",
        "積寶擊狀態"
    ],
    "NO_DATA_FOUND": [
        "No Data Found",
        "没有记录",
        "沒有記錄"
    ],
    "FIRST_PAGE": [
        "First",
        "首页",
        "首頁"
    ],
    "LAST_PAGE": [
        "Last",
        "末页",
        "末頁"
    ],
    "JACKPOT": [
        "JACKPOT",
        "积宝",
        "積寶"
    ],
    "HIGHEST_JACKPOT_PRIZE": [
        "Highest Jackpot Prize",
        "最高积宝奖励",
        "最高積寶獎勵"
    ],
    "SLOT_GAME": [
        "SLOT GAME",
        "老虎机",
        "老虎機"
    ],
    "BONUS_YES": [
        "YES",
        "有",
        "有"
    ],
    "BONUS_NO": [
        "NO",
        "无",
        "无"
    ],
    "TNC": [
        "Terms and Conditions",
        "条款及细则",
        "條款及細則"
    ],
    "ACCEPT": [
        "Accept",
        "接受",
        "接受"
    ],
    "DECLINE": [
        "Decline",
        "拒绝",
        "拒絕"
    ],
    "SESSION_TIMEOUT": [
        "Session Timeout",
        "会话超时",
        "會話超時"
    ],
    "SESSION_EXPIRE_IN": [
        "Your session will expire in 00:{x}. Click \"OK\" to remain logged in or click \"Cancel\" to Log out.",
        "您的会话将在 00:{x} 后过期。点选「确定」保持登入状态，或按「取消」登出。",
        "您的會話將在 00:{x} 後過期。點選「確定」保持登入狀態，或按「取消」登出。"
    ],
    "OK": [
        "OK",
        "确定",
        "確定"
    ],
    "CANCEL": [
        "Cancel",
        "取消",
        "取消"
    ],
    "LOG_OUT_DUE_TO_INACTIVITY": [
        "Your account has been logged out due to inactivity.",
        "您的账号因不活动而已登出。",
        "您的帳號因不活動而已登出。"
    ],
    "ACCOUNTING_INVALID_SESSION_LOGGED_OUT": [
        "Your account has been logged out due to invalid session",
        "您的账号因无效会话而已登出",
        "您的帳號因無效會話而已登出"
    ],
    "STATUS": [
        "Status",
        "状态",
        "狀態"
    ],
    "STATUS_NONE": [
        "Normal",
        "正常",
        "正常"
    ],
    "STATUS_PAYOUT_FAILED": [
        "Payout Failed",
        "付款失败",
        "付款失敗"
    ],
    "STATUS_PAYOUT_PENDING": [
        "Payout Pending",
        "付款待定",
        "付款待定"
    ],
    "STATUS_JACKPOT_CONTRIBUTION_FAILED": [
        "Jackpot Contribution Failed",
        "积宝贡献失败",
        "積寳貢獻失敗"
    ],
    "STATUS_JACKPOT_STRIKE_FAILED": [
        "Jackpot Claim Failed",
        "领取积宝失败",
        "領取積寳失敗"
    ],
    "STATUS_REFUND_FAILED": [
        "Refund Failed",
        "退款失败",
        "退款失敗"
    ],
    "GAMBLE_PAYOUT_FAILED": [
        "Gamble Payout Failed",
        "搏一搏付款失败",
        "搏一搏付款失敗"
    ]
}
