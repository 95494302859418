import { GameLobbyDTO } from '../../dto/gameDTO';
import * as LobbyActions from './LobbyActions';
import { LobbyActionType } from './LobbyConstants';
import { ILobbyState, InitLobbyState } from './LobbyStates';

export const lobbyReducer = (state = InitLobbyState, action: LobbyActions.ActionTypes): ILobbyState => {
    switch (action.type) {
        case LobbyActionType.GET_LOBBY_GAMES_REQUEST:
            return { ...state, error: "" }
        case LobbyActionType.GET_LOBBY_GAMES_SUCCESS:
            return { ...state, lobbyGames: action.payload as GameLobbyDTO, error: "" }
        case LobbyActionType.GET_LOBBY_GAMES_FAILED:
            return { ...state, error: action.payload }
        default:
            return state
    }
}
