
import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
    FormText,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../states/Player/PlayerActions'
import Loader from '../Loader'
import Message from '../Message'
import { IStore } from '../../states/store/IStore';
import { PlayerActionType } from '../../states/Player/PlayerConstants';
import { LanguageModeContext } from '../../context/LanguageContext';
import { strings } from '../../assets/strings'
import { PlayerWalletActionType } from '../../states/PlayerWallet/PlayerWalletConstants';
import { FunplayModeContext } from '../../context/FunplayContext';
import { GameMode } from '../../enums/GameMode';
import { getRememberMeConfig } from '../../states/PortalConfig/PortalConfigActions';

const LoginModal = (props: { openModal: boolean, onChange: any, title: string }) => {
    const dispatch = useDispatch()
    const [modal, setModal] = useState(props.openModal);
    const [idText, setIdText] = useState("");
    const [passwordText, setPasswordText] = useState("");
    const [idError, setIdError] = useState(" ");
    const [passwordError, setPasswordError] = useState(" ");
    const userState = useSelector((state: IStore) => state.userState)
    const { loading, error, isLogin } = userState

    const [rememberMe, setRememberMe] = useState(false);
    const [hasRememberMeBox, setHasRememberMeBox] = useState(false);
    const [rememberMeTime, setRememberMeTime] = useState(-1);
    const rememberMeState = useSelector((state: IStore) => state.rememberMeState)
    const { rememberMeInfo } = rememberMeState

    const { language } = useContext(LanguageModeContext)
    const { setFunplayMode } = useContext(FunplayModeContext)

    const toggle = () => {
        if (!loading) {
            setModal(!modal);
            props.onChange(!modal);
            if (!loading && !isLogin) {
                dispatch({ type: PlayerActionType.USER_RESET })
                dispatch({ type: PlayerWalletActionType.WALLET_RESET })
            }
            setIdText("")
            setPasswordText("")
        }
    };

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            submit();
        }
    }

    const submit = () => {
        setIdError(" ")
        setPasswordError(" ")

        if (idText === "") {
            var textbox = document.getElementById("id");
            if (textbox) {
                textbox.focus()
                setIdError(strings.PLEASE_ENTER_YOUR_ID[language])
            }
        }
        else if (passwordText === "") {
            var textbox = document.getElementById("password");
            if (textbox) {
                textbox.focus()
                setPasswordError(strings.PLEASE_ENTER_YOUR_PASSWORD[language])
            }
        }

        if (idText !== "" && passwordText !== "") {
            dispatch(login(idText + "," + passwordText, language))

            if (rememberMe) {
                var today = new Date();
                today.setMinutes(today.getMinutes() + rememberMeTime);
                var newTime = today.toUTCString();
                document.cookie = "userID=" + idText + "; expires=" + newTime;
            }
            else
                expireCookie();
        }
    }

    const funplay = () => {
        setFunplayMode(true)
        dispatch(login("", language, GameMode.FUNPLAY))
    }

    useEffect(() => {
        setModal(props.openModal)

        if (!props.openModal) {
            setIdError(" ")
            setPasswordError(" ")
        } else 
            checkRememberMe();

    }, [props.openModal])

    const checkRememberMe = () => {
        const cookieValue = document.cookie
            .split("; ")
            .find((row) => row.startsWith("userID="))
            ?.split("=")[1];

        if (cookieValue !== undefined && hasRememberMeBox && cookieValue != "") {
            setIdText(cookieValue);
            setRememberMe(true);
            const rememberMeChkBox: HTMLInputElement = document.getElementById("rememberMe") as HTMLInputElement;

            if (rememberMeChkBox)
                rememberMeChkBox.checked = true;
        }
        else {
            setRememberMe(false);
            expireCookie();
        }
    }

    const expireCookie = () => {
        const cookieValue = document.cookie
            .split("; ")
            .find((row) => row.startsWith("userID="))
            ?.split("=")[1];

        if (cookieValue !== undefined) {
            // If cookies was found, make cookie expired
            var todayNow = new Date();
            document.cookie = "userID=" + "" + "; expires=" + todayNow;
        }
    }

    useEffect(() => {
        if (!loading && isLogin && modal) {
            setModal(!modal);
            props.onChange(!modal);
        }
    }, [isLogin])

    useEffect(() => {
        if (rememberMeInfo && rememberMeInfo.enb) {
            setHasRememberMeBox(rememberMeInfo.enb);
            setRememberMeTime(rememberMeInfo.exp);
        }
    }, [rememberMeInfo])

    useEffect(() => {
        dispatch(getRememberMeConfig())
    }, [])

    return (
        <Modal id="loginModal" isOpen={modal}>
            <ModalHeader id="loginModalHeader" toggle={!loading ? toggle : undefined}>
                <div>
                    <span id="header-logo" className="header-logo pt-3 pb-3" />
                </div>
                <div>
                    {props.title}
                </div>
            </ModalHeader>
            <ModalBody id="loginModalBody">
                {loading ?
                    (
                        <div className="loading-container">
                            <div>
                                <Loader />
                            </div>
                            <div>
                                {strings.LOGGING_IN[language]}
                            </div>
                        </div>
                    )
                    : (<Form autoComplete="off">
                        {error ? (<Message variant='danger'>{error}</Message>) : <></>}
                        <FormGroup className="input-group">
                            <Input
                                id="id"
                                name="id"
                                className="input"
                                placeholder=" "
                                type="text"
                                onChange={(e) => setIdText(e.target.value.trim())}
                                value={idText}
                                onKeyPress={handleKeyPress}
                            />
                            <Label for="id" className="placeholder">
                                {strings.ID[language]}
                            </Label>
                        </FormGroup>
                        <FormText className="error-message">{idError}</FormText>
                        <FormGroup className="form-check" hidden={!hasRememberMeBox}>
                            <Input
                                id="rememberMe"
                                name="rememberMe"
                                className="form-check-input"
                                placeholder=" "
                                type="checkbox"
                                value={rememberMe.toString()}
                                onChange={(e) => setRememberMe(e.target.checked)}
                                checked={rememberMe}
                            />
                            <Label for="rememberMe" className="form-check-label" >
                                {"Remember Me"}
                            </Label>
                        </FormGroup>
                        <FormGroup className="input-group">
                            <Input
                                id="password"
                                name="password"
                                className="input"
                                placeholder=" "
                                type="password"
                                onChange={(e) => setPasswordText(e.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                            <Label for="password" className="placeholder">
                                {strings.PASSWORD[language]}
                            </Label>
                        </FormGroup>
                        <FormText className="error-message">{passwordError}</FormText>
                    </Form>
                    )}
            </ModalBody>
            {!loading ? (
                <ModalFooter id="loginModalFooter" className="modalFooter">
                    <Button onClick={submit} type="submit" color="success">
                        {strings.LOGIN[language]}
                    </Button>
                    <div>
                        <p className="button-separator">{strings.OR[language]}</p>
                    </div>
                    <Button onClick={funplay} type="submit" color="primary">
                        {strings.FUN_PLAY[language]}
                    </Button>
                </ModalFooter>) :
                (<div></div>)}
        </Modal>
    );
}

export default LoginModal;