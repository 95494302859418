import { Language } from "../enums/Language";
import { errorMessages } from '../assets/errorMessages'
import { ErrorCode } from '../enums/ErrorCode'

export function GetErrorMessage(error: number, lang: Language): string {
    console.log("Error: " + ErrorCode[error])
    let message = errorMessages[ErrorCode[error]];

    if (message)
        return message[lang]
    else
        return errorMessages.ERROR[lang]
}