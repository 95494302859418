import axios from 'axios';
import { PlayerWalletActionType } from './PlayerWalletConstants';
import { PlayerActionType } from '../Player/PlayerConstants';
import { TransferInDTO } from '../../dto/transferInDTO';
import { GetErrorMessage } from '../../utils/ErrorMessageHelper';
import { Language } from '../../enums/Language';
import { FsgDynamiqAccounting } from '../../dto/accountingRequestDTO';
import { updateToken } from '../../App';
import { GameMode } from '../../enums/GameMode';
import { PlayerBalanceDTO } from '../../dto/playerBalanceDTO';

// Custom Type Declaration
type UpdateLocalBalance_Request = { type: PlayerWalletActionType.UPDATE_LOCAL_BALANCE_REQUEST };
type UpdateLocalBalance_Success = { type: PlayerWalletActionType.UPDATE_LOCAL_BALANCE_SUCCESS, payload: {} };
type UpdateLocalBalance_Failed = { type: PlayerWalletActionType.UPDATE_LOCAL_BALANCE_FAILED, payload: {} };
type GetCMSBalance_Request = { type: PlayerWalletActionType.GET_CMS_BALANCE_REQUEST };
type GetCMSBalance_Success = { type: PlayerWalletActionType.GET_CMS_BALANCE_SUCCESS, payload: {} };
type GetCMSBalance_Failed = { type: PlayerWalletActionType.GET_CMS_BALANCE_FAILED, payload: {} };
type GetBalance_Request = { type: PlayerWalletActionType.GET_BALANCE_REQUEST };
type GetBalance_Success = { type: PlayerWalletActionType.GET_BALANCE_SUCCESS, payload: {} };
type GetBalance_Failed = { type: PlayerWalletActionType.GET_BALANCE_FAILED, payload: {} };
type GetTransferInLimit_Request = { type: PlayerWalletActionType.GET_TRANSFER_IN_LIMIT_REQUEST };
type GetTransferInLimit_Success = { type: PlayerWalletActionType.GET_TRANSFER_IN_LIMIT_SUCCESS, payload: {} };
type GetTransferInLimit_Failed = { type: PlayerWalletActionType.GET_TRANSFER_IN_LIMIT_FAILED, payload: {} };
type TransferIn_Request = { type: PlayerWalletActionType.TRANSFER_IN_REQUEST };
type TransferIn_Success = { type: PlayerWalletActionType.TRANSFER_IN_SUCCESS, payload: {} };
type TransferIn_Failed = { type: PlayerWalletActionType.TRANSFER_IN_FAILED, payload: {} };
type LoginWalletVIP_Success = { type: PlayerWalletActionType.LOGIN_WALLET_VIP_SUCCESS, payload: {} };
type LoginWalletCommon_Success = { type: PlayerWalletActionType.LOGIN_WALLET_COMMON_SUCCESS, payload: {} };
type TransferInReset = { type: PlayerWalletActionType.TRANSFER_IN_RESET, payload: {} };
type WalletReset = { type: PlayerWalletActionType.WALLET_RESET, payload: {} };

export type ActionTypes =
    UpdateLocalBalance_Request |
    UpdateLocalBalance_Success |
    UpdateLocalBalance_Failed |
    GetCMSBalance_Request |
    GetCMSBalance_Success |
    GetCMSBalance_Failed |
    GetBalance_Request |
    GetBalance_Success |
    GetBalance_Failed |
    GetTransferInLimit_Request |
    GetTransferInLimit_Success |
    GetTransferInLimit_Failed |
    TransferIn_Request |
    TransferIn_Success |
    TransferIn_Failed |
    LoginWalletVIP_Success |
    LoginWalletCommon_Success |
    TransferInReset |
    WalletReset;

export const updateLocalBalance = (rawData: any) => async (dispatch: any, getState: any) => {
    if (!localStorage.getItem('tkn')) {
        dispatch({
            type: PlayerActionType.LOGOUT_SUCCESS,
        });
        dispatch({
            type: PlayerWalletActionType.WALLET_RESET,
        })
    } else {
        try {

            dispatch({
                type: PlayerWalletActionType.UPDATE_LOCAL_BALANCE_REQUEST,
            });

            if (rawData) {
                var data = JSON.parse(JSON.stringify(rawData))
                 if (data.irr)
                    updateToken(data.tkn)

                dispatch({
                    type: PlayerWalletActionType.UPDATE_LOCAL_BALANCE_SUCCESS,
                    payload: JSON.parse(data.res)
                });
            }

        } catch (error) {
            dispatch({
                type: PlayerWalletActionType.UPDATE_LOCAL_BALANCE_FAILED,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    }
}

export const getCMSBalance = (gameMode: GameMode = GameMode.NORMAL) => async (dispatch: any, getState: any) => {
    if (!localStorage.getItem('tkn')) {
        dispatch({
            type: PlayerActionType.LOGOUT_SUCCESS,
        });
        dispatch({
            type: PlayerWalletActionType.WALLET_RESET,
        })
    } else {
        try {
            dispatch({
                type: PlayerWalletActionType.GET_CMS_BALANCE_REQUEST,
            });

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Token': localStorage.getItem('tkn') || "",
                },
            }
            if (gameMode == GameMode.DEMO || gameMode == GameMode.FUNPLAY) {
                var cmsWallet: PlayerBalanceDTO = {
                    ec: 0,
                    nn: 0,
                    vipec: 0,
                    vipnn: 0
                }

                dispatch({
                    type: PlayerWalletActionType.GET_CMS_BALANCE_SUCCESS,
                    payload: cmsWallet
                });
            } else {
                const { data } = await axios.post(
                    "Wallet/GetCMSBalance",
                    JSON.stringify(FsgDynamiqAccounting),
                    config
                )

                if (data && data.err == 0) {
                     if (data.irr)
                        updateToken(data.tkn)

                    dispatch({
                        type: PlayerWalletActionType.GET_CMS_BALANCE_SUCCESS,
                        payload: JSON.parse(data.res)
                    });
                } else {
                    dispatch({
                        type: PlayerWalletActionType.GET_CMS_BALANCE_FAILED
                    })
                }
            }
        }
        catch (error) {
            dispatch({
                type: PlayerWalletActionType.GET_CMS_BALANCE_FAILED,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            })
        }
    }
}

export const getBalance = (gameMode: GameMode = GameMode.NORMAL) => async (dispatch: any, getState: any) => {
    if (!localStorage.getItem('tkn')) {
        dispatch({
            type: PlayerActionType.LOGOUT_SUCCESS,
        });
        dispatch({
            type: PlayerWalletActionType.WALLET_RESET,
        })
    } else {
        try {
            dispatch({
                type: PlayerWalletActionType.GET_BALANCE_REQUEST,
            });

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Token': localStorage.getItem('tkn') || "",
                },
            }
            if (gameMode == GameMode.DEMO || gameMode == GameMode.FUNPLAY) {
                var {
                    userState: { userInfo },
                } = getState()

                dispatch({
                    type: PlayerWalletActionType.GET_BALANCE_SUCCESS,
                    payload: userInfo.blc
                });
            }
            else {
                const { data } = await axios.post(
                    "Wallet/GetBalance",
                    JSON.stringify(FsgDynamiqAccounting),
                    config
                )

                if (data && data.err == 0) {
                     if (data.irr)
                        updateToken(data.tkn)

                    dispatch({
                        type: PlayerWalletActionType.GET_BALANCE_SUCCESS,
                        payload: JSON.parse(data.res)
                    });
                } else {
                    dispatch({
                        type: PlayerWalletActionType.GET_BALANCE_FAILED
                    })
                }
            }
        }
        catch (error) {
            dispatch({
                type: PlayerWalletActionType.GET_BALANCE_FAILED,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            })
        }
    }
}

export const getTransferInLimit = (gameMode: GameMode = GameMode.NORMAL) => async (dispatch: any, getState: any) => {
    if (!localStorage.getItem('tkn')) {
        dispatch({
            type: PlayerActionType.LOGOUT_SUCCESS,
        });
        dispatch({
            type: PlayerWalletActionType.WALLET_RESET,
        })
    } else {
        try {
            dispatch({
                type: PlayerWalletActionType.GET_TRANSFER_IN_LIMIT_REQUEST,
            });

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Token': localStorage.getItem('tkn') || "",
                },
            }
            if (gameMode == GameMode.DEMO || gameMode == GameMode.FUNPLAY) {
                var transferInLimit: PlayerBalanceDTO = {
                    ec: 1000,
                    nn: 1000,
                    vipec: 1000,
                    vipnn: 1000
                }

                dispatch({
                    type: PlayerWalletActionType.GET_TRANSFER_IN_LIMIT_SUCCESS,
                    payload: transferInLimit
                });
            }
            else {
                const { data } = await axios.post(
                    "Wallet/GetTransferInLimit",
                    JSON.stringify(FsgDynamiqAccounting),
                    config
                )

                if (data && data.err == 0) {
                     if (data.irr)
                        updateToken(data.tkn)

                    dispatch({
                        type: PlayerWalletActionType.GET_TRANSFER_IN_LIMIT_SUCCESS,
                        payload: JSON.parse(data.res)
                    });
                }
            }
        }
        catch (error) {
            dispatch({
                type: PlayerWalletActionType.GET_TRANSFER_IN_LIMIT_FAILED,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            })
        }
    }
}

export const creditTransferIn = (amount: number, walletType: number, lang: Language) => async (dispatch: any, getState: any) => {
    if (!localStorage.getItem('tkn')) {
        dispatch({
            type: PlayerActionType.LOGOUT_SUCCESS,
        });
        dispatch({
            type: PlayerWalletActionType.WALLET_RESET,
        })
    } else {
        try {
            dispatch({
                type: PlayerWalletActionType.TRANSFER_IN_REQUEST,
            });

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Token': localStorage.getItem('tkn') || "",
                },
            }


            var transferAmount: TransferInDTO = {
                amt: amount,
                wltt: walletType,
                cms: 1
            }

            const { data } = await axios.post(
                "Wallet/TransferIn",
                transferAmount,
                config
            )

            if (data && data.err == 0) {
                if (data.irr)
                    updateToken(data.tkn)

                dispatch({
                    type: PlayerWalletActionType.TRANSFER_IN_SUCCESS,
                    payload: JSON.parse(data.res)
                });
            } else {
                dispatch({
                    type: PlayerWalletActionType.TRANSFER_IN_FAILED,
                    payload: GetErrorMessage(JSON.parse(data.err), lang)
                })
            }
        }
        catch (error) {
            dispatch({
                type: PlayerWalletActionType.TRANSFER_IN_FAILED,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            })
        }
    }
}