export enum Theme {
    LIGHT = 0,
    DARK = 1
}

export enum Orientation {
    LANDSCAPE = 0,
    PORTRAIT = 1
}

