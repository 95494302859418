import React, { useContext, useEffect, useState } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col,
    ModalFooter,
    Button,
} from 'reactstrap';
import { strings } from '../../assets/strings';
import { LanguageModeContext } from '../../context/LanguageContext';

const IdlePopUpModal = (props: { openModal: boolean, onChange: any, isLogout: any }) => {
    const { language } = useContext(LanguageModeContext)
    const [modal, setModal] = useState(props.openModal);
    const [timer, setTimer] = useState("10");
    const [idleTimer, setIdleTimer] = useState<NodeJS.Timeout | undefined>(undefined);
    const [isLogOut, setIsLogOut] = useState(false);

    const toggle = () => {
        clearTimer()
        setModal(false);
        props.onChange(false);
    };

    const logout = () => {
        toggle()
        setIsLogOut(false)
        props.isLogout(true)
    }

    const clearTimer = () => {
        if (idleTimer != undefined) {
            clearInterval(idleTimer)
            setIdleTimer(undefined)
        }
    }

    useEffect(() => {
        setModal(props.openModal)
    }, [props.openModal])

    useEffect(() => {
        if (isLogOut)
            logout()
    }, [isLogOut])

    useEffect(() => {
        if (modal) {
            let remainingTime = 10
            setTimer(remainingTime.toString())

            setIdleTimer(setInterval(() => {
                console.log("remain:" + remainingTime)
                if (remainingTime >= 0) {
                    if (remainingTime.toString().length < 2)
                        setTimer(remainingTime.toString().padStart(2, "0"))
                    else
                        setTimer(remainingTime.toString())
                    remainingTime--;
                }
                else {
                    setIsLogOut(true)
                }
            }, 1000))
        }
    }, [modal])

    return (
        <Modal id="idleModal" isOpen={modal}>
            <ModalHeader id="idleModalHeader">{strings.SESSION_TIMEOUT[language]}</ModalHeader>
            <ModalBody id="idleModalBody">
                <Row>
                    <Col>{strings.SESSION_EXPIRE_IN[language].replace("{x}", timer)}</Col>
                </Row>
            </ModalBody>
            <ModalFooter id="idleModalFooter" className="modalFooter">
                <Row className="w-100">
                    <Col><Button className="w-100" onClick={toggle} type="submit" color="primary">
                        {strings.OK[language]}
                    </Button></Col>
                    <Col><Button className="w-100" onClick={logout} type="submit" color="primary">
                        {strings.CANCEL[language]}
                    </Button></Col>
                </Row>
            </ModalFooter>
        </Modal>
    );
}
export default IdlePopUpModal;
