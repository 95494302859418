import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, Col, Row } from 'reactstrap';
import { DemoModeContext } from '../context/DemoContext';
import { FunplayModeContext } from '../context/FunplayContext';
import { IStore } from '../states/store/IStore';

const GameContainer = (props: { children?: React.ReactNode }) => {
    const userState = useSelector((state: IStore) => state.userState)
    const { isLogin } = userState
    const navigate = useNavigate();
    const isDemo = useContext(DemoModeContext)
    const { funplayMode } = useContext(FunplayModeContext)

    useEffect(() => {
        if (localStorage.getItem('tkn') == null) {
            navigate('/')
        }
    }, [isLogin])

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col>
                        <Row className="justify-content-center">
                            {props.children}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default GameContainer
