import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const FunplayModeContext = createContext<any>({});

export const FunplayContext = (props: any) => {
    const [funplayMode, setFunplayMode] = useState(false);

    useEffect(() => {
        if (sessionStorage.getItem('funplay') && sessionStorage.getItem('funplay') == 'true')
            setFunplayMode(true)
        else
            setFunplayMode(false)

    }, []);

    return (
        <FunplayModeContext.Provider
            value={{ funplayMode, setFunplayMode }}>
            {props.children}
        </FunplayModeContext.Provider>
    );
};