import { PlayerBalanceDTO } from '../../dto/playerBalanceDTO';
import * as PlayerWalletAction from './PlayerWalletActions';
import { PlayerWalletActionType } from './PlayerWalletConstants';
import { InitWalletState, IWalletState } from './PlayerWalletStates';

export const walletReducer = (state = InitWalletState, action: PlayerWalletAction.ActionTypes): IWalletState => {
    switch (action.type) {
        case PlayerWalletActionType.UPDATE_LOCAL_BALANCE_REQUEST:
            return { ...state, error: "" }
        case PlayerWalletActionType.UPDATE_LOCAL_BALANCE_SUCCESS:
            return { ...state, currentWallet: action.payload as PlayerBalanceDTO, error: "" }
        case PlayerWalletActionType.UPDATE_LOCAL_BALANCE_FAILED:
            return { ...state, error: action.payload }
        case PlayerWalletActionType.GET_CMS_BALANCE_REQUEST:
            return { ...state, loading: true, error: "" }
        case PlayerWalletActionType.GET_CMS_BALANCE_SUCCESS:
            return { ...state, loading: false, cmsWallet: action.payload as PlayerBalanceDTO, error: "" }
        case PlayerWalletActionType.GET_CMS_BALANCE_FAILED:
            return { ...state, loading: false, error: action.payload }
        case PlayerWalletActionType.GET_BALANCE_REQUEST:
            return { ...state, loading: true, error: "" }
        case PlayerWalletActionType.GET_BALANCE_SUCCESS:
            return { ...state, loading: false, currentWallet: action.payload as PlayerBalanceDTO, error: "" }
        case PlayerWalletActionType.GET_BALANCE_FAILED:
            return { ...state, loading: false, error: action.payload }
        case PlayerWalletActionType.GET_BALANCE_REQUEST:
            return { ...state, loading: true, error: "" }
        case PlayerWalletActionType.GET_TRANSFER_IN_LIMIT_SUCCESS:
            return { ...state, loading: false, transferInLimit: action.payload as PlayerBalanceDTO, error: "" }
        case PlayerWalletActionType.GET_TRANSFER_IN_LIMIT_FAILED:
            return { ...state, loading: false, error: action.payload }
        case PlayerWalletActionType.GET_TRANSFER_IN_LIMIT_REQUEST:
            return { ...state, loading: true, error: "" }
        case PlayerWalletActionType.TRANSFER_IN_SUCCESS:
            return { ...state, loading: false, currentWallet: action.payload as PlayerBalanceDTO, error: "", transferInSuccess: true }
        case PlayerWalletActionType.TRANSFER_IN_FAILED:
            return { ...state, loading: false, error: action.payload, transferInSuccess: false }
        case PlayerWalletActionType.LOGIN_WALLET_VIP_SUCCESS:
            return { ...state, loading: false, currentWallet: action.payload as PlayerBalanceDTO, error: "", isVIP: true }
        case PlayerWalletActionType.LOGIN_WALLET_COMMON_SUCCESS:
            return { ...state, loading: false, currentWallet: action.payload as PlayerBalanceDTO, error: "", isVIP: false }
        case PlayerWalletActionType.TRANSFER_IN_RESET:
            return {
                ...state, transferInSuccess: false
            }
        case PlayerWalletActionType.WALLET_RESET:
            return InitWalletState
        default:
            return state
    }
}
