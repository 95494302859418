import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { strings } from "../assets/strings";
import { LanguageModeContext } from "../context/LanguageContext";
import { ThemesModeContext } from "../context/ThemesContext";
import { HistoryGameServiceDTO, HistoryGameServiceListDTO } from "../dto/historyDTO";
import { Orientation } from "../enums/Style";
import { GetHistoryGameServiceList } from "../states/History/HistoryActions";
import { IStore } from "../states/store/IStore";
import Loader from "./Loader";
import Message from "./Message";

const HistoryGameList = (props: { onSelect: any, playerID: number }) => {
    const dispatch = useDispatch()

    const { orientation } = useContext(ThemesModeContext)
    const [isPortrait, setIsPortrait] = useState(false)

    const historyState = useSelector((state: IStore) => state.historyState)
    const { gameServiceList, error, loading } = historyState
    const [gameServices, setGameServices] = useState({} as HistoryGameServiceListDTO)
    const { language } = useContext(LanguageModeContext)

    const selectGameService = (id: number, gameName: string) => {
        props.onSelect(id, gameName)
    }

    useEffect(() => {
        dispatch(GetHistoryGameServiceList(props.playerID))
    }, [props.playerID])


    useEffect(() => {
        if (gameServiceList.ttc > 0) {
            setGameServices(gameServiceList)
        }
    }, [gameServiceList])

    useEffect(() => {
        setIsPortrait(orientation == Orientation.PORTRAIT)
    }, [orientation])

    return (
        <> {loading ? (
            <div>
                <Loader />
            </div>)
            : error ?
                (<Message variant='danger'>{error}</Message>)
                : (
                    <Col className="d-flex flex-column align-items-center fade-in-top">
                        {gameServices && gameServices.ttc > 0 ? <>
                            {gameServices.hgs.map((data: HistoryGameServiceDTO, index: number) => {
                                return (
                                    <Row key={data.gsid + "-" + index} onClick={() => { selectGameService(data.gsid, data.gnm) }} className={"history-game-service flex-nowrap " + (isPortrait ? "portrait" : "")}>
                                        <div className="history-game-service-details">
                                            <div>
                                                <img className="game-widget-img shimmer" src={data.icn != "" ? "data:image/png;base64," + data.icn : ""} />
                                            </div>
                                            <div className="history-game-service-text">
                                                <span>{data.gnm}</span>
                                            </div>
                                        </div>
                                        <div className="history-game-service-arrow w-auto"><FontAwesomeIcon icon={faAngleRight} /></div>
                                    </Row>
                                )
                            })}
                        </> : <Row className="history-no-data">{strings.NO_DATA_FOUND[language]}</Row>}

                    </Col>)}
        </>
    );
}
export default HistoryGameList;
