import React, { useContext, useEffect, useState } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col,
    ModalFooter,
    Button,
} from 'reactstrap';
import axios from 'axios';
import Loader from '../Loader';
import { strings } from '../../assets/strings';
import { LanguageModeContext } from '../../context/LanguageContext';

const AgreementModal = (props: { openModal: boolean, onChange: any, isLogout: any, onAgree: any }) => {
    const { language } = useContext(LanguageModeContext)
    const [modal, setModal] = useState(props.openModal);
    const [isEndOfContent, setIsEndOfContent] = useState(false);
    const [tncSource, setTncSource] = useState<any | undefined>(undefined);
    const [tncExist, setTncExist] = useState(false);
    const [tncData, setTncData] = useState<any | undefined>(undefined);

    const toggle = () => {
        setModal(false);
        props.onChange(false);
    };

    const logout = () => {
        toggle()
        props.isLogout(true)
        props.onAgree(false)
    }

    const accept = () => {
        toggle()
        props.isLogout(false)
        props.onAgree(true)
    }

    const tncNotFound = () => {
        toggle()
        props.isLogout(false)
        props.onAgree(false)
    }

    const checkEndOfContent = () => {
        var iframe = document.getElementById("agreementiframe") as HTMLFrameElement

        if (iframe.contentWindow && iframe.contentDocument) {
            if ((iframe.contentWindow.innerHeight + iframe.contentWindow.scrollY) >= iframe.contentDocument.body.offsetHeight * 0.85) {
                setIsEndOfContent(true)
                iframe.contentDocument.removeEventListener('scrollend', () => { checkEndOfContent() })
            }
        }
    }

    const handleIFrameLoad = () => {
        var iframe = document.getElementById("agreementiframe") as HTMLFrameElement

        if (iframe) {
            //add check scroll to end function
            //for same origin only
            if (iframe.contentDocument) {
                iframe.contentDocument.addEventListener('scrollend', () => { checkEndOfContent() })
            } else {//if not same origin
                setIsEndOfContent(true)
            }
        }
    }

    const getTnCSource = async () => {
        await axios.get("TnC").then(({ data }) => {

            if (data.item1) {
                setTncData(data)
                setTncExist(true)
            }
            else {
                tncNotFound()
            }
        })
    }

    if (tncSource == undefined) {
        var iframe = document.getElementById("agreementiframe") as HTMLFrameElement

        if (iframe) {
            //set iframe content
            if (tncData && tncData.item2) {
                if (iframe.contentDocument) {
                    iframe.contentDocument.body.innerHTML = tncData.item1
                    iframe.contentDocument.addEventListener('scrollend', () => { checkEndOfContent() })
                }
                setTncSource("")
            }
            else {
                setTncSource(tncData.item1)
            }
        }
    }

    useEffect(() => {
        setModal(props.openModal)
        if (props.openModal) {
            setIsEndOfContent(false)
            getTnCSource()
        }
    }, [props.openModal])

    return (
        <>
            {tncExist ? <Modal id="agreementModal" isOpen={modal}>
                <ModalHeader id="agreementModalHeader">{strings.TNC[language]}</ModalHeader>
                <ModalBody id="agreementModalBody">
                    <Col className="h-100">
                        <Row className="justify-content-center align-items-center h-100">
                            {tncSource == undefined ? <Loader /> : <></>}
                            <Col className={tncSource == undefined ? "hidden" : ""}>
                                <iframe id="agreementiframe" frameBorder={0} src={tncSource == "" ? undefined : tncSource} onLoad={handleIFrameLoad} />
                            </Col>
                        </Row>
                    </Col>
                </ModalBody>
                <ModalFooter id="agreementModalFooter" className="p-0">
                    <Row className="w-100 m-0">
                        <Col className="p-0"><Button id="acceptButton" className="w-100 p-3" onClick={accept} disabled={!isEndOfContent} type="submit" color="light">
                            {strings.ACCEPT[language]}
                        </Button></Col>
                        <Col className="p-0"><Button id="declineButton" className="w-100 p-3" onClick={logout} type="submit" color="light">
                            {strings.DECLINE[language]}
                        </Button></Col>
                    </Row>
                </ModalFooter>
            </Modal> :
                <></>}
        </>
    );
}
export default AgreementModal;
