import axios from 'axios';
import { GameHistoryRequestDTO, HistoryGameReplayRequestDTO, HistoryGameServiceRequestDTO } from '../../dto/historyDTO';
import { GameMode } from '../../enums/GameMode';
import { HistoryActionType } from './HistoryConstants';

type GetHistoryGameReplay_Request = { type: HistoryActionType.GET_HISTORY_GAME_REPLAY_REQUEST };
type GetHistoryGameReplay_Success = { type: HistoryActionType.GET_HISTORY_GAME_REPLAY_SUCCESS, payload: {} };
type GetHistoryGameReplay_Failed = { type: HistoryActionType.GET_HISTORY_GAME_REPLAY_FAILED, payload: {} };
type CLearHistoryGameReplay = { type: HistoryActionType.CLEAR_HISTORY_GAME_REPLAY, payload: {} };
type GetHistoryGameService_Request = { type: HistoryActionType.GET_HISTORY_GAME_SERVICE_REQUEST };
type GetHistoryGameService_Success = { type: HistoryActionType.GET_HISTORY_GAME_SERVICE_SUCCESS, payload: {} };
type GetHistoryGameService_Failed = { type: HistoryActionType.GET_HISTORY_GAME_SERVICE_FAILED, payload: {} };
type GetGameHistory_Request = { type: HistoryActionType.GET_GAME_HISTORY_REQUEST };
type GetGameHistory_Success = { type: HistoryActionType.GET_GAME_HISTORY_SUCCESS, payload: {} };
type GetGameHistory_Failed = { type: HistoryActionType.GET_GAME_HISTORY_FAILED, payload: {} };
type GetActiveGameHistory_Request = { type: HistoryActionType.GET_ACTIVE_GAME_HISTORY_REQUEST };
type GetActiveGameHistory_Success = { type: HistoryActionType.GET_ACTIVE_GAME_HISTORY_SUCCESS, payload: {} };
type GetActiveGameHistory_Failed = { type: HistoryActionType.GET_ACTIVE_GAME_HISTORY_FAILED, payload: {} };
type HistoryReset = { type: HistoryActionType.HISTORY_RESET, payload: {} };

export type ActionTypes =
    GetHistoryGameReplay_Request |
    GetHistoryGameReplay_Success |
    GetHistoryGameReplay_Failed |
    CLearHistoryGameReplay |
    GetHistoryGameService_Request |
    GetHistoryGameService_Success |
    GetHistoryGameService_Failed |
    GetGameHistory_Request |
    GetGameHistory_Success |
    GetGameHistory_Failed |
    GetActiveGameHistory_Request |
    GetActiveGameHistory_Success |
    GetActiveGameHistory_Failed |
    HistoryReset;

export const GetHistoryGameReplay = (historyId: number, gameServiceId: number, cms: number, isActiveGame: boolean) => async (dispatch: any, getState: any) => {
    try {

        dispatch({
            type: HistoryActionType.GET_HISTORY_GAME_REPLAY_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('tkn') || "",
            },
        }

        var request: HistoryGameReplayRequestDTO = {
            iag: isActiveGame,
            hid: historyId,
            gsid: gameServiceId,
            cms: cms
        }

        const { data } = await axios.post(
            "History/GetHistoryGameReplay",
            JSON.stringify(request),
            config
        )

        dispatch({
            type: HistoryActionType.GET_HISTORY_GAME_REPLAY_SUCCESS,
            payload: JSON.parse(data.res)
        });

    } catch (error) {
        dispatch({
            type: HistoryActionType.GET_HISTORY_GAME_REPLAY_FAILED,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const GetHistoryGameServiceList = (playerID: number) => async (dispatch: any, getState: any) => {
    try {

        dispatch({
            type: HistoryActionType.GET_HISTORY_GAME_SERVICE_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('tkn') || "",
            },
        }

        var request: HistoryGameServiceRequestDTO = {
            pid: playerID,
            cms: 1
        }
            const { data } = await axios.post(
                "History/GetHistoryGameServiceList",
                JSON.stringify(request),
                config
            )

            dispatch({
                type: HistoryActionType.GET_HISTORY_GAME_SERVICE_SUCCESS,
                payload: JSON.parse(data.res)
            });
       
    } catch (error) {
        dispatch({
            type: HistoryActionType.GET_HISTORY_GAME_SERVICE_FAILED,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const GetGameHistory = (page: number, dataCount: number, gameServiceId: number, playerID: number, username: string, gameMode: GameMode = GameMode.NORMAL) => async (dispatch: any, getState: any) => {
    try {

        dispatch({
            type: HistoryActionType.GET_GAME_HISTORY_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('tkn') || "",
            },
        }

            var request: GameHistoryRequestDTO = {
                pg: page,
                dct: dataCount,
                gsid: gameServiceId,
                pid: playerID
            }

            const { data } = await axios.post(
                "History/GetGameHistory",
                JSON.stringify(request),
                config
            )

            dispatch({
                type: HistoryActionType.GET_GAME_HISTORY_SUCCESS,
                payload: JSON.parse(data.res)
            });

    } catch (error) {
        dispatch({
            type: HistoryActionType.GET_GAME_HISTORY_FAILED,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const GetActiveGameHistory = (page: number, dataCount: number, gameServiceId: number, playerID: number, username: string) => async (dispatch: any, getState: any) => {
    try {

        dispatch({
            type: HistoryActionType.GET_ACTIVE_GAME_HISTORY_SUCCESS,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('tkn') || "",
            },
        }
            var request: GameHistoryRequestDTO = {
                pg: page,
                dct: dataCount,
                gsid: gameServiceId,
                pid: playerID
            }

            const { data } = await axios.post(
                "History/GetActiveGameHistory",
                JSON.stringify(request),
                config
            )

            dispatch({
                type: HistoryActionType.GET_ACTIVE_GAME_HISTORY_SUCCESS,
                payload: JSON.parse(data.res)
            });
      

    } catch (error) {
        dispatch({
            type: HistoryActionType.GET_ACTIVE_GAME_HISTORY_FAILED,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}