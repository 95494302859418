import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare as regularSquare } from '@fortawesome/free-regular-svg-icons';
import React, { useContext, useRef, useEffect, useState } from 'react';
import { Button } from "reactstrap";
import Loader from '../components/Loader';
import { postToIframe } from '../App';
import { LanguageModeContext } from '../context/LanguageContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IStore } from '../states/store/IStore';
import { InitGameDTO } from '../dto/gameDTO';

interface IGameFrame {
    gameLink: string,
    showOptions: boolean,
    initGame: InitGameDTO | undefined,
    replayGameInfo?: string,
    location?: any,
    frameHeight?: string,
    disableAnimation?: boolean
}


const GameFrame = (props: IGameFrame) => {
    const navigate = useNavigate();
    const location = useLocation()

    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [isTheatre, setIsTheatre] = useState(false);
    const [gameLink, setGameLink] = useState("");
    const [isFullScreen, setFullScreen] = useState(false);
    const [isGameContainer, setIsGameContainer] = useState(false);
    const currentWalletJson = useRef({});
    const jackpotDataJson = useRef({});
    const initGameJson = useRef({});
    const [initListener, setInitListener] = useState(false);
    const { language } = useContext(LanguageModeContext);
    const walletState = useSelector((state: IStore) => state.walletState)
    const { currentWallet } = walletState
    const jackpotState = useSelector((state: IStore) => state.jackpotState)
    const { jackpotData } = jackpotState

    const handleIFrameLoad = () => {
        setIframeLoaded(true);
        postToIframe({
            type: "gameLoading",
            origin: window.origin,
            lan: language
        });
    }

    const handleFullscreen = () => {
        var iframe = document.getElementById("gameFrame");
        if (iframe) {
            setFullScreen(true);
            iframe.requestFullscreen();
            postToIframe({
                type: "requestFullScreen",
            });
        }
    }

    const exitFullScreenHandler = () => {
        if (!document.fullscreenElement && isFullScreen) {
            setFullScreen(false);
            postToIframe({
                type: "exitFullScreen",
            });
        }
    }

    // Add ExitFullScreenHandler
    document.onfullscreenchange = exitFullScreenHandler;



    const handleTheatreMode = () => {
        setIsTheatre(!isTheatre)
    }

    const initGame = () => {
        if (initGameJson) {
            postToIframe({
                type: "initGame",
                res: JSON.stringify(initGameJson.current)
            });
        }
        console.log(JSON.stringify(initGameJson.current))
        postToIframe({
            type: "updateBalance",
            res: JSON.stringify(currentWalletJson.current)
        });
        postToIframe({
            type: "updateJackpot",
            res: JSON.stringify(jackpotDataJson.current)
        });
    }

    function gameFrameMessageEvents(event: MessageEvent) {
        switch (event.data["type"]) {
            case "exitFullScreen":
                document.exitFullscreen();
                break;

            case "gameReady":
                console.log("initGame")
                initGame()
                break;
            case "exitGame":
                navigate("/")
                break;
        }
        event.stopPropagation();
        event.preventDefault();
    }


    useEffect(() => {
        if (gameLink !== props.gameLink && props.gameLink != undefined && props.gameLink != '') {
            setGameLink(props.gameLink)
            setIframeLoaded(false)
        }

    }, [isTheatre, iframeLoaded, props.gameLink])


    useEffect(() => {
        if (props.initGame) {
            let initGame: InitGameDTO = {
                api: props.initGame.api,
                tkn: props.initGame.tkn,
                gmd: props.initGame.gmd,
                lan: props.initGame.lan,
                gid: props.initGame.gid,
                cms: props.initGame.cms,
                rsm: props.replayGameInfo != null ? props.replayGameInfo : "",
                jai: props.initGame.jai
            }
            initGameJson.current = initGame
        }

        if (!initListener) {
            window.addEventListener('message', gameFrameMessageEvents);
            setInitListener(true)
        }

        return () => {
            window.removeEventListener('message', gameFrameMessageEvents);
        }
    }, [])

    useEffect(() => {
        if (location.state) {
            let gameContainerData = location.state
            setGameLink(gameContainerData.gameLink)
            setIsGameContainer(gameContainerData.isGameContainer)
            let initGame: InitGameDTO = {
                api: gameContainerData.initGame.api,
                tkn: gameContainerData.initGame.tkn,
                gmd: gameContainerData.initGame.gmd,
                lan: gameContainerData.initGame.lan,
                gid: gameContainerData.initGame.gid,
                cms: gameContainerData.initGame.cms,
                rsm: gameContainerData.replayGameInfo != null ? props.replayGameInfo : "",
                jai: gameContainerData.initGame.jai
            }
            initGameJson.current = initGame
        }
    }, [location.state])

    useEffect(() => {
        if (currentWallet) {
            currentWalletJson.current = currentWallet
        }

        if (jackpotData) {
            jackpotDataJson.current = jackpotData
        }

    }, [currentWallet, jackpotData])

    return (
        <>
            {isGameContainer ?
                (<div className="game-container" >
                    <div style={{ height: "100vh", width: "100vw" }} className="ratio ratio-16x9" >
                        <iframe id="gameFrame" className="embed-responsive-item" src={gameLink} onLoad={handleIFrameLoad} allowFullScreen allow="autoplay; screen-wake-lock" />
                    </div>
                </div>)
                :
                <div style={{ height: props.frameHeight }} className={"iframe-container " + (props.disableAnimation ? "" : "show-game ") + (iframeLoaded ? (isTheatre ? "container-theatre" : "container-non-theatre") : "")}>
                    {!iframeLoaded ? <div className="loading-container"> <Loader /> </div> : null}
                    <div className={!iframeLoaded ? "hidden" : "game-iframe " + (props.disableAnimation ? "" : "show-game")}>
                        <div className="ratio ratio-16x9" style={{ height: props.frameHeight }}>
                            <iframe id="gameFrame" hidden={iframeLoaded ? false : true} className={"embed-responsive-item " + (props.showOptions ? "options-container" : "")} src={gameLink} onLoad={handleIFrameLoad} allowFullScreen allow="autoplay; screen-wake-lock" />
                        </div>
                        {props.showOptions ?
                            (<div className="iframe-options-container"><Button className="iframe-options-button" color="deoco" onClick={handleFullscreen}><FontAwesomeIcon className="fa-xl" icon={faExpand} /></Button><Button className="iframe-options-button" color="deoco" onClick={handleTheatreMode}><FontAwesomeIcon className="fa-xl" icon={regularSquare} /></Button></div>)
                            : <></>}
                    </div>
                </div>
            }
        </>
    );
}
export default GameFrame;
