import React, { useContext, useEffect, useState } from "react";
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import VideoPopUpModal from "../components/modals/VideoPopUpModal";
import { GameHistoryDTO, GameHistoryListDTO } from "../dto/historyDTO"
import HistoryTable from "./HistoryTable";
import { useDispatch, useSelector } from "react-redux";
import { GetActiveGameHistory, GetGameHistory } from "../states/History/HistoryActions";
import { IStore } from "../states/store/IStore";
import { strings } from "../assets/strings";
import { LanguageModeContext } from "../context/LanguageContext";
import { ThemesModeContext } from "../context/ThemesContext";
import { Orientation, Theme } from "../enums/Style";
import HistoryDetailsModal from "./modals/HistoryDetailsModal";

export enum HistoryTabs {
    ACTIVE,
    COMPLETED
}

const HistoryWidget = (props: { serviceId: number, gameName: string, onBackToServices: any, playerID: number, username: string }) => {
    const { language } = useContext(LanguageModeContext)
    const maxContentView = 5
    const maxPageView = 5

    const dispatch = useDispatch()

    const historyState = useSelector((state: IStore) => state.historyState)
    const { gameHistoryList } = historyState
    const { orientation, theme } = useContext(ThemesModeContext)
    const [isPortrait, setIsPortrait] = useState(false)

    const [currentServiceId, setCurrentServiceId] = useState<number | undefined>(undefined)
    const [dataList, setDataList] = useState({} as GameHistoryListDTO)
    const [showVideoModal, setshowVideoModal] = useState(false);
    const [showDetailsModal, setshowDetailsModal] = useState(false);
    const [historyDetails, setHistoryDetails] = useState<GameHistoryDTO | undefined>(undefined);
    const [replayGameHistoryID, setReplayGameHistoryID] = useState<number | undefined>(undefined);
    const [replayGameServiceID, setReplayGameServiceID] = useState<number | undefined>(undefined);
    const [replayGameCMS, setReplayGameCMS] = useState<number | undefined>(undefined);
    const [replayGameDateTime, setReplayGameDateTime] = useState<Date | undefined>(undefined);
    const [currentHistoryTab, setCurrentHistoryTab] = useState(HistoryTabs.COMPLETED);
    const [isActiveGame, setIsActiveGame] = useState(false);

    const handleVideoModalOpened = (value: boolean) => {
        setshowVideoModal(value)
    };

    const handleDetailsModalOpened = (value: boolean) => {
        setshowDetailsModal(value)
    };

    const reloadData = (page: number) => {
        if (currentHistoryTab == HistoryTabs.COMPLETED) {
            dispatch(GetGameHistory(page - 1, maxContentView, props.serviceId, props.playerID, props.username))
        } else {
            dispatch(GetActiveGameHistory(page - 1, maxContentView, props.serviceId, props.playerID, props.username))
        }
    }

    const handleHistoryReplay = (isReplay: boolean, historyId: number, serviceId: number, cms: number, dateTime: Date, betAmount: number, payout: number, currency: string) => {
        if (isReplay) {
            setshowVideoModal(!showVideoModal)
            setReplayGameHistoryID(historyId)
            setReplayGameServiceID(serviceId)
            setReplayGameCMS(cms)
            setReplayGameDateTime(dateTime)
        }
    }

    const handleHistoryDetails = (details: GameHistoryDTO) => {
        setshowDetailsModal(!showVideoModal)
        setHistoryDetails(details)
    }

    const adjustSelector = (tab: HistoryTabs) => {
        var tabSelector = document.getElementById("tabSelector")
        var tabLeft = document.getElementById("tabLeft")
        var tabNav = document.getElementById("tabNav")
        var tabRight = document.getElementById("tabRight")
        if (tabSelector && tabLeft && tabRight && tabNav) {
            tabSelector.style.setProperty("--tab-marginLeft", tabLeft.clientWidth + "px");
            tabSelector.style.setProperty("--tabLeft-width", tabLeft.clientWidth + "px");
            tabSelector.style.setProperty("--tabRight-width", tabRight.clientWidth + "px");
            if (tab == HistoryTabs.COMPLETED) {
                tabSelector.style.width = tabLeft.clientWidth + "px"
                tabSelector.style.marginLeft = "0px"
            }
            else {
                tabSelector.style.width = tabRight.clientWidth + "px"
                tabSelector.style.marginLeft = "var(--tab-marginLeft)"
            }
        }
    }

    const switchTab = (tab: HistoryTabs) => {
        if (currentHistoryTab != tab) {
            setCurrentHistoryTab(tab)
            adjustSelector(tab)

            if (tab == HistoryTabs.COMPLETED) {
                setIsActiveGame(false)
                dispatch(GetGameHistory(0, maxContentView, props.serviceId, props.playerID, props.username))
            }
            else {
                setIsActiveGame(true)
                dispatch(GetActiveGameHistory(0, maxContentView, props.serviceId, props.playerID, props.username))
            }
        }
    }

    const handleBackButton = () => {
        props.onBackToServices(true)
    }

    useEffect(() => {
        if (props.serviceId != undefined && props.serviceId != currentServiceId) {
            setCurrentHistoryTab(HistoryTabs.COMPLETED)
            setCurrentServiceId(props.serviceId)
            dispatch(GetGameHistory(0, maxContentView, props.serviceId, props.playerID, props.username))
        }
    }, [props.serviceId])

    useEffect(() => {
        if (gameHistoryList && gameHistoryList.ttc > 0) {
            setDataList(gameHistoryList)
        }

    }, [gameHistoryList])

    useEffect(() => {
        setIsPortrait(orientation == Orientation.PORTRAIT)
        adjustSelector(currentHistoryTab)
    }, [orientation])

    useEffect(() => {
        adjustSelector(currentHistoryTab)
    }, [language])

    return (
        <>
            <Row className="justify-content-center">
                <Nav id="tabNav" tabs className="history-tab">
                    <div id="tabSelector" className={"selected-slider " + (currentHistoryTab == HistoryTabs.COMPLETED ? "left " : "right ")}></div>
                    <NavItem id="tabLeft">
                        <NavLink className={"tab-item " + (currentHistoryTab == HistoryTabs.COMPLETED ? "selected" : "")} onClick={() => { switchTab(HistoryTabs.COMPLETED) }}>
                            {strings.COMPLETED_GAMES[language]}
                        </NavLink>
                    </NavItem>
                    <NavItem id="tabRight">
                        <NavLink className={"tab-item " + (currentHistoryTab == HistoryTabs.ACTIVE ? "selected" : "")} onClick={() => { switchTab(HistoryTabs.ACTIVE) }}>
                            {strings.ACTIVE_GAMES[language]}
                        </NavLink>
                    </NavItem>
                </Nav>
            </Row>
            <Row className="history-widget justify-content-center">
                <Card className={"history-card fade-in-top " + (isPortrait ? "portrait" : "")}>
                    <CardHeader className="history-widget-header">
                        <div className="back-button" onClick={handleBackButton}><FontAwesomeIcon icon={faAngleLeft} /></div>
                        <div>{props.gameName}</div>
                    </CardHeader>
                    <CardBody className="history-widget-body">
                        <Col className="p-0">
                            <TabContent activeTab={currentHistoryTab}>
                                <TabPane tabId={HistoryTabs.COMPLETED}>
                                    {currentHistoryTab == HistoryTabs.COMPLETED && gameHistoryList && gameHistoryList.ttc > 0 ? <Row className="history-details">
                                        <HistoryTable
                                            dataList={dataList}
                                            reloadData={reloadData}
                                            totalDataCount={gameHistoryList.ttc}
                                            maxContentView={maxContentView}
                                            maxPageView={maxPageView}
                                            onDetailsSelected={handleHistoryDetails}
                                            onReplaySelected={handleHistoryReplay}
                                        />
                                    </Row> : <Row className="history-no-data">{strings.NO_DATA_FOUND[language]}</Row>}
                                </TabPane>
                                <TabPane tabId={HistoryTabs.ACTIVE}>
                                    {currentHistoryTab == HistoryTabs.ACTIVE && gameHistoryList && gameHistoryList.ttc > 0 ? <Row className="history-details">
                                        <HistoryTable
                                            dataList={dataList}
                                            reloadData={reloadData}
                                            totalDataCount={gameHistoryList.ttc}
                                            maxContentView={maxContentView}
                                            maxPageView={maxPageView}
                                            onDetailsSelected={handleHistoryDetails}
                                            onReplaySelected={handleHistoryReplay}
                                        />
                                    </Row> : <Row className="history-no-data">{strings.NO_DATA_FOUND[language]}</Row>}
                                </TabPane>
                            </TabContent>
                        </Col>
                    </CardBody>
                </Card>

            </Row>
            <VideoPopUpModal
                modalTitle={currentHistoryTab == HistoryTabs.COMPLETED ? strings.COMPLETED_GAME[language] : strings.ACTIVE_GAME[language]}
                openModal={showVideoModal}
                onChange={handleVideoModalOpened}
                gameHistoryID={replayGameHistoryID}
                gameServiceID={replayGameServiceID}
                cmsType={replayGameCMS}
                isActiveGame={isActiveGame}
                dateTime={replayGameDateTime}
            />
            <HistoryDetailsModal openModal={showDetailsModal} onChange={handleDetailsModalOpened} details={historyDetails != undefined ? historyDetails : undefined} darkMode={theme == Theme.DARK} />
        </>
    );
}
export default HistoryWidget;
