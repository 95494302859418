import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Col, NavItem, NavLink, Row } from "reactstrap";
import { strings } from "../../assets/strings";
import { DemoModeContext } from "../../context/DemoContext";
import { FunplayModeContext } from "../../context/FunplayContext";
import { LanguageModeContext } from "../../context/LanguageContext";
import { ThemesModeContext } from "../../context/ThemesContext";
import { Orientation } from "../../enums/Style";
import { IStore } from "../../states/store/IStore";

import "../../styles/NavigationBar.css";

const BottomNavigationBar = () => {
    const location = useLocation();
    const { orientation, theme } = useContext(ThemesModeContext)
    const { language } = useContext(LanguageModeContext)
    const { funplayMode } = useContext(FunplayModeContext)
    const isDemo = useContext(DemoModeContext)

    const userState = useSelector((state: IStore) => state.userState)
    const { isLogin } = userState

    const [isPortrait, setIsPortrait] = useState(false)

    useEffect(() => {
        setIsPortrait(orientation == Orientation.PORTRAIT)
    }, [orientation, theme])

    return (
        <Row fluid="true" id="navBar" className={"navigation-bar " + (isPortrait ? "portrait" : "landscape")}>
            <div className={"navigation-bar-item " + (isPortrait ? "portrait col" : "landscape")}>
                <NavItem className="nav-item">
                    <NavLink tag={Link} to="/">
                        <Row>
                            <Col id={location.pathname == "/" ? "homeIconSelected" : "homeIcon"} className="icon-image nav-bar-icon" >
                            </Col>
                        </Row>
                        <Row>
                            <Col className={location.pathname == "/" ? "selected" : ""}>
                                {strings.HOME[language]}
                            </Col>
                        </Row>
                    </NavLink>
                </NavItem>
            </div>
            {/*{lobbyGames && lobbyGames.lbg && lobbyGames.lbg.length > 1 ?*/}
            {/*    < div className={"navigation-bar-item " + (isPortrait ? "portrait col" : "landscape")}>*/}
            {/*        <NavItem className="nav-item">*/}
            {/*            <NavLink tag={Link} to="/">*/}
            {/*                <Row>*/}
            {/*                    <Col id={location.pathname == "/GameList" ? "gameIconSelected" : "gameIcon"} className="icon-image nav-bar-icon" >*/}
            {/*                    </Col>*/}
            {/*                </Row>*/}
            {/*                <Row>*/}
            {/*                    <Col className={location.pathname == "/GameList" ? "selected" : ""}>*/}
            {/*                        Games*/}
            {/*                    </Col>*/}
            {/*                </Row>*/}
            {/*            </NavLink>*/}
            {/*        </NavItem>*/}
            {/*    </div> : <></>*/}
            {/*}*/}
            {isLogin && !funplayMode && !isDemo ?
                <div className={"navigation-bar-item " + (isPortrait ? "portrait col" : "landscape")}>
                    <NavItem className="nav-item">
                        <NavLink tag={Link} to="/History" onClick={(e) => { funplayMode ? e.preventDefault() : {} }}>
                            <Row>
                                <Col id={location.pathname == "/History" ? "historyIconSelected" : "historyIcon"} className={"icon-image nav-bar-icon " + (isPortrait ? "portrait" : "landscape")} >
                                </Col>
                            </Row>
                            <Row>
                                <Col className={"text-nowrap "+(location.pathname == "/History" ? "selected" : "")}>
                                    {strings.HISTORY[language]}
                                </Col>
                            </Row>
                        </NavLink>
                    </NavItem>
                </div> : <></>
            }

        </Row >
    );
}
export default BottomNavigationBar;
