import { RememberMeDTO } from '../../dto/rememberMeDTO';
import * as PlayerAction from './PortalConfigActions';
import { LocalConfigActionType } from './PortalConfigConstants';
import { InitRememberMeState, IRememberMeState } from './PortalConfigStates';

export const rememberMeReducer = (state = InitRememberMeState, action: PlayerAction.ActionTypes): IRememberMeState => {
    switch (action.type) {
        case LocalConfigActionType.GET_REMEMBER_ME_REQUEST:
            return { ...state, loading: false, error: "", rememberMeInfo: { } as RememberMeDTO }
        case LocalConfigActionType.GET_REMEMBER_ME_SUCCESS:
            return { ...state, loading: false, rememberMeInfo: action.payload as RememberMeDTO, error: "" }
        case LocalConfigActionType.GET_REMEMBER_ME_FAILED:
            return { ...state, loading: false, error: action.payload }
        default:
            return state
    }
}
