import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { resetFunctions } from "../App";
import { strings } from "../assets/strings";
import { DemoModeContext } from "../context/DemoContext";
import { FunplayModeContext } from "../context/FunplayContext";
import { LanguageModeContext } from "../context/LanguageContext";
import { WorkerMessageDTO } from "../dto/workerMessageDTO";
import { WorkerActions } from "../enums/WorkerActions";
import { IStore } from "../states/store/IStore";
import IdlePopUpModal from "./modals/IdlePopUpModal";
import LogoutLoadingModal from "./modals/LogoutLoadingModal";
import NotificationModal from "./modals/NotificationModal";

const Idler = () => {
    const isDemo = useContext(DemoModeContext)
    const { language } = useContext(LanguageModeContext)
    const { funplayMode } = useContext(FunplayModeContext)

    const [initializeListener, setInitializeListener] = useState(false)
    const [isResetTimer, setIsResetTimer] = useState(false);
    const [triggerResetTimer, setTriggerResetTimer] = useState(false);
    const [idlePromptTime, setIdlePromptTime] = useState<number | undefined>(undefined);
    const [showIdleModal, setshowIdleModal] = useState(false);
    const [showLogoutModal, setshowLogoutModal] = useState(false);
    const [showNotificationModal, setshowNotificationModal] = useState(false);
    const userState = useSelector((state: IStore) => state.userState)
    const { isLogin, loading } = userState

    const idleTimer = useRef<NodeJS.Timeout | undefined>(setInterval(() => { }, 0))
    const idleSeconds = useRef<number>(0)
    const playerLogin = useRef<boolean>(false)
    const lastActiveTime = useRef<number | undefined>(undefined)

    function idlerMessageEvents(event: MessageEvent) {
        switch (event.data["type"]) {
            case "ping":
                console.log('ping')
                navigator.serviceWorker.ready.then((registration) => {
                    if (registration.active) {
                        registration.active.postMessage({
                            actionType: WorkerActions.START_IDLE,
                            data: true
                        });
                    }
                })
                break;
            case "invalidSession":
                sessionLogout(true)
                break;
        }
        event.stopPropagation();
        event.preventDefault();
    }

    const stopIdleTimer = (clearTimer: boolean = false) => {
        if (idleTimer.current != undefined) {
            clearInterval(idleTimer.current)
            idleTimer.current = setInterval(() => { }, 0)
        }
        idleSeconds.current = 0

        if (clearTimer) {
            setIdlePromptTime(undefined)
            setshowIdleModal(false)
            window.onload = () => null
            window.onmousemove = () => null
            window.onmousedown = () => null
            window.ontouchstart = () => null
            window.onclick = () => null
            window.onkeypress = () => null
        }
    }

    const resetTimer = () => {
        if (playerLogin && !isDemo && idlePromptTime != 0 && !showIdleModal && !showLogoutModal && !showNotificationModal) {
            lastActiveTime.current = undefined
            if (idleTimer.current != undefined) {
                clearInterval(idleTimer.current)
            }
            idleSeconds.current = 0
            setIsResetTimer(true)
        }
    }

    const startIdleTimer = () => {
        idleSeconds.current++
        console.log(idleSeconds.current)
        if (idleSeconds.current == idlePromptTime) {
            setshowIdleModal(true)
            lastActiveTime.current = undefined
        }
    }

    const sessionLogout = (logout: boolean) => {
        if (logout) {
            stopIdleTimer()
            logoutHandler()
            lastActiveTime.current = undefined
        }
    }

    const logoutHandler = () => {
        resetFunctions()
        setshowLogoutModal(true)
    }

    useEffect(() => {
        playerLogin.current = isLogin
        if (!loading && isLogin && localStorage.getItem('tkn')) {
            if (Number.parseInt(localStorage.getItem('idt') || "") > 0) {
                let idleTime = Number.parseInt(localStorage.getItem('idt') || "")

                if (idleTime > 0) {
                    if (idleTime > 10)
                        setIdlePromptTime(idleTime - 10)
                    else
                        setIdlePromptTime(0)
                }
            }

        } else if (!isLogin) {
            stopIdleTimer(true)
            window.removeEventListener('message', idlerMessageEvents);
        }

        if (!initializeListener) {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.addEventListener('message', async (e: any) => {
                    if (e.data) {
                        var message = e.data as WorkerMessageDTO
                        switch (message.actionType) {
                            case WorkerActions.STOP_IDLE:
                                stopIdleTimer()
                                break;
                            case WorkerActions.START_IDLE:
                                setTriggerResetTimer(true)
                                break;
                        }
                    }
                })
            }

            window.addEventListener('message', idlerMessageEvents)
            setInitializeListener(true)
        }
    }, [isLogin])

    useEffect(() => {
        if (isResetTimer) {
            if (isLogin && idleTimer.current != undefined && !showIdleModal && !showLogoutModal && !showNotificationModal && idlePromptTime) {
                if (lastActiveTime.current != undefined && Date.now() - lastActiveTime.current >= ((idlePromptTime + 10) * 1000)) {
                    console.log("Timespan from last active time: " + (Date.now() - lastActiveTime.current))
                    sessionLogout(true)
                    setshowNotificationModal(true)
                }
                else {
                    idleTimer.current = setInterval(startIdleTimer, 1000)
                    lastActiveTime.current = Date.now()
                }
            }
            setIsResetTimer(false)
        }
    }, [isResetTimer])

    useEffect(() => {
        if (triggerResetTimer) {
            resetTimer()
            setTriggerResetTimer(false)
        }
    }, [triggerResetTimer])

    useEffect(() => {
        if (showIdleModal) {
            stopIdleTimer()
        }
        else if (!showIdleModal && !showLogoutModal && !showNotificationModal && playerLogin) {
            resetTimer()
        }

    }, [showIdleModal])

    useEffect(() => {
        if (isLogin && idlePromptTime != 0 && !showIdleModal) {
            //set idle timeout
            window.onload = () => { setTriggerResetTimer(true) }
            window.onmousemove = () => { setTriggerResetTimer(true) }
            window.onmousedown = () => { setTriggerResetTimer(true) }
            window.ontouchstart = () => { setTriggerResetTimer(true) }
            window.onclick = () => { setTriggerResetTimer(true) }
            window.onkeypress = () => { setTriggerResetTimer(true) }
        }


    }, [idlePromptTime])

    return (
        <>
            <IdlePopUpModal openModal={showIdleModal} onChange={setshowIdleModal} isLogout={sessionLogout} />
            <LogoutLoadingModal openModal={showLogoutModal} onChange={setshowLogoutModal} />
            <NotificationModal openModal={showNotificationModal} onChange={setshowNotificationModal} title={strings.SESSION_TIMEOUT[language]} content={strings.LOG_OUT_DUE_TO_INACTIVITY[language]} appearSeconds={5} />
        </>
    );
}
export default Idler;
