import axios from 'axios';
import { RememberMeDTO } from '../../dto/rememberMeDTO';
import { LocalConfigActionType } from './PortalConfigConstants';

// Custom Type Declaration
type GetRememberMe_Request = { type: LocalConfigActionType.GET_REMEMBER_ME_REQUEST };
type GetRememberMe_Success = { type: LocalConfigActionType.GET_REMEMBER_ME_SUCCESS, payload: {} };
type GetRememberMe_Failed = { type: LocalConfigActionType.GET_REMEMBER_ME_FAILED, payload: {} };

export type ActionTypes =
    GetRememberMe_Request |
    GetRememberMe_Success |
    GetRememberMe_Failed;

export const getRememberMeConfig = () => async (dispatch: any, getState: any) => {
    try {
        dispatch({
            type: LocalConfigActionType.GET_REMEMBER_ME_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json'
            },
        }

        const { data } = await axios.get(
            "PortalConfig/GetRememberMeConfig",
            config
        )

        var rememberMeData: RememberMeDTO = JSON.parse(data.res)


        dispatch({
            type: LocalConfigActionType.GET_REMEMBER_ME_SUCCESS,
            payload: rememberMeData
        });
    } catch (error) {
        dispatch({
            type: LocalConfigActionType.GET_REMEMBER_ME_FAILED,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}
