import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { strings } from "../../assets/strings";
import { DemoModeContext } from "../../context/DemoContext";
import { FunplayModeContext } from "../../context/FunplayContext";
import { LanguageModeContext } from "../../context/LanguageContext";
import { GameMode } from "../../enums/GameMode";
import { logout } from "../../states/Player/PlayerActions";
import { IStore } from "../../states/store/IStore";
import Loader from "../Loader";

const LogoutLoadingModal = (props: { openModal: boolean, onChange: any }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const isDemo = useContext(DemoModeContext)
    const { funplayMode, setFunplayMode } = useContext(FunplayModeContext)
    const { language } = useContext(LanguageModeContext)
    const [modal, setModal] = useState(props.openModal);
    const userState = useSelector((state: IStore) => state.userState)
    const { isLogin } = userState

    useEffect(() => {
        setModal(props.openModal)
    }, [props.openModal])

    useEffect(() => {
        if (modal) {
            dispatch(logout(isDemo ? GameMode.DEMO : funplayMode ? GameMode.FUNPLAY : GameMode.NORMAL))
        }
    }, [modal])

    useEffect(() => {
        if (modal) {
            setModal(false);
            props.onChange(false);
        }
        if (!isLogin && localStorage.getItem('tkn') == null) {
            navigate('/')
            if (funplayMode)
                setFunplayMode(false)
        }
    }, [isLogin])

    return (
        <Modal id="logoutLoadingModal" isOpen={modal} >
            <ModalBody id="logoutLoadingModalBody" className="loading-container">
                <Row>
                    <Loader />
                </Row>
                <Row>
                    <Col>{strings.LOGGING_OUT[language]}</Col>
                </Row>
            </ModalBody>
        </Modal>
    );
}
export default LogoutLoadingModal;
