import React, { useContext, useEffect, useRef, useState } from "react";
import { Row } from "reactstrap";
import { strings } from "../assets/strings";
import { LanguageModeContext } from "../context/LanguageContext";
import { Language } from "../enums/Language";

const Pagination = (props: { onReloadData: any, totalDataCount: number, dataList: any, maxContentNum: number, maxPageNum: number }) => {
    const { language } = useContext(LanguageModeContext)
    const maxPageIndex = useRef(0);
    const minPageIndex = useRef(0);
    const [pages, setPages] = useState(0);
    const [maxPageView, setMaxPageView] = useState(0);
    const currentPage = useRef(0);
    const [pageSelection, setPageSelection] = useState([] as JSX.Element[])
    const [currentLanguage, setCurrentLanguage] = useState(Language.EN)

    //generate pagination page list
    const generatePageList = (firstIndex: number) => {
        //only generate pagination when more than one page
        if (maxPageView > 1) {
            const pageNums = []
            pageNums.push(<li key="list-first-item" id="list-first-item" className="page-item" onClick={() => { selectPage(1) }} style={{ color: "#007bff" }}> <a className="page-link">{strings.FIRST_PAGE[language]}</a></li >)
            pageNums.push(<li key="list-previous-item" id="list-previous-item" className="page-item" onClick={() => { getPreviousPage() }} style={{ color: "#007bff" }}><a className="page-link">&laquo;</a></li>)
            for (let pageIndex = firstIndex; pageIndex < firstIndex + maxPageView; pageIndex++) {
                let id = "list-item-" + pageIndex
                pageNums.push(<li key={id} id={id} className="page-item" onClick={() => { selectPage(pageIndex) }} style={{ color: "#007bff" }}><a className="page-link">{pageIndex}</a></li>)
            }
            pageNums.push(<li key="list-next-item" id="list-next-item" className="page-item" onClick={() => { getNextPage() }} style={{ color: "#007bff" }}><a className="page-link">&raquo;</a></li>)
            pageNums.push(<li key="list-last-item" id="list-last-item" className="page-item" onClick={() => { selectPage(pages) }} style={{ color: "#007bff" }}><a className="page-link">{strings.LAST_PAGE[language]}</a></li>)

            setPageSelection(pageNums)
        }
    }

    const getPreviousPage = () => {
        selectPage(currentPage.current - 1 > 1 ? currentPage.current - 1 : 1)
    }
    const getNextPage = () => {
        selectPage(currentPage.current + 1 < pages ? currentPage.current + 1 : pages)
    }

    const stylePagination = () => {
        for (let pageIndex = minPageIndex.current; pageIndex < minPageIndex.current + maxPageView; pageIndex++) {
            var listItem = document.getElementById("list-item-" + pageIndex)
            if (listItem) {
                listItem.classList.remove('active')
                if (pageIndex == currentPage.current)
                    listItem.classList.add('active')
            }
        }

        let startIndex = currentPage.current - 1 < 1 ? 0 : props.maxContentNum * (currentPage.current - 1) + 1
        let endIndex = props.maxContentNum * currentPage.current

        var listPreviousItem = document.getElementById("list-previous-item")
        var listNextItem = document.getElementById("list-next-item")
        var listFirstItem = document.getElementById("list-first-item")
        var listLastItem = document.getElementById("list-last-item")

        if (listPreviousItem && listNextItem && listFirstItem && listLastItem) {
            if (endIndex >= props.totalDataCount) {
                listNextItem.classList.add('disabled')
                listLastItem.classList.add('disabled')
            }
            else {
                listNextItem.classList.remove('disabled')
                listLastItem.classList.remove('disabled')
            }

            if (startIndex === 0) {
                listPreviousItem.classList.add('disabled')
                listFirstItem.classList.add('disabled')
            }
            else {
                listPreviousItem.classList.remove('disabled')
                listFirstItem.classList.remove('disabled')
            }
        }
    }

    const selectPage = (page: number, reloadLanguage?: boolean) => {
        currentPage.current = page

        if (reloadLanguage) {
            generatePageList(minPageIndex.current)
        }
        else {
            if (page >= maxPageIndex.current && page < pages) {
                maxPageIndex.current = page + maxPageView - 2
                minPageIndex.current = page - 1
                generatePageList(page - 1)
            }
            else if (page == pages) {
                maxPageIndex.current = page
                minPageIndex.current = page - maxPageView + 1
                generatePageList(page - maxPageView + 1)
            }
            else if (page <= minPageIndex.current && page > 1) {
                minPageIndex.current = page - maxPageView + 2
                maxPageIndex.current = page + 1
                generatePageList(page - maxPageView + 2)
            }
            else if (page == 1) {
                minPageIndex.current = page
                maxPageIndex.current = page + maxPageView - 1
                generatePageList(page)
            }

            props.onReloadData(page)
        }
    }

    useEffect(() => {
        minPageIndex.current = 1
        maxPageIndex.current = Math.ceil(props.maxPageNum)
        currentPage.current = 1
    }, [])

    useEffect(() => {
        if (props.totalDataCount > 0 && pages == 0) {
            var availablePages = Math.ceil(props.totalDataCount / props.maxContentNum)
            setPages(availablePages)
            setMaxPageView(Math.ceil(props.maxPageNum) > availablePages ? availablePages : Math.ceil(props.maxPageNum))
        }
    }, [props.totalDataCount])

    useEffect(() => {
        var pagination = document.getElementById("pagination")
        var pageSelection = document.getElementById("page-selection")

        if (pagination && pageSelection && pagination.innerHTML.length != 0) {
            if (pages != 0 && pages != 1) {
                generatePageList(1)
            }
            else {
                pageSelection.innerHTML = ""
            }
        }
    }, [pages])

    useEffect(() => {
        stylePagination()
    }, [props.dataList, pageSelection])

    useEffect(() => {
        if (language != currentLanguage) {
            setCurrentLanguage(language)
            selectPage(currentPage.current, true)
        }
    }, [language])

    return (
        <Row id="pagination">
            <nav className="row justify-content-center p-0 m-0">
                <ul id="page-selection" className="pagination justify-content-center pe-0">
                    {pageSelection}
                </ul>
            </nav>
        </Row>
    );
}
export default Pagination;
