import React, { useContext, useEffect, useState } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import GameFrame from '../GameFrame';
import { GetHistoryGameReplay } from '../../states/History/HistoryActions';
import { IStore } from '../../states/store/IStore';
import { InitGameDTO } from '../../dto/gameDTO';
import { LanguageModeContext } from '../../context/LanguageContext';
import { GameMode } from '../../enums/GameMode';
import axios from 'axios';
import { FormatCurrency, FormatDateTime } from '../../utils/StringFormatHelper';
import { strings } from '../../assets/strings';
import Loader from '../Loader';

interface IVideoPopUpModal {
    modalTitle: string,
    openModal: boolean,
    onChange: any,
    isActiveGame: boolean,
    gameHistoryID?: number,
    gameServiceID?: number,
    cmsType?: number,
    dateTime?: Date,
}

const VideoPopUpModal = (props: IVideoPopUpModal) => {
    const dispatch = useDispatch()
    const { language } = useContext(LanguageModeContext)

    const historyState = useSelector((state: IStore) => state.historyState)
    const { replayGameData } = historyState
    const userState = useSelector((state: IStore) => state.userState)
    const { userInfo } = userState

    const [modal, setModal] = useState(props.openModal);
    const [replayGameFrameInit, setReplayGameFrameInit] = useState<InitGameDTO | undefined>(undefined);
    const [replayGameString, setReplayGameString] = useState("");
    const [api, setApi] = useState("");
    const [gamelink, setGamelink] = useState("");
    const [historyID, setHistoryID] = useState(0);
    const [dateTime, setDateTime] = useState("");

    const toggle = () => {
        setModal(!modal);
        props.onChange(!modal);
    };

    const getAPI = async () => {
        var { data } = await axios.get("API")
        setApi(data)
    }

    useEffect(() => {
        getAPI()
    }, [])

    useEffect(() => {
        if (props.gameHistoryID != undefined && props.gameServiceID != undefined && props.cmsType != undefined) {
            setHistoryID(props.gameHistoryID)

            setModal(props.openModal)
            setReplayGameFrameInit(undefined)

            if (props.openModal) {
                dispatch(GetHistoryGameReplay(props.gameHistoryID, props.gameServiceID, props.cmsType, props.isActiveGame))
            }
        }

        if (props.dateTime != undefined)
            setDateTime(FormatDateTime(props.dateTime))
    }, [props.openModal])

    useEffect(() => {
        if (replayGameData) {
            if (replayGameData.rsm)
                setReplayGameString(replayGameData.rsm)
            let token = localStorage.getItem('tkn')

            let initGame: InitGameDTO = {
                api: api,
                tkn: token != undefined ? token : "",
                gmd: GameMode.REPLAY,
                lan: language,
                gid: replayGameData.gid,
                cms: userInfo.cms,
                jai: 0
            }
            setReplayGameFrameInit(initGame)
            setGamelink(replayGameData.url)

        }
    }, [replayGameData])

    const renderReplayGameFrame = () => {
        return (<GameFrame gameLink={gamelink} showOptions={false} initGame={replayGameFrameInit} replayGameInfo={replayGameString} />)
    }

    return (
        <Modal id="videoModal" isOpen={modal}>
            <ModalHeader id="videoModalHeader" toggle={toggle}>
                <Col>
                    <Row id="videoModalTitle">
                        <Col>{props.modalTitle}</Col>
                    </Row>
                    <Row className="ps-3 pe-3">
                        <Col>
                            <Row>
                                <Col className="history-table-header" md={2} style={{ maxWidth: "8em", minWidth: "8em" }}>ID:</Col>
                                <Col>{historyID}</Col>
                            </Row>
                            <Row>
                                <Col className="history-table-header" md={2} style={{ maxWidth: "8em", minWidth: "8em" }}>{strings.DATE_TIME[language]}:</Col>
                                <Col>{dateTime}</Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </ModalHeader>
            <ModalBody id="videoModalBody" className="ps-4 pe-4">
                {replayGameFrameInit != undefined && Object.keys(replayGameData).length > 0 ? renderReplayGameFrame() :
                    <Loader />
                }
            </ModalBody>
        </Modal>
    );
}

export default VideoPopUpModal;