import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const DemoModeContext = createContext(false);

export const DemoContext = (props: any) => {
    const [demoMode, setDemoMode] = useState(false);

    const initDemoMode = async () => {
        var { data } = await axios.get("Demo")
        setDemoMode(data)
    }

    useEffect(() => {
        initDemoMode();
    }, []);

    return (
        <DemoModeContext.Provider
            value={demoMode}>
            {props.children}
        </DemoModeContext.Provider>
    );
};