

export function FormatCurrency(value: number): string {
    if (!isNaN(Number(value))) {
        return value.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    }
    else
        return "NaN"
}

export function FormatDateTime(date: Date): string {
    let p = new Intl.DateTimeFormat('en', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    }).formatToParts(new Date(date).getTime()).reduce((acc: any, part) => {
        acc[part.type] = part.value;
        return acc;
    }, {});

    return `${p.year}/${p.month}/${p.day} ${p.hour}:${p.minute}:${p.second}`; 
}