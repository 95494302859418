import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { ThemesModeContext } from '../context/ThemesContext';
import { GameRoomDTO } from '../dto/gameDTO';
import { Orientation } from '../enums/Style';

const GameWidget = (props: { onSelect: any, gameInfo: GameRoomDTO }) => {
    const [gameName, setGameName] = useState("");
    const { orientation } = useContext(ThemesModeContext);
    const [isPortrait, setIsPortrait] = useState(false);
    const [isLoaded, setIsloaded] = useState(false);
    const [gameIconSource, setGameIconSource] = useState("");
    const [altIconSource, setAltIconSource] = useState("");
    const [gameIcon, setGameIcon] = useState("");

    const openGame = () => {
        props.onSelect(props.gameInfo.gmn, props.gameInfo.url, props.gameInfo)
    }

    useEffect(() => {
        setGameName(props.gameInfo.gmn);
        setGameIconSource(props.gameInfo.icn);
        setAltIconSource(props.gameInfo.aic);
    }, [props])

    useEffect(() => {
        setIsPortrait(orientation == Orientation.PORTRAIT)
    }, [orientation])

    useEffect(() => {
        if (gameIconSource != "") {
            var image = new Image();
            image.src = 'data:image/png;base64,' + gameIconSource;

            setGameIcon(image.src);
            setIsloaded(true);
        }
    }, [gameIconSource]);

    return (
        <>
            {isPortrait ?
                <Row onClick={openGame} className={"game-widget portrait"}>
                    <div className="d-flex align-items-center flex-row w-100 p-0">
                        <div className="me-3">
                            <img
                                src={gameIcon}
                                className="game-widget-img shimmer portrait"
                                style={{
                                    display: isLoaded ? 'block' : 'none'
                                }}
                            />
                            <img
                                className="game-widget-img shimmer portrait"
                                src={altIconSource != "" ? "data:image/png;base64," + altIconSource : ""}
                                style={{
                                    display: isLoaded ? 'none' : 'block'
                                }}
                            />
                        </div>
                        <div className="game-name">
                            <span>{gameName}</span>
                        </div>
                    </div>
                    <div className="history-game-service-arrow w-auto"></div>
                </Row>
                :
                <Col id="widget" className="game-widget" onClick={openGame}>
                    <img
                        className="game-widget-img shimmer"
                        src={gameIcon}
                        style={{
                            display: isLoaded ? 'block' : 'none'
                        }}
                    />
                    <img
                        className="game-widget-img shimmer"
                        src={altIconSource != "" ? "data:image/png;base64," + altIconSource : ""}
                        style={{
                            display: isLoaded ? 'none' : 'block'
                        }}
                    />                    
                    <span className="game-name">{gameName}</span>
                </Col>
            }
        </>
    )
};

export default GameWidget;
