import { RememberMeDTO } from '../../dto/rememberMeDTO'

export interface IRememberMeState {
    rememberMeInfo: RememberMeDTO, loading: boolean, error: {}
}

export const InitRememberMeState: IRememberMeState = {
    rememberMeInfo: {} as RememberMeDTO, loading: false, error: ""
}

