import { faArrowRightFromBracket, faArrowRightToBracket, faCrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, NavbarBrand, Row, UncontrolledButtonDropdown } from 'reactstrap';
import LoginModal from '../../components/modals/LoginModal';
import { login, logout, updateFirstTimeLogin } from '../../states/Player/PlayerActions'
import { IStore } from '../../states/store/IStore';
import { getPlayerProfile } from '../../states/Player/PlayerActions'
import "../../styles/Header.css";
import { DemoModeContext } from '../../context/DemoContext';
import { LanguageModeContext } from '../../context/LanguageContext';
import { strings } from '../../assets/strings'
import LanguageButton from '../../components/LanguageButton';
import { getBalance } from '../../states/PlayerWallet/PlayerWalletActions';
import { WorkerActions } from '../../enums/WorkerActions';
import { FormatCurrency } from '../../utils/StringFormatHelper';
import LogoutLoadingModal from '../../components/modals/LogoutLoadingModal';
import { FunplayModeContext } from '../../context/FunplayContext';
import { GameMode } from '../../enums/GameMode';
import AgreementModal from '../../components/modals/AgreementModal';
import { resetFunctions } from '../../App';
import { ThemesModeContext } from '../../context/ThemesContext';
import { Orientation, Theme } from '../../enums/Style';

const Header = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const isDemo = useContext(DemoModeContext)
    const { funplayMode, setFunplayMode } = useContext(FunplayModeContext)
    const { language, setLanguage } = useContext(LanguageModeContext)
    const { orientation, theme, setTheme } = useContext(ThemesModeContext)

    const [isPortrait, setIsPortrait] = useState(false)
    const [isButtonOpen, setIsButtonOpen] = useState(false)

    const [showLoginModal, setShowLoginModal] = useState(false)
    const [showLogoutModal, setshowLogoutModal] = useState(false)
    const [showAgreementModal, setshowAgreementModal] = useState(false)
    const [tncNotFound, setTncNotFound] = useState(false)
    const [showMemberLogin, setShowMemberLogin] = useState(false)

    const userState = useSelector((state: IStore) => state.userState)
    const { userInfo, isLogin, loading } = userState
    const walletState = useSelector((state: IStore) => state.walletState)
    const { currentWallet, isVIP } = walletState

    const [name, setName] = useState("")
    const [ecBalance, setEcBalance] = useState("0.00")
    const [nnBalance, setNnBalance] = useState("0.00")
    const [firstTimeLogin, setFirstTimeLogin] = useState(true)
    const [currency, setCurrency] = useState("-")

    const userButtonToggle = () => {
        setIsButtonOpen(!isButtonOpen);
    }

    const logoutHandler = () => {
        resetFunctions()
        setshowLogoutModal(true)
    }

    const memberLoginHandler = () => {
        resetFunctions()
        setFunplayMode(false)
        dispatch(logout(GameMode.FUNPLAY))
        setShowMemberLogin(true)
    }

    const agreeTnC = (agree: boolean) => {
        dispatch(updateFirstTimeLogin(!agree, language))
        if (!agree)
            setTncNotFound(true)
    }

    const disagreeTnC = (logout: boolean) => {
        if (logout)
            logoutHandler()
    }

    useEffect(() => {
        setIsPortrait(orientation == Orientation.PORTRAIT)
    }, [orientation, theme])

    useEffect(() => {
        if (isLogin) {
            if (userInfo && Object.keys(userInfo).length > 0) {
                setshowAgreementModal(userInfo.ftl)

                if (!funplayMode) {
                    setLanguage(userInfo.lgg)
                    sessionStorage.setItem('lang', userInfo.lgg.toString())
                }

                setName(userInfo.fnm)
                setFirstTimeLogin(userInfo.ftl)
                setCurrency(userInfo.cur)

                //first time wallet data
                if (!currentWallet || Object.keys(currentWallet).length == 0) {
                    if (userInfo.blc && Object.keys(userInfo.blc).length > 0) {
                        if (isVIP && userInfo.blc.vipec != undefined && userInfo.blc.vipnn != undefined) {
                            setEcBalance(FormatCurrency(userInfo.blc.vipec))
                            setNnBalance(FormatCurrency(userInfo.blc.vipnn))
                        } else if (userInfo.blc.ec != undefined && userInfo.blc.nn != undefined) {
                            setEcBalance(FormatCurrency(userInfo.blc.ec))
                            setNnBalance(FormatCurrency(userInfo.blc.nn))
                        }
                    }
                }
            } else {
                dispatch(getPlayerProfile(language, isDemo ? GameMode.DEMO : funplayMode ? GameMode.FUNPLAY : GameMode.NORMAL))
            }
        }
    }, [userInfo])

    useEffect(() => {
        if (isLogin) {
            if (currentWallet && Object.keys(currentWallet).length > 0) {
                if (isVIP && currentWallet.vipec != undefined && currentWallet.vipnn != undefined) {
                    setEcBalance(FormatCurrency(currentWallet.vipec))
                    setNnBalance(FormatCurrency(currentWallet.vipnn))
                }
                else if (currentWallet.ec != undefined && currentWallet.nn != undefined) {
                    setEcBalance(FormatCurrency(currentWallet.ec))
                    setNnBalance(FormatCurrency(currentWallet.nn))
                }
            }
            else {
                dispatch(getBalance(isDemo ? GameMode.DEMO : funplayMode ? GameMode.FUNPLAY : GameMode.NORMAL))
            }
        }
    }, [currentWallet, isVIP])

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then((registration) => {
                if (registration.active) {
                    localStorage.removeItem('balanceUpdateRunning')
                    registration.active.postMessage({
                        actionType: WorkerActions.BALANCE_DATA_UPDATE,
                        data: null
                    });

                    if (!loading && isLogin && localStorage.getItem('tkn')) {
                        if (!isDemo && !funplayMode) {
                            registration.active.postMessage({
                                actionType: WorkerActions.UPDATE_TOKEN,
                                data: localStorage.getItem('tkn')
                            });

                            if (localStorage.getItem('tkn') && (!localStorage.getItem('balanceUpdateRunning') || (localStorage.getItem('balanceUpdateRunning') && localStorage.getItem('balanceUpdateRunning') == 'false'))) {
                                try {
                                    registration.active.postMessage({
                                        actionType: WorkerActions.BALANCE_DATA_UPDATE,
                                        data: ""
                                    });
                                } catch (e) {
                                    localStorage.setItem('balanceUpdateRunning', 'false')
                                }
                            }
                        }
                    }
                }
            })
        }


        if (!isLogin) {
            if (!localStorage.getItem("tkn"))
                resetFunctions()

            if (!showMemberLogin)
                setShowLoginModal(false)
            else {
                setShowLoginModal(true)
                setShowMemberLogin(false)
            }

            setshowLogoutModal(false)
            setshowAgreementModal(false)
            setTncNotFound(false)
        }
    }, [isLogin])


    return (
        <Row id="header" fluid="true" className="header">
            <Col className="col-2 d-flex pe-4 ps-4 w-auto">
                <NavbarBrand id="header-logo" className="header-logo" tag={Link} to="/" />
            </Col>
            {!funplayMode && !isPortrait && isLogin ?
                <Col id="playerInfoBoxCol" className="d-flex align-items-center">
                    <Row className="w-100 p-0 m-0">
                        <Col className="playerInfoBoxText">
                            <Row className="w-100">
                                <Col className="p-0">
                                    <b>{strings.EC[language]} ({currency}):</b>
                                </Col>
                            </Row>
                            <Row className="w-100">
                                <Col className="p-0">
                                    {ecBalance}
                                </Col>
                            </Row>
                        </Col>
                        <Col className="playerInfoBoxText">
                            <Row className="w-100">
                                <Col className="p-0">
                                    <b>{strings.NN[language]} ({currency}):</b>
                                </Col>
                            </Row>
                            <Row className="w-100">
                                <Col className="p-0">
                                    {nnBalance}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col> : <></>
            }
            <Col className="user-nav">
                <Row className="d-flex align-items-center justify-content-end flex-nowrap me-1 ms-0">
                    {isLogin && userInfo ?
                        (<UncontrolledButtonDropdown className="dropdownBtnGroup p-0 h-100" isOpen={isButtonOpen} toggle={userButtonToggle}>
                            <DropdownToggle color="none" className="p-0">
                                <Row id="playerInfoBox">
                                    {!isPortrait ? (<Col id="playerName" className="landscape d-flex align-items-center flex-column p-0" lg={12} md={10}>
                                        <Row className="playerInfoBoxText landscape w-100">
                                            <Col>
                                                <b>{name}</b>
                                            </Col>
                                        </Row>
                                    </Col>) : <></>}
                                    <Col id="playerIconCol">
                                        <span id="playerIcon" className="icon-image" />
                                        {isVIP ?
                                            <div id="vipLabel">
                                                <FontAwesomeIcon icon={faCrown} size="lg" />
                                                <span className="ms-2">VIP</span>
                                            </div>
                                            : <></>}
                                    </Col>
                                </Row>
                            </DropdownToggle>
                            {!isPortrait ? (<DropdownMenu className="userDropdownMenu landscape" >
                                {funplayMode ? <>
                                    <DropdownItem className="member-login-btn" onClick={() => { memberLoginHandler() }}><FontAwesomeIcon icon={faArrowRightToBracket} size="xl" className="ms-2 me-3" />{strings.LOGIN_AS_MEMBER[language]}</DropdownItem>
                                </> :
                                    <>
                                        <DropdownItem onClick={() => { navigate("/PlayerProfile") }}><span id="playerInfoIcon" className="icon-image" />{strings.PLAYER_PROFILE[language]}</DropdownItem>
                                        <DropdownItem divider className="divider" />
                                    </>}
                                <DropdownItem className="logout-btn" onClick={() => { logoutHandler() }}><FontAwesomeIcon icon={faArrowRightFromBracket} size="xl" className="ms-2 me-3" />{strings.LOGOUT[language]}</DropdownItem>
                            </DropdownMenu>)
                                : (<DropdownMenu className="userDropdownMenu portrait">
                                    <DropdownItem>
                                        <Col>
                                            <Row id="playerName" className="playerInfoBoxText portrait">
                                                <b>{name}</b>
                                            </Row>
                                            {funplayMode ? <></> :
                                                <Row className="flex-nowrap">
                                                    <Col className="playerInfoBoxText">
                                                        <Row className="w-100">
                                                            <Col>
                                                                <b>{strings.EC[language]} ({currency}):</b>
                                                            </Col>
                                                        </Row>
                                                        <Row className="w-100">
                                                            <Col>
                                                                {ecBalance}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col className="playerInfoBoxText">
                                                        <Row className="w-100">
                                                            <Col>
                                                                <b>{strings.NN[language]} ({currency}):</b>
                                                            </Col>
                                                        </Row>
                                                        <Row className="w-100">
                                                            <Col>
                                                                {nnBalance}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>}
                                        </Col>
                                    </DropdownItem>
                                    <DropdownItem divider className="divider" />
                                    {funplayMode ? <>
                                        <DropdownItem className="member-login-btn" onClick={() => { memberLoginHandler() }}><FontAwesomeIcon icon={faArrowRightToBracket} size="xl" className="ms-2 me-3" />{strings.LOGIN_AS_MEMBER[language]}</DropdownItem>
                                    </> :
                                        <><DropdownItem onClick={() => { navigate("/PlayerProfile") }}><span id="playerInfoIcon" className="icon-image" />{strings.PLAYER_PROFILE[language]}</DropdownItem>
                                            <DropdownItem divider className="divider" /></>
                                    }
                                    <DropdownItem className="logout-btn" onClick={() => { logoutHandler() }}><FontAwesomeIcon icon={faArrowRightFromBracket} size="xl" className="ms-2 me-3" />{strings.LOGOUT[language]}</DropdownItem>
                                </DropdownMenu>)}
                        </UncontrolledButtonDropdown>) : (
                            <Col className="d-flex justify-content-center me-3 p-0">
                                {isDemo ?
                                    (<Button id="playerIconButton" onClick={() => { dispatch(login("", language, GameMode.DEMO)) }} color="none">{strings.DEMO[language]}</Button>)
                                    : (<Button id="playerIconButton" onClick={() => { setShowLoginModal(!showLoginModal) }} color="none">{strings.LOGIN[language]}</Button>)}
                            </Col>
                        )}
                    <Col className="d-flex p-0 pe-2 m-0 justify-content-center">
                        <Button id="themeModeSwitch" className="icon-image" onClick={() => { setTheme(theme == Theme.DARK ? Theme.LIGHT : Theme.DARK) }} color="none"></Button>
                    </Col>
                    <Col className="d-flex p-0 m-0 justify-content-center">
                        <LanguageButton demoMode={isDemo} funplayMode={funplayMode} />
                    </Col>
                </Row>
                {isLogin && firstTimeLogin && !tncNotFound ? <AgreementModal openModal={showAgreementModal} onChange={setshowAgreementModal} isLogout={disagreeTnC} onAgree={agreeTnC} /> : <></>}
                {!isLogin ? <LoginModal openModal={showLoginModal} onChange={setShowLoginModal} title={strings.LOGIN[language]} /> :
                    <LogoutLoadingModal openModal={showLogoutModal} onChange={setshowLogoutModal} />}
            </Col>
        </Row >
    );
}

export default Header;