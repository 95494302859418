import React, { useContext } from 'react';
import { Spinner } from 'reactstrap';
import { strings } from '../assets/strings';
import { LanguageModeContext } from '../context/LanguageContext';
import { ThemesModeContext } from '../context/ThemesContext';
import { Theme } from '../enums/Style';

const Loader = () => {
    const { language } = useContext(LanguageModeContext)
    const { theme } = useContext(ThemesModeContext)

    return (
        <div className="loader p-4">
            <Spinner className={theme == Theme.DARK? "text-light" : "text-dark"}>
                {strings.LOADING[language]}
            </Spinner>
        </div>
    )
}

export default Loader;