import { GameRoomDTO } from '../../dto/gameDTO';
import { GameHistoryListDTO, HistoryGameServiceListDTO } from '../../dto/historyDTO';
import * as HistoryActions from './HistoryActions';
import { HistoryActionType } from './HistoryConstants';
import { IHistoryState, InitHistoryState } from './HistoryStates';

export const historyReducer = (state = InitHistoryState, action: HistoryActions.ActionTypes): IHistoryState => {
    switch (action.type) {
        case HistoryActionType.GET_HISTORY_GAME_REPLAY_REQUEST:
            return { ...state, loading: true, error: "" }
        case HistoryActionType.GET_HISTORY_GAME_REPLAY_SUCCESS:
            return { ...state, loading: false, replayGameData: action.payload as GameRoomDTO, error: "" }
        case HistoryActionType.GET_HISTORY_GAME_REPLAY_FAILED:
            return { ...state, loading: false, error: action.payload }
        case HistoryActionType.CLEAR_HISTORY_GAME_REPLAY:
            return {
                ...state, loading: false, replayGameData: {} as GameRoomDTO, error: ""
            }
        case HistoryActionType.GET_HISTORY_GAME_SERVICE_REQUEST:
            return { ...state, loading: true, error: "" }
        case HistoryActionType.GET_HISTORY_GAME_SERVICE_SUCCESS:
            return { ...state, loading: false, gameServiceList: action.payload as HistoryGameServiceListDTO, error: "" }
        case HistoryActionType.GET_HISTORY_GAME_SERVICE_FAILED:
            return { ...state, loading: false, error: action.payload }
        case HistoryActionType.GET_HISTORY_GAME_SERVICE_REQUEST:
            return { ...state, loading: true, error: "" }
        case HistoryActionType.GET_HISTORY_GAME_SERVICE_SUCCESS:
            return { ...state, loading: false, gameServiceList: action.payload as HistoryGameServiceListDTO, error: "" }
        case HistoryActionType.GET_HISTORY_GAME_SERVICE_FAILED:
            return { ...state, loading: false, error: action.payload }
        case HistoryActionType.GET_GAME_HISTORY_REQUEST:
            return { ...state, loading: true, error: "" }
        case HistoryActionType.GET_GAME_HISTORY_SUCCESS:
            return { ...state, loading: false, gameHistoryList: action.payload as GameHistoryListDTO, error: "" }
        case HistoryActionType.GET_GAME_HISTORY_FAILED:
            return { ...state, loading: false, error: action.payload }
        case HistoryActionType.GET_ACTIVE_GAME_HISTORY_REQUEST:
            return { ...state, loading: true, error: "" }
        case HistoryActionType.GET_ACTIVE_GAME_HISTORY_SUCCESS:
            return { ...state, loading: false, gameHistoryList: action.payload as GameHistoryListDTO, error: "" }
        case HistoryActionType.GET_ACTIVE_GAME_HISTORY_FAILED:
            return { ...state, loading: false, error: action.payload }
        case HistoryActionType.HISTORY_RESET:
            return InitHistoryState
        default:
            return state
    }
}
