import axios from 'axios';
import { HighestJackpotPotDTO, JackpotGameMeterModel } from '../../dto/jackpotPoolDTO';
import { JackpotActionType } from './JackpotConstants';

type UpdateLocalJackpotMeter_Request = { type: JackpotActionType.UPDATE_LOCAL_JACKPOT_METER_REQUEST };
type UpdateLocalJackpotMeter_Success = { type: JackpotActionType.UPDATE_LOCAL_JACKPOT_METER_SUCCESS, payload: {} };
type UpdateLocalJackpotMeter_Failed = { type: JackpotActionType.UPDATE_LOCAL_JACKPOT_METER_FAILED, payload: {} };
type GetJackpotMeter_Request = { type: JackpotActionType.GET_JACKPOT_METER_REQUEST };
type GetJackpotMeter_Success = { type: JackpotActionType.GET_JACKPOT_METER_SUCCESS, payload: {} };
type GetJackpotMeter_Failed = { type: JackpotActionType.GET_JACKPOT_METER_FAILED, payload: {} };
type UpdateHighestJackpot = { type: JackpotActionType.UPDATE_HIGHEST_JACKPOT, payload: {} };

export type ActionTypes =
    UpdateLocalJackpotMeter_Request |
    UpdateLocalJackpotMeter_Success |
    UpdateLocalJackpotMeter_Failed |
    GetJackpotMeter_Request |
    GetJackpotMeter_Success |
    GetJackpotMeter_Failed |
    UpdateHighestJackpot;

export const updateLocalJackpotMeter = (rawData: any) => async (dispatch: any, getState: any) => {
    try {

        dispatch({
            type: JackpotActionType.UPDATE_LOCAL_JACKPOT_METER_REQUEST,
        });

        if (rawData) {
            var data = JSON.parse(JSON.stringify(rawData))

            var jackpotData: JackpotGameMeterModel = JSON.parse(data.res)

            var highestJackpot: HighestJackpotPotDTO = {
                typ: jackpotData.htp.typ,
                amt: jackpotData.htp.amt,
                cur: jackpotData.htp.cur
            }

            dispatch({
                type: JackpotActionType.UPDATE_LOCAL_JACKPOT_METER_SUCCESS,
                payload: jackpotData
            });
            dispatch({
                type: JackpotActionType.UPDATE_HIGHEST_JACKPOT,
                payload: highestJackpot
            });
        }

    } catch (error) {
        dispatch({
            type: JackpotActionType.UPDATE_LOCAL_JACKPOT_METER_FAILED,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const getJackpotMeter = () => async (dispatch: any, getState: any) => {
    try {

        dispatch({
            type: JackpotActionType.GET_JACKPOT_METER_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json'
            },
        }

        const { data } = await axios.get(
            "/Jackpot/GetJackpotMeter",
            config
        )

        var jackpotData: JackpotGameMeterModel = JSON.parse(data.res)

        var highestJackpot: HighestJackpotPotDTO = {
            typ: jackpotData.htp.typ,
            amt: jackpotData.htp.amt,
            cur: jackpotData.htp.cur
        }

        dispatch({
            type: JackpotActionType.GET_JACKPOT_METER_SUCCESS,
            payload: jackpotData
        });
        dispatch({
            type: JackpotActionType.UPDATE_HIGHEST_JACKPOT,
            payload: highestJackpot
        });


    } catch (error) {
        dispatch({
            type: JackpotActionType.GET_JACKPOT_METER_FAILED,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}