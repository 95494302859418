import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { strings } from "../../assets/strings";
import { LanguageModeContext } from "../../context/LanguageContext";
import { GameHistoryDTO } from "../../dto/historyDTO";
import { SlotGameStatus } from "../../enums/SlotGameStatus";
import { FormatCurrency } from "../../utils/StringFormatHelper";

const HistoryDetailsModal = (props: { openModal: boolean, onChange: any, details: any, darkMode: boolean }) => {
    const { language } = useContext(LanguageModeContext)
    const [modal, setModal] = useState(props.openModal);
    const [historyDetails, setHistoryDetails] = useState({} as GameHistoryDTO);

    const toggle = () => {
        setModal(!modal);
        props.onChange(!modal);
    };

    const getStatus = (status: SlotGameStatus) => {
        switch (status) {
            case SlotGameStatus.NONE:
                return strings.STATUS_NONE[language]
            case SlotGameStatus.PAYOUT_FAILED:
                return strings.STATUS_PAYOUT_FAILED[language]
            case SlotGameStatus.PAYOUT_PENDING:
                return strings.STATUS_PAYOUT_PENDING[language]
            case SlotGameStatus.JACKPOT_CONTRIBUTION_FAILED:
                return strings.STATUS_JACKPOT_CONTRIBUTION_FAILED[language]
            case SlotGameStatus.JACKPOT_STRIKE_FAILED:
                return strings.STATUS_JACKPOT_STRIKE_FAILED[language]
            case SlotGameStatus.REFUND_FAILED:
                return strings.STATUS_REFUND_FAILED[language]
            case SlotGameStatus.GAMBLE_PAYOUT_FAILED:
                return strings.GAMBLE_PAYOUT_FAILED[language]
            default:
                return "N/A"
        }
    }

    useEffect(() => {
        setModal(props.openModal)
    }, [props.openModal])

    useEffect(() => {
        if (props.details != undefined) {
            setHistoryDetails(props.details)
        }
    }, [props.details])

    return (
        <Modal id="historyDetailsModal" className={props.darkMode ? "dark" : ""} isOpen={modal}>
            <ModalHeader id="historyDetailsModalHeader" toggle={toggle}>{strings.GAME_HISTORY_DETAILS[language]}</ModalHeader>
            <ModalBody id="historyDetailsModalBody">
                <Row>
                    <Card className={"history-details-card fade-in-top " + (props.darkMode ? "dark" : "")}>
                        <Row className="m-0 w-100">
                            <Col>
                                <Row className="history-details-subtitle">{strings.GAME_INFO[language]}</Row>
                                <Table className={"history-details-table " + (props.darkMode ? "dark" : "")}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "33%" }}>
                                                {strings.CREDIT_RATE[language]}
                                            </th>
                                            <th style={{ width: "33%" }}>
                                                {strings.CURRENCY[language]}
                                            </th>
                                            <th style={{ width: "33%" }}>
                                                {strings.GAMING_AREA[language]}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {historyDetails.crt}
                                            </td>
                                            <td>
                                                {historyDetails.cur}
                                            </td>
                                            <td>
                                                {historyDetails.are}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card>
                </Row>
                <Row>
                    <Card className={"history-details-card fade-in-top " + (props.darkMode ? "dark" : "")}>
                        <Row className="m-0 w-100">
                            <Col>
                                <Row className="history-details-subtitle">{strings.WALLET_TYPE[language]}</Row>
                                <Table className={"history-details-table " + (props.darkMode ? "dark" : "")}>
                                    <thead>
                                        <tr>
                                            <th className="w-50">
                                                EC {strings.WALLET[language]}
                                            </th>
                                            <th className="w-50">
                                                NN {strings.WALLET[language]}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {historyDetails.ewt}
                                            </td>
                                            <td>
                                                {historyDetails.nwt}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card>
                </Row>
                <Row>
                    <Card className={"history-details-card fade-in-top " + (props.darkMode ? "dark" : "")}>
                        <Row className="m-0 w-100">
                            <Col>
                                <Row className="history-details-subtitle">{strings.WAGER_INFO[language]}</Row>
                                <Card className={"history-details-subcard box-shadow p-2 " + (props.darkMode ? "dark" : "")}>
                                    <Table className={"history-details-table " + (props.darkMode ? "dark" : "")}>
                                        <thead>
                                            <tr>
                                                <th className="w-50">
                                                    {strings.BETS_PER_LINE[language]}
                                                </th>
                                                <th className="w-50">
                                                    {strings.LINES[language]}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {historyDetails.bpl}
                                                </td>
                                                <td>
                                                    {historyDetails.lin}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card>
                                <Row>
                                    <Col>
                                        <Card className={"history-details-subcard mt-4 box-shadow p-2 " + (props.darkMode ? "dark" : "")}>
                                            <Row className="history-details-wager-info">EC</Row>
                                            <Table className={"history-details-table " + (props.darkMode ? "dark" : "")}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "33%" }}>
                                                            {strings.BEFORE[language]}
                                                        </th>
                                                        <th style={{ width: "33%" }}>
                                                            {strings.WAGER[language]}
                                                        </th>
                                                        <th style={{ width: "33%" }}>
                                                            {strings.AFTER[language]}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {FormatCurrency(historyDetails.bec)}
                                                        </td>
                                                        <td>
                                                            {FormatCurrency(historyDetails.wec)}
                                                        </td>
                                                        <td>
                                                            {FormatCurrency(historyDetails.aec)}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card className={"history-details-subcard mt-4 box-shadow p-2 " + (props.darkMode ? "dark" : "")}>
                                            <Row className="history-details-wager-info">NN</Row>
                                            <Table className={"history-details-table " + (props.darkMode ? "dark" : "")}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "33%" }}>
                                                            {strings.BEFORE[language]}
                                                        </th>
                                                        <th style={{ width: "33%" }}>
                                                            {strings.WAGER[language]}
                                                        </th>
                                                        <th style={{ width: "33%" }}>
                                                            {strings.AFTER[language]}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {FormatCurrency(historyDetails.bnn)}
                                                        </td>
                                                        <td>
                                                            {FormatCurrency(historyDetails.wnn)}
                                                        </td>
                                                        <td>
                                                            {FormatCurrency(historyDetails.ann)}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Card>
                                    </Col>
                                </Row>
                                <Card className={"history-details-subcard mt-4 box-shadow p-2 " + (props.darkMode ? "dark" : "")}>
                                    <Table className={"history-details-table " + (props.darkMode ? "dark" : "")}>
                                        <thead>
                                            <tr>
                                                <th className="w-50">
                                                    {strings.PAYOUT[language]}
                                                </th>
                                                <th className="w-50">
                                                    {strings.HANDPAY_AMOUNT[language]}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody id="historyDetailsPayout">
                                            <tr>
                                                <td>
                                                    {FormatCurrency(historyDetails.pay)}
                                                </td>
                                                <td>
                                                    {FormatCurrency(historyDetails.hpy)}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </Row>
                <Row>
                    <Card className={"history-details-card fade-in-top " + (props.darkMode ? "dark" : "")}>
                        <Row className="m-0 w-100">
                            <Col>
                                <Row className="history-details-subtitle">{strings.MINIGAME_AND_GAMBLE[language]}</Row>
                                <Card className={"history-details-subcard box-shadow p-2 " + (props.darkMode ? "dark" : "")}>
                                    <Table className={"history-details-table " + (props.darkMode ? "dark" : "")}>
                                        <thead>
                                            <tr>
                                                <th style={{ width: "33%" }}>
                                                    {strings.NUMBER_OF_GAMBLE[language]}
                                                </th>
                                                <th style={{ width: "33%" }}>
                                                    {strings.GAMBLE_TOTAL_BET[language]}
                                                </th>
                                                <th style={{ width: "33%" }}>
                                                    {strings.GAMBLE_TOTAL_PAYOUT[language]}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {historyDetails.nog}
                                                </td>
                                                <td>
                                                    {FormatCurrency(historyDetails.gtb)}
                                                </td>
                                                <td>
                                                    {FormatCurrency(historyDetails.gtp)}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card>
                                <Card className={"history-details-subcard box-shadow mt-4 p-2 " + (props.darkMode ? "dark" : "")}>
                                    <Table className={"history-details-table " + (props.darkMode ? "dark" : "")}>
                                        <thead>
                                            <tr>
                                                <th className="w-50">
                                                    {strings.MINIGAME_TYPE[language]}
                                                </th>
                                                <th className="w-50">
                                                    {strings.MINIGAME_TOTAL_PAYOUT[language]}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {historyDetails.mgt == 0 ? "N/A" : historyDetails.mgt}
                                                </td>
                                                <td>
                                                    {FormatCurrency(historyDetails.mgtp)}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </Row>
                <Row>
                    <Card className={"history-details-card fade-in-top " + (props.darkMode ? "dark" : "")}>
                        <Row className="m-0 w-100">
                            <Col>
                                <Row className="history-details-subtitle">{strings.JACKPOT[language]}</Row>
                                <Table className={"history-details-table " + (props.darkMode ? "dark" : "")}>
                                    <thead>
                                        <tr>
                                            <th className="w-50">
                                                {strings.JACKPOT_POOL_STRIKE[language]}
                                            </th>
                                            <th className="w-50">
                                                {strings.JACKPOT_STRIKE_AMOUNT[language]}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {historyDetails.jps}
                                            </td>
                                            <td>
                                                {FormatCurrency(historyDetails.jsa)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card>
                </Row>
            </ModalBody>
            <ModalFooter id="historyDetailsModalFooter" />
        </Modal>
    );
}
export default HistoryDetailsModal;
