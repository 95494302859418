import React, { useContext, useEffect, useState } from "react";
import Pagination from "./Pagination";
import { Button, Col, Row } from 'reactstrap';
import { GameHistoryListDTO, GameHistoryDTO } from "../dto/historyDTO";
import icon_Replay from "../assets/images/icon_Replay.png"
import { FormatCurrency, FormatDateTime } from "../utils/StringFormatHelper";
import { strings } from "../assets/strings";
import { LanguageModeContext } from "../context/LanguageContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const HistoryTable = (props: {
    dataList: GameHistoryListDTO,
    reloadData: any,
    totalDataCount: number,
    maxContentView: number,
    maxPageView: number,
    onDetailsSelected: any,
    onReplaySelected: any
}) => {
    const { language } = useContext(LanguageModeContext)
    const [dataShown, setDataShown] = useState({} as GameHistoryListDTO)

    const populateTable = (
        <Row className="history-table">
            <Col className="p-0">
                {dataShown.ghl && dataShown.ghl.map((data: GameHistoryDTO, dataIndex: number) => {
                    return (
                        <div key={"history-" + dataIndex}>
                            <Row className="m-0">
                                <Col>
                                    <Row className="justify-content-center">
                                        <Col className="mb-2" style={{ minWidth: "10em", maxWidth: "10em" }}>
                                            <Row>
                                                <Col className="history-table-header">
                                                    <Row>ID: </Row>
                                                </Col>
                                                <Col>
                                                    <Row>{data.gid}</Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Row style={{ fontSize: "small" }} >{FormatDateTime(data.dtt)}</Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="w-auto mb-2">
                                            <Row className="flex-nowrap justify-content-center">
                                                <Col className="mb-2" md={3} style={{ minWidth: "10em", maxWidth: "10em" }}>
                                                    <Row className="p-1">
                                                        <Col>
                                                            <Row className="justify-content-center history-table-header" >{strings.TOTAL_BET[language]}:</Row>
                                                            <Row className="text-center justify-content-center">{data.cur + " " + FormatCurrency(data.bet)}</Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className="mb-2" md={3} style={{ minWidth: "10em", maxWidth: "10em" }}>
                                                    <Row className="p-1">
                                                        <Col>
                                                            <Row className="justify-content-center history-table-header" >{strings.PAYOUT[language]}:</Row>
                                                            <Row className={"text-center justify-content-center " + (data.pay > 0 ? "green" : "red")}>{data.cur + " " + FormatCurrency(data.pay)}</Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="history-table-btn-col">
                                            <Row>
                                                <Col className="p-1">
                                                    <Button key={dataIndex + "-details"} color="none" onClick={() => { props.onDetailsSelected(data) }} className="history-table-btn">
                                                        <FontAwesomeIcon size="lg" icon={faCircleInfo} /> {strings.DETAILS[language]}
                                                    </Button>
                                                </Col>
                                                <Col className="p-1">
                                                    <Button key={dataIndex + "-replay"} color="none" onClick={() => { props.onReplaySelected(true, data.gid, data.gsid, data.cms, data.dtt, data.bet, data.pay, data.cur) }} className="history-table-btn">
                                                        <img className="replay-icon-image" src={icon_Replay} /> {strings.REPLAY[language]}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="table-line">
                                {(dataIndex != dataShown.ttc - 1) ? (
                                    <hr className="divider" />
                                ) : <></>}
                            </Row>
                        </div>)
                }
                )}
            </Col>
        </Row >
    )

    useEffect(() => {
        if (props.dataList && props.dataList.ttc > 0) {
            setDataShown(props.dataList)
        }
    }, [props.dataList])

    return (
        <>
            {props.dataList && dataShown && populateTable}
            {props.dataList.ghl &&
                props.dataList.ghl.length > 0 ?
                (<Pagination
                    onReloadData={props.reloadData}
                    totalDataCount={props.totalDataCount}
                    dataList={props.dataList.ghl}
                    maxContentNum={props.maxContentView}
                    maxPageNum={props.maxPageView}
                />)
                : <></>
            }
        </>
    );
}
export default HistoryTable;
