import React, { useContext, useEffect, useState } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { ThemesModeContext } from '../../context/ThemesContext';
import { Orientation } from '../../enums/Style';

const Layout = (props: { children?: React.ReactNode }) => {
    const { orientation, theme } = useContext(ThemesModeContext)
    const [isPortrait, setIsPortrait] = useState(false)

    useEffect(() => {
        setIsPortrait(orientation == Orientation.PORTRAIT)
    }, [orientation, theme])

    return (
        <React.Fragment>
            <Container fluid id="layout" className="layout">
                <Row className="w-100 pe-2">
                    <Col>
                        {props.children}
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default Layout
