import React, { useEffect, useRef, useState } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col
} from 'reactstrap';

const NotificationModal = (props: { openModal: boolean, onChange: any, title?: string, content: string, appearSeconds?: number }) => {
    const [modal, setModal] = useState(props.openModal);
    const timer = useRef<NodeJS.Timeout | undefined>(undefined);

    const toggle = () => {
        clearTimer()
        setModal(false);
        props.onChange(false);
    };

    const clearTimer = () => {
        if (timer.current != undefined) {
            clearInterval(timer.current)
            timer.current = undefined
        }
    }

    useEffect(() => {
        setModal(props.openModal)
    }, [props.openModal])

    useEffect(() => {
        if (modal) {
            let seconds = props.appearSeconds

            timer.current = setInterval(() => {
                if (seconds != undefined && seconds >= 0) {
                    seconds--;
                }
                else {
                    toggle()
                }
            }, 1000)
        }
    }, [modal])

    return (
        <Modal id="notificationModal" isOpen={modal}>
            <ModalHeader id="notificationModalHeader" toggle={toggle}> {props.title != undefined ? props.title : ""}</ModalHeader>
            <ModalBody id="notificationModalBody">
                <Row>
                    <Col>{props.content}</Col>
                </Row>
            </ModalBody>
        </Modal>
    );
}
export default NotificationModal;
