import { PlayerProfileDTO } from '../../dto/playerProfileDTO';
import * as PlayerAction from './PlayerActions';
import { PlayerActionType } from './PlayerConstants';
import { InitUserState, IUserState } from './PlayerStates';

export const userReducer = (state = InitUserState, action: PlayerAction.ActionTypes): IUserState => {
    switch (action.type) {
        case PlayerActionType.LOGIN_REQUEST:
            return { ...state, loading: true, error: "" }
        case PlayerActionType.LOGIN_SUCCESS:
            return {
                ...state, loading: false, userInfo: action.payload as PlayerProfileDTO, tkn: action.token, isLogin: true, error: ""
            }
        case PlayerActionType.LOGIN_FAILED:
            return { ...state, loading: false, error: action.payload }
        case PlayerActionType.USER_RESET:
            return InitUserState
        case PlayerActionType.LOGOUT_REQUEST:
            return { ...state, loading: true, error: "" }
        case PlayerActionType.LOGOUT_SUCCESS:
            return { ...state, loading: false, isLogin: false, error: "", tkn: "" }
        case PlayerActionType.LOGOUT_FAILED:
            return { ...state, loading: false, error: action.payload }
        case PlayerActionType.GET_PROFILE_REQUEST:
            return { ...state, loading: true, error: "" }
        case PlayerActionType.GET_PROFILE_SUCCESS:
            return { ...state, loading: false, userInfo: action.payload as PlayerProfileDTO, error: "" }
        case PlayerActionType.GET_PROFILE_FAILED:
            return { ...state, loading: false, error: action.payload }
        case PlayerActionType.TOKEN_VALIDATION_REQUEST:
            return { ...state, error: "" }
        case PlayerActionType.TOKEN_VALIDATION_SUCCESS:
            return { ...state, error: "", tkn: action.token }
        case PlayerActionType.TOKEN_VALIDATION_FAILED:
            return { ...state, error: action.payload }
        case PlayerActionType.UPDATE_LANGUAGE_PREF_REQUEST:
            return { ...state, error: "" }
        case PlayerActionType.UPDATE_LANGUAGE_PREF_SUCCESS:
            return { ...state, userInfo: action.payload as PlayerProfileDTO, error: "" }
        case PlayerActionType.UPDATE_LANGUAGE_PREF_FAILED:
            return { ...state, error: action.payload }
        case PlayerActionType.UPDATE_FIRST_TIME_LOGIN_REQUEST:
            return { ...state, error: "" }
        case PlayerActionType.UPDATE_FIRST_TIME_LOGIN_SUCCESS:
            return { ...state, userInfo: action.payload as PlayerProfileDTO, error: "" }
        case PlayerActionType.UPDATE_FIRST_TIME_LOGIN_FAILED:
            return { ...state, error: action.payload }
        case PlayerActionType.GET_HISTORY_REQUEST:
            return { ...state, loading: true, error: "" }
        case PlayerActionType.GET_HISTORY_SUCCESS:
            return { ...state, loading: false, userInfo: action.payload as PlayerProfileDTO, error: "" }
        case PlayerActionType.GET_HISTORY_FAILED:
            return { ...state, loading: false, error: action.payload }
        default:
            return state
    }
}
